export const refreshTokens = ({firstHash, secondHash} : { firstHash: string; secondHash: string}) => {
  window.localStorage.setItem('Hash-1', firstHash);
  window.localStorage.setItem('Hash-2', secondHash);
};



export const signUserOut = () => {
  window.localStorage.removeItem('Hash-1');
  window.localStorage.removeItem('Hash-2');
  window.location.reload();
};

export const isUserLoggedIn = () => {
  return window.localStorage.getItem('Hash-1');
};

export const getFirstHash = () => {
  return window.localStorage.getItem('Hash-1');
};

export const getSecondHash = () => {
  return window.localStorage.getItem('Hash-2');
};

export const signInWithEmailandPassword = async (email: string, password: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};
