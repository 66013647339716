import { createTheme } from '@mui/material/styles';

export const muiBlueTheme = createTheme({
  palette: {
    primary: {
      main: '#f39c12',
    },
    secondary: {
      light: '#f8961',
      main: '#766dff',
      contrastText: '#f39c12',
    },

    background: {
      default: 'rgb(25, 23, 54)', // website background
    },
    text: {
      primary: '#e7e7e7',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px rgb(25, 23, 54) inset',
              WebkitTextFillColor: '#e7e7e7',
            },
          },
        },
      },
    },
  },
});

export const muiBlueRootStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8a8a8a',
  },
  '& .MuiInputLabel-outlined': {
    color: '#e7e7e7',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '11px',
  },
};
