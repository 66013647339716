import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { signUserOut } from '../api/auth/login';
import { capitalizeFirstLetters } from './library/Track';
import { categoryPaths } from './Footer';
import { isUserLoggedIn } from '../api/auth/login';

const Header = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme') || 'spotify dark';
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const isLoggedIn = isUserLoggedIn();
  const location = useLocation();
  const isOnPlayerPath = categoryPaths.includes(
    location.pathname.toLowerCase(),
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  useEffect(() => {
    const handleBodyClick = (e: MouseEvent) => {
      if (isSnackbarOpen) {
        const snackbar = document.querySelector('.snackbar-navigation-content');
        if (snackbar && !snackbar.contains(e.target as Node)) {
          closeSnackbar();
        }
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [isSnackbarOpen]);

  const toggleSnackBarNavigation = () => {
    setIsSnackbarOpen(!isSnackbarOpen);
  };

  const handleThemeChange = (event: any) => {
    const selectedTheme = event.value;
    window.localStorage.setItem('lawfmtheme', selectedTheme);
    window.location.reload();
  };

  const optionsClassName =
    selectedTheme === 'youtube white'
      ? 'category-selection-option category-selection-option--white'
      : 'category-selection-option';

  const options = [
    {
      value: 'spotify dark',
      label: 'Spotify Dark',
      className: optionsClassName,
    },
    {
      value: 'dark blue',
      label: 'Dark Blue',
      className: optionsClassName,
    },
    {
      value: 'youtube white',
      label: 'Youtube White',
      className: optionsClassName,
    },
  ];

  const filteredOptions = options.filter(
    (option) => option.value !== selectedTheme,
  );

  return (
    <header className={!isOnPlayerPath ? 'header' : `header--hidden`}>
      <div className="row">
        {isMobile ? (
          <div
            className={
              isSnackbarOpen && selectedTheme === 'spotify dark'
                ? 'snackbar-navigation snackbar-navigation--open snackbar-navigation--open--dark'
                : isSnackbarOpen && selectedTheme === 'dark blue'
                ? 'snackbar-navigation snackbar-navigation--open snackbar-navigation--open--blue'
                : isSnackbarOpen && selectedTheme === 'youtube white'
                ? 'snackbar-navigation snackbar-navigation--open snackbar-navigation--open--white'
                : 'snackbar-navigation'
            }
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleSnackBarNavigation();
              }}
              className="snackbar-navigation__icon-container"
            >
              {isSnackbarOpen ? (
                <CloseIcon style={snackBarIconStyles} />
              ) : (
                <MenuIcon style={snackBarIconStyles} />
              )}
            </div>

            {isSnackbarOpen && (
              <div className="snackbar-navigation-content">
                <ul className="snackbar-header-list">
                  <NavLink className="snackbar-header-list__link" to="/">
                    <li
                      className="snackbar-header-list__item"
                      onClick={toggleSnackBarNavigation}
                    >
                      Home
                    </li>
                  </NavLink>

                  <NavLink className="snackbar-header-list__link" to="/about">
                    <li
                      className="snackbar-header-list__item"
                      onClick={toggleSnackBarNavigation}
                    >
                      About
                    </li>
                  </NavLink>

                  {isLoggedIn ? (
                    <NavLink
                      className="snackbar-header-list__link"
                      to="/premium?plan=all-plans"
                    >
                      <li className="snackbar-header-list__item">Pricing</li>
                    </NavLink>
                  ) : (
                    <NavLink
                      className="snackbar-header-list__link"
                      to="/pricing"
                    >
                      <li className="snackbar-header-list__item">Pricing</li>
                    </NavLink>
                  )}

                  {isLoggedIn && (
                    <NavLink
                      className="snackbar-header-list__link"
                      to="/categories"
                    >
                      <li
                        className="snackbar-header-list__item"
                        onClick={toggleSnackBarNavigation}
                      >
                        Categories
                      </li>
                    </NavLink>
                  )}

                  {isLoggedIn && (
                    <NavLink
                      className="snackbar-header-list__link"
                      to="/dashboard"
                    >
                      <li
                        className="snackbar-header-list__item"
                        onClick={toggleSnackBarNavigation}
                      >
                        Dashboard
                      </li>
                    </NavLink>
                  )}

                  {isLoggedIn && (
                    <li
                      className="snackbar-header-list__item"
                      onClick={() => {
                        toggleSnackBarNavigation();
                        signUserOut();
                      }}
                    >
                      Logout
                    </li>
                  )}

                  {!isLoggedIn && (
                    <NavLink className="snackbar-header-list__link" to="/login">
                      <li
                        className="snackbar-header-list__item"
                        onClick={toggleSnackBarNavigation}
                      >
                        Login
                      </li>
                    </NavLink>
                  )}
                </ul>
                <div className="header-theme-select-container">
                  <Dropdown
                    controlClassName={
                      selectedTheme === 'youtube white'
                        ? 'category-selection category-selection--white category-selection--big category-selection--phone'
                        : 'category-selection category-selection--big category-selection--phone'
                    }
                    menuClassName={
                      selectedTheme === 'youtube white'
                        ? 'category-selection-menu category-selection-menu--phone-white'
                        : selectedTheme === 'dark blue'
                        ? 'category-selection-menu category-selection-menu--phone-blue'
                        : 'category-selection-menu category-selection-menu--phone-dark'
                    }
                    options={filteredOptions}
                    placeholder={capitalizeFirstLetters(selectedTheme)}
                    onChange={handleThemeChange}
                  />

                  {/* <select
                    className={
                      selectedTheme === 'dark blue'
                        ? 'select-dropdown select-dropdown--blue select-dropdown select-dropdown--large'
                        : selectedTheme === 'youtube white'
                        ? 'select-dropdown select-dropdown--white select-dropdown select-dropdown--large'
                        : 'select-dropdown select-dropdown--dark select-dropdown select-dropdown--large'
                    }
                    id="theme"
                    name="theme"
                    onChange={handleThemeChange}
                  >
                    {selectedTheme && (
                      <option value={selectedTheme}>
                        {capitalizeFirstLetters(selectedTheme)}
                      </option>
                    )}

                    {selectedTheme && selectedTheme !== 'spotify dark' && (
                      <option value="spotify dark">Spotify Dark</option>
                    )}

                    {selectedTheme && selectedTheme !== 'dark blue' && (
                      <option value="dark blue">Dark Blue</option>
                    )}

                    {selectedTheme && selectedTheme !== 'youtube white' && (
                      <option value="youtube white">Youtube White</option>
                    )}
                  </select> */}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="header-list-items-and-dropdown-container">
            <ul
              className={
                selectedTheme === 'dark blue'
                  ? 'header-list--blue'
                  : selectedTheme === 'youtube white'
                  ? 'header-list--white'
                  : 'header-list--dark'
              }
            >
              <NavLink
                className={
                  selectedTheme === 'dark blue'
                    ? 'header-list--blue__link'
                    : selectedTheme === 'youtube white'
                    ? 'header-list--white__link'
                    : 'header-list--dark__link'
                }
                to="/"
              >
                <li
                  className={
                    selectedTheme === 'dark blue'
                      ? 'header-list--blue__item'
                      : selectedTheme === 'youtube white'
                      ? 'header-list--white__item'
                      : 'header-list--dark__item'
                  }
                >
                  Home
                </li>
              </NavLink>

              <NavLink
                className={
                  selectedTheme === 'dark blue'
                    ? 'header-list--blue__link'
                    : selectedTheme === 'youtube white'
                    ? 'header-list--white__link'
                    : 'header-list--dark__link'
                }
                to="/about"
              >
                <li
                  className={
                    selectedTheme === 'dark blue'
                      ? 'header-list--blue__item'
                      : selectedTheme === 'youtube white'
                      ? 'header-list--white__item'
                      : 'header-list--dark__item'
                  }
                >
                  About
                </li>
              </NavLink>

              {isLoggedIn ? (
                <NavLink
                  className={
                    selectedTheme === 'dark blue'
                      ? 'header-list--blue__link'
                      : selectedTheme === 'youtube white'
                      ? 'header-list--white__link'
                      : 'header-list--dark__link'
                  }
                  to="/premium?plan=all-plans"
                >
                  <li
                    className={
                      selectedTheme === 'dark blue'
                        ? 'header-list--blue__item'
                        : selectedTheme === 'youtube white'
                        ? 'header-list--white__item'
                        : 'header-list--dark__item'
                    }
                  >
                    Pricing
                  </li>
                </NavLink>
              ) : (
                <NavLink
                  className={
                    selectedTheme === 'dark blue'
                      ? 'header-list--blue__link'
                      : selectedTheme === 'youtube white'
                      ? 'header-list--white__link'
                      : 'header-list--dark__link'
                  }
                  to="/pricing"
                >
                  <li
                    className={
                      selectedTheme === 'dark blue'
                        ? 'header-list--blue__item'
                        : selectedTheme === 'youtube white'
                        ? 'header-list--white__item'
                        : 'header-list--dark__item'
                    }
                  >
                    Pricing
                  </li>
                </NavLink>
              )}

              {isLoggedIn && (
                <NavLink
                  className={
                    selectedTheme === 'dark blue'
                      ? 'header-list--blue__link'
                      : selectedTheme === 'youtube white'
                      ? 'header-list--white__link'
                      : 'header-list--dark__link'
                  }
                  to="/categories"
                >
                  <li
                    className={
                      selectedTheme === 'dark blue'
                        ? 'header-list--blue__item'
                        : selectedTheme === 'youtube white'
                        ? 'header-list--white__item'
                        : 'header-list--dark__item'
                    }
                  >
                    Categories
                  </li>
                </NavLink>
              )}

              {isLoggedIn && (
                <NavLink
                  className={
                    selectedTheme === 'dark blue'
                      ? 'header-list--blue__link'
                      : selectedTheme === 'youtube white'
                      ? 'header-list--white__link'
                      : 'header-list--dark__link'
                  }
                  to="/dashboard"
                >
                  <li
                    className={
                      selectedTheme === 'dark blue'
                        ? 'header-list--blue__item'
                        : selectedTheme === 'youtube white'
                        ? 'header-list--white__item'
                        : 'header-list--dark__item'
                    }
                  >
                    Dashboard
                  </li>
                </NavLink>
              )}

              {isLoggedIn && (
                <li
                  className={
                    selectedTheme === 'dark blue'
                      ? 'header-list--blue__item'
                      : selectedTheme === 'youtube white'
                      ? 'header-list--white__item'
                      : 'header-list--dark__item'
                  }
                  onClick={signUserOut}
                >
                  Logout
                </li>
              )}
              {!isLoggedIn && (
                <NavLink
                  className={
                    selectedTheme === 'dark blue'
                      ? 'header-list--blue__link'
                      : selectedTheme === 'youtube white'
                      ? 'header-list--white__link'
                      : 'header-list--dark__link'
                  }
                  to="/login"
                >
                  <li
                    className={
                      selectedTheme === 'dark blue'
                        ? 'header-list--blue__item'
                        : selectedTheme === 'youtube white'
                        ? 'header-list--white__item'
                        : 'header-list--dark__item'
                    }
                  >
                    Login
                  </li>
                </NavLink>
              )}

              <div className="header-theme-select-container">
                <Dropdown
                  controlClassName={
                    selectedTheme === 'youtube white'
                      ? 'category-selection category-selection--white category-selection--big'
                      : 'category-selection category-selection--big'
                  }
                  menuClassName={
                    selectedTheme === 'youtube white'
                      ? 'category-selection-menu category-selection-menu--white'
                      : 'category-selection-menu'
                  }
                  // optionClassName="category-selection-option"
                  // className="category-selection"
                  options={filteredOptions}
                  placeholder={capitalizeFirstLetters(selectedTheme)}
                  onChange={handleThemeChange}
                />

                {/* <select
                  className={
                    selectedTheme === 'dark blue'
                      ? 'select-dropdown select-dropdown--blue select-dropdown select-dropdown--large'
                      : selectedTheme === 'youtube white'
                      ? 'select-dropdown select-dropdown--white select-dropdown select-dropdown--large'
                      : 'select-dropdown select-dropdown--dark select-dropdown select-dropdown--large'
                  }
                  id="theme"
                  name="theme"
                  onChange={handleThemeChange}
                >
                  {selectedTheme && (
                    <option value={selectedTheme}>
                      {capitalizeFirstLetters(selectedTheme)}
                    </option>
                  )}

                  {selectedTheme !== 'spotify dark' && (
                    <option value="spotify dark">Spotify Dark</option>
                  )}

                  {selectedTheme !== 'dark blue' && (
                    <option value="dark blue">Dark Blue</option>
                  )}

                  {selectedTheme !== 'youtube white' && (
                    <option value="youtube white">Youtube White</option>
                  )}
                </select> */}
              </div>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

const snackBarIconStyles = {
  fontSize: '3.5rem',
  cursor: 'pointer',
};

export default Header;
