import { Element } from 'react-scroll';
// import { default as AboutUsIcon } from '../styles/icons/about-us.svg';
import InZoneIcon from '../styles/icons/in-zone-icon.svg';
import IncreaseIcon from '../styles/icons/increase-icon.svg';
import CollectionIcon from '../styles/icons/collection-icon.svg';
import DistactionsIcon from '../styles/icons/distractions-icon.svg';
import AboutUsIcon from '../styles/icons/about-us-icon.svg';

const About = () => {
  return (
    <Element name="section-about">
      <section className="section-about" id="section-about">
        <div className="row about">
          <h2 className="about__heading secondary-heading">About</h2>
          <div className="row">
            {/* <div className="about-icon-container">
              <img
                className="about-icon-container__icon"
                src={AboutUsIcon}
                alt="about-us"
              ></img>
            </div> */}

            {/* <div className="col-1-of-4">
              <div className="feature-box">
                <div className="feature-icon-container">
                  <img
                    className="feature-icon-container__icon"
                    src={DistactionsIcon}
                    alt="about-us"
                  />
                </div>
                <p className="feature-box__paragraph">
                  Avoid distractions like ads, unrelated content.
                </p>
              </div>
            </div> */}
          </div>
          {/* <p className="about__paragraph paragraph">
            The music on LAWFM is crafted to enhance your productivity by
            seamlessly blending into the background, enabling you to concentrate
            without distractions. On the other hand, normal music is engineered
            to captivate your attention, hindering your ability to think and
            work effectively, often without you even being aware of it.
          </p> */}
          <p className="about__paragraph about__paragraph--with-padding paragraph">
            LAWFM's focus music aims to enhance your work experience by
            seamlessly integrating into your surroundings, allowing you to
            concentrate without interruptions. It promotes sustained attention
            and productivity, fostering an optimal environment for accomplishing
            tasks effectively.
          </p>

          <p className="about__paragraph paragraph">
            Contrary to this, conventional music often seeks to seize your
            attention, posing challenges to your cognitive processes and
            workflow, often without your awareness. Music on LAWFM is
            meticulously engineered to influence brain activity and elevate your
            performance from its core.
          </p>

          {/* <div className="col-1-of-3">
            <div className="feature-box">
              <div className="feature-icon-container">
                <img
                  className="feature-icon-container__icon"
                  src={InZoneIcon}
                  alt="about-us"
                />
              </div>
              <p className="feature-box__paragraph">
                Get in the zone effortlessly and faster.
              </p>
            </div>
          </div>
          <div className="col-1-of-3">
            <div className="feature-box">
              <div className="feature-icon-container">
                <img
                  className="feature-icon-container__icon"
                  src={IncreaseIcon}
                  alt="about-us"
                />
              </div>
              <p className="feature-box__paragraph">
                Music is scientifically proven to increase focus.
              </p>
            </div>
          </div>
          <div className="col-1-of-3">
            <div className="feature-box">
              <div className="feature-icon-container">
                <img
                  className="feature-icon-container__icon"
                  src={CollectionIcon}
                  alt="about-us"
                />
              </div>
              <p className="feature-box__paragraph">10000+ hours of content.</p>
            </div>
          </div> */}
          {/* <div>
            <ul className="about-list">
              <li className="about-list__item">
                It is specially tailored for music that helps you focus.
              </li>
              <li className="about-list__item">
                Boosts your productivity by helping you concentrate for longer.
              </li>

              <li className="about-list__item">
                Quality tracks - don't have to waste time searching for the
                right music.
              </li>
              <li className="about-list__item">
                No content you aren't here for, no ads, no nonsense.
              </li>
              <li className="about-list__item">
                No tracking. Not even Google Analytics. Privacy is very
                important for our users.
              </li>
            </ul>
          </div> */}
        </div>
      </section>
    </Element>
  );
};

export default About;
