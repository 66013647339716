import { useState, ChangeEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockResetIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme, muiRootStyles } from '../styles/mui/mui-styles';
import { muiBlueTheme, muiBlueRootStyles } from '../styles/mui/mui-styles-blue';
import {
  muiWhiteTheme,
  muiWhiteRootStyles,
} from '../styles/mui/mui-styles-white';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { requestPassWordResetEmail } from '../api/auth/forgot-reset-password';
import {
  inputStyles,
  inputStylesForBlueTheme,
  inputStylesForWhiteTheme,
} from './SignIn';
import Loading from './Loading';
import { isUserLoggedIn } from '../api/auth/login';

const defaultFormFields = {
  email: '',
};

export default function ForgotPassword() {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const isLoggedIn = isUserLoggedIn();
  const [emailErrorText, setEmailErrorText] = useState('');
  const [messageAfterPasswordReset, setMessageAfterPasswordReset] =
    useState('');
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email } = formFields;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (email === '') {
      setIsLoading(false);
      setEmailErrorText('Please enter email.');
      return;
    }

    try {
      await requestPassWordResetEmail(email);
      setIsLoading(false);
      setMessageAfterPasswordReset(`Password reset link sent.`);
    } catch (error) {
      if (error.code === 'user-not-found') {
        setIsLoading(false);
        setEmailErrorText(error.message);
      } else if (error.code === 'invalid-email') {
        setIsLoading(false);
        setEmailErrorText(error.message);
      } else if (error.code === 'too-many-requests') {
        setIsLoading(false);
        setEmailErrorText(error.message);
      } else {
        setIsLoading(false);
        setMessageAfterPasswordReset(
          'Something went wrong, please try again later.',
        );
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEmailErrorText('');
    setFormFields({ ...formFields, [name]: value });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  } else if (isLoading) {
    return <Loading />;
  } else {
    return (
      <ThemeProvider
        theme={
          selectedTheme === 'youtube white'
            ? muiWhiteTheme
            : selectedTheme === 'dark blue'
            ? muiBlueTheme
            : muiTheme
        }
      >
        <Container component="main" maxWidth="xs">
          {/* <CssBaseline /> */}
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {!messageAfterPasswordReset && (
              <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <LockResetIcon />
              </Avatar>
            )}

            {!messageAfterPasswordReset && (
              <Typography component="h1" variant="h5">
                Send password reset link to email
              </Typography>
            )}

            {messageAfterPasswordReset && (
              <div className="message-for-password-reset-box">
                <p className="message-for-password-reset-box__paragraph">
                  {messageAfterPasswordReset}
                </p>{' '}
                <RouterLink
                  to="/login"
                  className={
                    selectedTheme === 'spotify dark'
                      ? 'link--dark message-for-password-reset-box__paragraph'
                      : selectedTheme === 'spotify blue'
                      ? 'link--blue message-for-password-reset-box__paragraph'
                      : 'link--white message-for-password-reset-box__paragraph'
                  }
                >
                  Return to login
                </RouterLink>
              </div>
            )}

            {!messageAfterPasswordReset && (
              <Box
                component="form"
                width={350}
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      autoFocus
                      required
                      fullWidth
                      onChange={handleChange}
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      helperText={emailErrorText}
                      error={emailErrorText !== ''}
                      sx={
                        selectedTheme === 'youtube white'
                          ? { ...muiWhiteRootStyles }
                          : selectedTheme === 'dark blue'
                          ? { ...muiBlueRootStyles }
                          : { ...muiRootStyles }
                      }
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={
                        selectedTheme === 'youtube white'
                          ? { style: inputStylesForWhiteTheme }
                          : selectedTheme === 'dark blue'
                          ? { style: inputStylesForBlueTheme }
                          : {
                              style: inputStyles,
                            }
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    color:
                      selectedTheme === 'youtube white' ? '#e7e7e7' : '#121212',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                >
                  Send
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <RouterLink
                      to="/login"
                      className={
                        selectedTheme === 'youtube white'
                          ? 'black-link'
                          : 'link'
                      }
                    >
                      Return to login
                    </RouterLink>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}
