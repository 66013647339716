import { getFirstHash, getSecondHash, refreshTokens } from '../auth/login';

export const addNewUninterestedTrack = async (trackId: string) => {
  const token = getSecondHash(); 

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/addToUninterested`,
    {
      method: 'POST',
      body: JSON.stringify({
        trackId,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    },
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');

  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }

  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const removeUninterestedTrack = async (trackId: string) => {
  const token = getSecondHash(); 

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/removeFromUninterested`,
    {
      method: 'POST',
      body: JSON.stringify({
        trackId,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    },
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }

  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const addNewFavoriteTrack = async (trackId: string) => {
  const token = getSecondHash(); 

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/addToFavorites`,
    {
      method: 'POST',
      body: JSON.stringify({
        trackId,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    },
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }

  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const removeFavoriteTrack = async (trackId: string) => {
  const token = getSecondHash(); 
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/removeFromFavorites`,
    {
      method: 'POST',
      body: JSON.stringify({
        trackId,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    },
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }

  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const fetchUserData = async () => {
  const token = getFirstHash(); 

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/fetchUser`, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    }
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }

  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const fetchUserDashboardData = async () => {
  const token = getFirstHash(); 

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/fetchUserDashboardData`, {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `Bearer ${token}`,
        },
      }
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }
  
  const responseJSON = await response.json();
  
  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

interface SettingsUpdate {
  displayTimePlayed: boolean;
  displayTheme: boolean;
  displayTrackInfo: Boolean;
  genresToPlay: string[];
}

export const updateUserSettings = async (settings: SettingsUpdate) => {
  const token = getSecondHash(); 

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/updateUserSettings`,
    {
      method: 'POST',
      body: JSON.stringify({
        settings,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    },
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }
  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const updateUserVolumeSettings = async (volumeLevel: number) => {
  const token = getSecondHash(); 
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/updateVolumeSettings`,
    {
      method: 'POST',
      body: JSON.stringify({
        volumeLevel,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    },
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }
  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const updateUserDefaultAlarmTimerSetting  = async (defaultAlarmTimer: number) => {
  const token = getSecondHash(); 
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/updateDefaultAlarmTimer`,
    {
      method: 'POST',
      body: JSON.stringify({
        defaultAlarmTimer,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    },
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }
  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const updateUserAlarmSoundSetting = async (playAlarmSound: boolean) => {
  const token = getSecondHash(); 
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/updatePlayAlarmSoundSetting`,
    {
      method: 'POST',
      body: JSON.stringify({
        playAlarmSound,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      },
    },
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');
  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }
  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

