import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { changePlayingTrack } from '../../reducers/librarySlice';
import { addToPlayedToday } from '../../reducers/userSlice';
import { truncateString } from '../TrackInformation';
import { getSecondHash } from '../../api/auth/login';
import {
  enablePlayerPreviousButton,
  findNextSuitableTrackIndex,
} from '../Player';
import GoldCrown from '../../styles/icons/gold-crown-icon.svg';
import { StoreState } from '../../store/store';
import {
  Track as TrackInterface,
  TracksQueData,
  PlayingTrack,
} from '../../reducers/librarySlice';
import { PlayerRefType, getNeuralEffectTrackPath } from '../Player';

const checkForNeuralEffects = (
  lowNeuralPath: any,
  mediumNeuralPath: any,
  highNeuralPath: any,
  lowNeuralLinkPath: any,
  mediumNeuralLinkPath: any,
  highNeuralLinkPath: any,
): string => {
  const nonEmptyPaths = [
    lowNeuralPath,
    mediumNeuralPath,
    highNeuralPath,
  ].filter((path) => path !== '');

  const nonEmptyLinkPaths = [
    lowNeuralLinkPath,
    mediumNeuralLinkPath,
    highNeuralLinkPath,
  ].filter((path) => path !== '');

  if (nonEmptyPaths.length > 1 || nonEmptyLinkPaths.length > 1) {
    return 'multiple neural effects';
  } else if (nonEmptyPaths.length === 1 || nonEmptyLinkPaths.length === 1) {
    if (lowNeuralPath !== '' || lowNeuralLinkPath !== '')
      return 'low neural effect';
    if (mediumNeuralPath !== '' || mediumNeuralLinkPath !== '')
      return 'medium neural effect';
    if (highNeuralPath !== '' || highNeuralLinkPath !== '')
      return 'high neural effect';
  }

  // Fallback, although this should never be reached because at least one path is always filled
  return '';
};

interface TrackProps {
  name: string;
  genre: string;
  tags: string[];
  instruments: string[];
  path: string;
  id: string;
  premium?: boolean;
  playerRef: PlayerRefType;
  addedOnDate: string;
  openPremiumOnlyTrackNotication: () => void;
  category: string;
  additionalCategory: string;
  additionalSecondCategory: string;
  imageUrl?: string;
  displayImageUrl?: string;
  description?: string;
  pathLinkId?: string;
  lowNeuralPath?: string;
  mediumNeuralPath?: string;
  highNeuralPath?: string;
  linkPath?: string;
  lowNeuralLinkPath?: string;
  mediumNeuralLinkPath?: string;
  highNeuralLinkPath?: string;
}

const Track = (props: TrackProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const token = useMemo(() => {
    return getSecondHash();
  }, []);
  // const isUserOnPhoneOrTablet: boolean =
  //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //     navigator.userAgent,
  //   ) ||
  //   (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) ||
  //   false;
  const isSubscribed = useSelector(
    (state: StoreState) => state.user.subscribed,
  );
  const location = useLocation();
  const sleepCategoryGenres =
    location.pathname === '/deep-sleep' ||
    location.pathname === '/power-nap' ||
    location.pathname === '/guided-sleep';

  const genresToPlay = useSelector(
    (state: StoreState) => state.user.genresToPlay,
  );
  const activitySettings = useSelector(
    (state: StoreState) => state.user.activitySettings,
  );
  const neuralEffectSettings = useSelector(
    (state: StoreState) => state.user.neuralEffectSettings,
  );
  const uninterestedTrackIds = useSelector(
    (state: StoreState) => state.user.uninterested,
  );
  const favoriteTrackIds = useSelector(
    (state: StoreState) => state.user.favorites,
  );
  const tracks = useSelector((state: StoreState) => state.library.tracks);
  const currentTracksQueData = useSelector(
    (state: StoreState) => state.library.tracksQueData,
  );

  const shouldPlayFromFavorites = useSelector(
    (state: StoreState) => state.user.playFromFavorites,
  );
  const shouldPlayFromSleepFavorites = useSelector(
    (state: StoreState) => state.user.playFromSleepFavorites,
  );

  const playFromFavorites = !sleepCategoryGenres
    ? shouldPlayFromFavorites
    : shouldPlayFromSleepFavorites;

  const dispatch = useDispatch();
  const {
    name,
    genre,
    tags,
    instruments,
    path,
    id,
    premium,
    playerRef,
    openPremiumOnlyTrackNotication,
    addedOnDate,
    category,
    additionalCategory,
    additionalSecondCategory,
    imageUrl,
    displayImageUrl,
    description,
    pathLinkId,
    lowNeuralPath,
    mediumNeuralPath,
    highNeuralPath,
    linkPath,
    lowNeuralLinkPath,
    mediumNeuralLinkPath,
    highNeuralLinkPath,
  } = props;

  const isRecentlyAddedTrack =
    addedOnDate && moment().diff(moment(addedOnDate), 'days') < 60;

  const isPlaying = useSelector((state: StoreState) => state.library.isPlaying);
  const defaultStylesForPlayButton =
    selectedTheme === 'youtube white'
      ? playButtonDefaultStylesWhiteTheme
      : playButtonDefaultStyles;

  const [playButtonStyles, setPlayButtonStyles] = useState(
    defaultStylesForPlayButton,
  );
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const currentPlayingTrackId = useSelector(
    (state: StoreState) => state.library.playingTrack.id,
  );

  const getPathAccordingToNeuralSettingsAndIfExternalSource = (
    neuralEffectSettings: string[],
    playingTrack: PlayingTrack,
  ) => {
    if (playingTrack.linkPath) {
      return playingTrack.linkPath;
    } else if (brainfmGenres.includes(playingTrack.genre)) {
      const neuralEffectPath = getNeuralEffectTrackPath(
        neuralEffectSettings,
        playingTrack,
      );

      const isDirectLinkPath = neuralEffectPath.includes('dropboxusercontent');
      if (isDirectLinkPath) {
        return neuralEffectPath;
      } else {
        return `${process.env.REACT_APP_API_URL}/api/tracks/${neuralEffectPath}?token=${token}`;
      }
    } else {
      return `${process.env.REACT_APP_API_URL}/api/tracks/${playingTrack.path}?token=${token}`;
    }
  };

  const handleTrackSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    const playingTrackIndex = tracks.findIndex((track) => track.id === id);
    if (playingTrackIndex === currentTracksQueData.currentPlayingTrackIndex) {
      if (playerRef) {
        if ('togglePlay' in playerRef) {
          return playerRef.togglePlay(e);
        }
      }
    }

    const selectedTrack = {
      path,
      name,
      genre,
      id,
      imageUrl,
      displayImageUrl,
      pathLinkId,
      lowNeuralPath,
      mediumNeuralPath,
      highNeuralPath,
      linkPath,
      lowNeuralLinkPath,
      mediumNeuralLinkPath,
      highNeuralLinkPath,
    };
    const tracksData = getSelectedTrack(
      tracks,
      currentTracksQueData,
      selectedTrack,
    );
    const { playingTrack, tracksQueData } = tracksData;

    const adjustedPath = getPathAccordingToNeuralSettingsAndIfExternalSource(
      neuralEffectSettings,
      playingTrack,
    );

    const playingTrackWithAdjustedPath = {
      ...playingTrack,
      path: adjustedPath,
      neuralEffect: 'low',
    };

    const isLastTrack =
      findNextSuitableTrackIndex(
        tracks,
        tracksQueData.playedTrackIndexes,
        tracksQueData.currentPlayingTrackIndex,
        genresToPlay,
        uninterestedTrackIds,
        favoriteTrackIds,
        isSubscribed,
        playFromFavorites,
        activitySettings,
        neuralEffectSettings,
        sleepCategoryGenres,
      ) === -1;

    dispatch(
      changePlayingTrack({
        playingTrack: playingTrackWithAdjustedPath,
        tracksQueData: {
          ...tracksQueData,
          isLastTrack,
        },
      }),
    );

    dispatch(addToPlayedToday(selectedTrack.id));
    enablePlayerPreviousButton();
  };

  const handleAccordionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const divElement = event.target as HTMLDivElement;
    const name = divElement.closest('.track-container-main-info-box__name');
    const image = divElement.closest('.track-container__image');
    const playButton = divElement.closest(
      '.track-container-main-info-box__play-button',
    );

    if (name || image || playButton) {
      return;
    }
    setIsAccordionExpanded(!isAccordionExpanded);
  };

  return (
    <div
      className="track-container"
      onClick={
        premium && !isSubscribed
          ? openPremiumOnlyTrackNotication
          : handleAccordionClick
      }
    >
      <Accordion expanded={isAccordionExpanded} sx={customAccordionStyles}>
        <AccordionSummary
          expandIcon={
            selectedTheme === 'youtube white' ? (
              <ExpandMoreIcon
                sx={
                  premium && !isSubscribed
                    ? expandIconStylesPremiumWhiteTheme
                    : expandIconStylesWhiteTheme
                }
              />
            ) : (
              <ExpandMoreIcon
                sx={
                  premium && !isSubscribed
                    ? expandIconStylesPremium
                    : expandIconStyles
                }
              />
            )
          }
        >
          <div className="track-container-image-box">
            {premium && !isSubscribed && (
              <img
                className="track-container__premium-icon"
                src={GoldCrown}
                alt="premium"
              />
            )}

            <LazyLoadImage
              onClick={
                premium && !isSubscribed
                  ? openPremiumOnlyTrackNotication
                  : handleTrackSelection
              }
              className={
                premium && !isSubscribed
                  ? 'track-container__image track-container__image--premium'
                  : 'track-container__image'
              }
              alt="track theme"
              src={
                displayImageUrl
                  ? displayImageUrl
                  : imageUrl
                  ? imageUrl
                  : `/theme-images/${genre}.png`
              }
              effect="blur"
            />
          </div>

          <div
            className={
              premium && !isSubscribed
                ? 'track-container-main-info-box track-container-main-info-box--premium'
                : 'track-container-main-info-box'
            }
          >
            <div className="track-container-image-box-phone">
              {premium && !isSubscribed && (
                <img
                  className="track-container__premium-icon"
                  src={GoldCrown}
                  alt="premium"
                />
              )}

              <LazyLoadImage
                onClick={
                  premium && !isSubscribed
                    ? openPremiumOnlyTrackNotication
                    : handleTrackSelection
                }
                className={
                  premium && !isSubscribed
                    ? 'track-container__image track-container__image--premium'
                    : 'track-container__image'
                }
                alt="track theme"
                src={
                  displayImageUrl
                    ? displayImageUrl
                    : imageUrl
                    ? imageUrl
                    : `/theme-images/${genre}.png`
                }
                effect="blur"
              />
            </div>
            <h3
              onClick={
                premium && !isSubscribed
                  ? openPremiumOnlyTrackNotication
                  : handleTrackSelection
              }
              className={
                selectedTheme === 'spotify dark'
                  ? 'track-container-main-info-box__name track-container-main-info-box__name--dark'
                  : selectedTheme === 'dark blue'
                  ? 'track-container-main-info-box__name track-container-main-info-box__name--blue'
                  : 'track-container-main-info-box__name track-container-main-info-box__name--white'
              }
            >
              {premium && !isSubscribed
                ? 'Name'
                : isAccordionExpanded
                ? capitalizeFirstLetters(name)
                : truncateString(capitalizeFirstLetters(name), 35)}

              {isRecentlyAddedTrack && (
                <span
                  className={
                    selectedTheme === 'spotify dark'
                      ? 'track-container-main-info-box__new-tag track-container-main-info-box__new-tag--dark'
                      : selectedTheme === 'dark blue'
                      ? 'track-container-main-info-box__new-tag track-container-main-info-box__new-tag--blue'
                      : 'track-container-main-info-box__new-tag track-container-main-info-box__new-tag--white'
                  }
                >
                  NEW
                </span>
              )}
            </h3>

            {brainfmGenres.includes(genre) ? (
              <p
                className={
                  selectedTheme === 'spotify dark'
                    ? 'track-container-main-info-box__genre track-container-main-info-box__genre--dark'
                    : selectedTheme === 'dark blue'
                    ? 'track-container-main-info-box__genre track-container-main-info-box__genre--blue'
                    : 'track-container-main-info-box__genre track-container-main-info-box__genre--white'
                }
              >
                {genre}{' '}
                <span>
                  •{' '}
                  {checkForNeuralEffects(
                    lowNeuralPath,
                    mediumNeuralPath,
                    highNeuralPath,
                    lowNeuralLinkPath,
                    mediumNeuralLinkPath,
                    highNeuralLinkPath,
                  )}{' '}
                </span>
              </p>
            ) : (
              <p
                className={
                  selectedTheme === 'spotify dark'
                    ? 'track-container-main-info-box__genre track-container-main-info-box__genre--dark'
                    : selectedTheme === 'dark blue'
                    ? 'track-container-main-info-box__genre track-container-main-info-box__genre--blue'
                    : 'track-container-main-info-box__genre track-container-main-info-box__genre--white'
                }
              >
                {genre}
              </p>
            )}
            <div
              className="track-container-main-info-box__play-button"
              onClick={
                premium && !isSubscribed
                  ? openPremiumOnlyTrackNotication
                  : handleTrackSelection
              }
            >
              {currentPlayingTrackId === id && isPlaying ? (
                <PauseCircleFilledIcon
                  style={playButtonStyles}
                  onMouseEnter={() => {
                    if (selectedTheme === 'youtube white') {
                      setPlayButtonStyles(playButtonHoverStylesWhiteTheme);
                    } else {
                      setPlayButtonStyles(playButtonHoverStyles);
                    }
                  }}
                  onMouseLeave={() => {
                    if (selectedTheme === 'youtube white') {
                      setPlayButtonStyles(playButtonDefaultStylesWhiteTheme);
                    } else {
                      setPlayButtonStyles(playButtonDefaultStyles);
                    }
                  }}
                />
              ) : (
                <PlayCircleIcon
                  style={playButtonStyles}
                  onMouseEnter={() => {
                    if (selectedTheme === 'youtube white') {
                      setPlayButtonStyles(playButtonHoverStylesWhiteTheme);
                    } else {
                      setPlayButtonStyles(playButtonHoverStyles);
                    }
                  }}
                  onMouseLeave={() => {
                    if (selectedTheme === 'youtube white') {
                      setPlayButtonStyles(playButtonDefaultStylesWhiteTheme);
                    } else {
                      setPlayButtonStyles(playButtonDefaultStyles);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* {genresWithDescription.includes(genre) && (
            <h4
              className={
                selectedTheme === 'spotify dark'
                  ? 'track-container-secondary-info-box__title track-container-secondary-info-box__title--dark'
                  : selectedTheme === 'dark blue'
                  ? 'track-container-secondary-info-box__title track-container-secondary-info-box__title--blue'
                  : 'track-container-secondary-info-box__title track-container-secondary-info-box__title--white'
              }
            >
              Description
            </h4>
          )} */}

          {genresWithDescription.includes(genre) && (
            <span
              className={
                selectedTheme === 'spotify dark'
                  ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--dark track-container-secondary-info-box__tag--dark'
                  : selectedTheme === 'dark blue'
                  ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--blue'
                  : 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--white'
              }
            >
              {description}
            </span>
          )}

          {genresWithTags.includes(genre) && (
            <div className="track-container-secondary-info-box">
              <h4
                className={
                  selectedTheme === 'spotify dark'
                    ? 'track-container-secondary-info-box__title track-container-secondary-info-box__title--dark'
                    : selectedTheme === 'dark blue'
                    ? 'track-container-secondary-info-box__title track-container-secondary-info-box__title--blue'
                    : 'track-container-secondary-info-box__title track-container-secondary-info-box__title--white'
                }
              >
                Tags
              </h4>
              {tags.map((tag, i, { length }) => {
                if (length - 1 === i) {
                  return (
                    <span
                      className={
                        selectedTheme === 'spotify dark'
                          ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--dark'
                          : selectedTheme === 'dark blue'
                          ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--blue'
                          : 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--white'
                      }
                      key={i}
                    >
                      {tag}
                    </span>
                  );
                } else {
                  return (
                    <span
                      className={
                        selectedTheme === 'spotify dark'
                          ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--dark'
                          : selectedTheme === 'dark blue'
                          ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--blue'
                          : 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--white'
                      }
                      key={i}
                    >
                      {tag},{' '}
                    </span>
                  );
                }
              })}

              {instruments.length > 0 && (
                <h4
                  className={
                    selectedTheme === 'spotify dark'
                      ? 'track-container-secondary-info-box__title track-container-secondary-info-box__title--dark'
                      : selectedTheme === 'dark blue'
                      ? 'track-container-secondary-info-box__title track-container-secondary-info-box__title--blue'
                      : 'track-container-secondary-info-box__title track-container-secondary-info-box__title--white'
                  }
                >
                  Instruments
                </h4>
              )}

              {instruments.length > 0 &&
                instruments.map((instrument, i, { length }) => {
                  if (length - 1 === i) {
                    return (
                      <span
                        className={
                          selectedTheme === 'spotify dark'
                            ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--dark'
                            : selectedTheme === 'dark blue'
                            ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--blue'
                            : 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--white'
                        }
                        key={i}
                      >
                        {instrument}
                      </span>
                    );
                  } else {
                    return (
                      <span
                        className={
                          selectedTheme === 'spotify dark'
                            ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--dark'
                            : selectedTheme === 'dark blue'
                            ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--blue'
                            : 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--white'
                        }
                        key={i}
                      >
                        {instrument},{' '}
                      </span>
                    );
                  }
                })}

              {brainfmGenres.includes(genre) && !sleepCategoryGenres && (
                <h4
                  className={
                    selectedTheme === 'spotify dark'
                      ? 'track-container-secondary-info-box__title track-container-secondary-info-box__title--dark'
                      : selectedTheme === 'dark blue'
                      ? 'track-container-secondary-info-box__title track-container-secondary-info-box__title--blue'
                      : 'track-container-secondary-info-box__title track-container-secondary-info-box__title--white'
                  }
                >
                  Activities
                </h4>
              )}

              {brainfmGenres.includes(genre) && !sleepCategoryGenres && (
                <span
                  className={
                    selectedTheme === 'spotify dark'
                      ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--dark track-container-secondary-info-box__tag--dark'
                      : selectedTheme === 'dark blue'
                      ? 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--blue'
                      : 'track-container-secondary-info-box__tag track-container-secondary-info-box__tag--white'
                  }
                >
                  {replaceStudyWithLearning(category.replace(/-/g, ' '))}
                  {replaceStudyWithLearning(additionalCategory) && ', '}
                  {replaceStudyWithLearning(additionalCategory) &&
                    replaceStudyWithLearning(
                      additionalCategory.replace(/-/g, ' '),
                    )}
                  {replaceStudyWithLearning(additionalSecondCategory) && ', '}
                  {replaceStudyWithLearning(additionalSecondCategory) &&
                    replaceStudyWithLearning(
                      additionalSecondCategory.replace(/-/g, ' '),
                    )}
                </span>
              )}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export const getSelectedTrack = (
  tracks: TrackInterface[],
  tracksQueData: TracksQueData,
  selectedTrack: PlayingTrack,
) => {
  const { playedTrackIndexes } = tracksQueData;
  const playingTrackIndex = tracks.findIndex(
    (track) => track.id === selectedTrack.id,
  );
  const alreadyPlayedTrackIndex = playedTrackIndexes.indexOf(playingTrackIndex);
  let newPlayedTrackIndexes = [];
  let newTrackQueIndex = playedTrackIndexes.length;

  if (alreadyPlayedTrackIndex !== -1) {
    newTrackQueIndex = playedTrackIndexes.length - 1;
    newPlayedTrackIndexes = [...playedTrackIndexes];
    newPlayedTrackIndexes.splice(alreadyPlayedTrackIndex, 1);
    newPlayedTrackIndexes = [...newPlayedTrackIndexes, playingTrackIndex];
  } else {
    newPlayedTrackIndexes = [...playedTrackIndexes, playingTrackIndex];
  }

  const isLastTrack = tracks.length - 1 === playingTrackIndex;

  return {
    playingTrack: selectedTrack,
    tracksQueData: {
      currentPlayingTrackIndex: playingTrackIndex,
      trackStartPoint: 0,
      playedTrackIndexes: newPlayedTrackIndexes,
      trackQueIndex: newTrackQueIndex,
      isFirstTrack: false,
      isLastTrack,
    },
  };
};

const customAccordionStyles = {
  backgroundColor: 'transparent',
};

const expandIconStyles = {
  fontSize: '24px',
  color: '#fefefc',
  cursor: 'pointer',
};

const expandIconStylesWhiteTheme = {
  fontSize: '24px',
  color: '#2f2f2f',
  cursor: 'pointer',
};

const expandIconStylesPremium = {
  fontSize: '24px',
  color: '#fefefc',
  cursor: 'pointer',
  opacity: 0.3,
};

const expandIconStylesPremiumWhiteTheme = {
  fontSize: '24px',
  color: '#2f2f2f',
  cursor: 'pointer',
  opacity: 0.3,
};

const playButtonDefaultStyles = {
  fontSize: '3.5rem',
  color: '#e7e7e7',
  transition: 'font-size 0.2s',
};

const playButtonHoverStyles = {
  fontSize: '3.5rem',
  color: '#fafafa',
  transition: 'font-size 0.2s',
};

const playButtonDefaultStylesWhiteTheme = {
  fontSize: '3.5rem',
  color: '#2f2f2f',
  transition: 'font-size 0.2s',
};

const playButtonHoverStylesWhiteTheme = {
  fontSize: '3.5rem',
  color: '#4a4a4a',
  transition: 'font-size 0.2s',
};

export const brainfmGenres = [
  'acoustic',
  'atmospheric',
  'cinematic',
  'classical',
  'drone',
  'electronic',
  'grooves',
  'lofi',
  'piano',
  'post rock',
  'beach',
  'chimes & bowls',
  'forest',
  'nightsounds',
  'rain',
  'rainforest',
  'river',
  'thunder',
  'underwater',
  'wind',
];

export const genresWithTags = [
  'acoustic',
  'atmospheric',
  'cinematic',
  'classical',
  'drone',
  'electronic',
  'grooves',
  'lofi',
  'piano',
  'post rock',
  'beach',
  'chimes & bowls',
  'forest',
  'nightsounds',
  'rain',
  'rainforest',
  'river',
  'thunder',
  'underwater',
  'wind',
];

const genresWithDescription = ['headspace', 'quantum'];

export function capitalizeFirstLetters(string: string) {
  return string.replace(/(^|\s|\(|\)|\[|\]){1}[a-z]/g, (match) =>
    match.toUpperCase(),
  );
}

const replaceStudyWithLearning = (word: string) => {
  if (word === 'study') {
    return 'learning';
  } else {
    return word;
  }
};

export default Track;
