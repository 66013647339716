import Tick from '../styles/icons/tick-icon.svg';
import GreenTick from '../styles/icons/green-tick-icon.svg';
import { ThemeProvider } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
// import SantaHatImage from '../styles/images/santa-hat.png';
// import ChristmasWreathImage from '../styles/images/christmas-wreath.png';
import {
  rectangleButtonStyles,
  rectangleButtonStylesWhiteTheme,
} from '../styles/mui/rectangle-button';
import { muiTheme } from '../styles/mui/mui-styles';
import { muiBlueTheme } from '../styles/mui/mui-styles-blue';
import { muiWhiteTheme } from '../styles/mui/mui-styles-white';
import Button from '@mui/material/Button';
import NotIncluded from '../styles/icons/not-included-icon.svg';

interface PricingBoxProps {
  title: string;
  price: string;
  promoPrice: string;
  isPremium: boolean;
  isOnPaymentPage: boolean;
  id: string;
  email?: string;
}

const PricingBox = (props: PricingBoxProps) => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const { title, price, promoPrice, isPremium, isOnPaymentPage, id, email } =
    props;

  return (
    <div
      className={
        selectedTheme === 'dark blue'
          ? 'pricing-box pricing-box--blue'
          : selectedTheme === 'youtube white'
          ? 'pricing-box pricing-box--white'
          : 'pricing-box pricing-box--dark'
      }
    >
      <div className="title-and-decoration-container">
        <h3 className="pricing-box__heading">{title}</h3>
        {/* <div className="pricing-box-decoration-container">
          <img
            className="pricing-box-decoration-container__image"
            src={
              title === 'Premium month' ? SantaHatImage : ChristmasWreathImage
            }
            alt="decoration"
          />
        </div> */}
      </div>

      <p
        className={
          selectedTheme === 'youtube white'
            ? 'pricing-box__price pricing-box__price--white'
            : 'pricing-box__price'
        }
      >
        {selectedTheme !== 'youtube white' && promoPrice !== price && (
          <span className="pricing-box__price--crossed">{price}</span>
        )}
        {selectedTheme === 'youtube white' && promoPrice !== price && (
          <span className="pricing-box__price--crossed-grey">{price}</span>
        )}
        {promoPrice}
      </p>
      <div className="pricing-box-icon-container">
        <img
          className="pricing-box-icon-container__icon"
          src={selectedTheme === 'youtube white' ? GreenTick : Tick}
          alt="tick"
        />
        <span className="pricing-box-icon-container__text">Free tracks</span>
      </div>
      <div className="pricing-box-icon-container">
        <img
          className="pricing-box-icon-container__icon"
          src={
            isPremium && selectedTheme === 'youtube white'
              ? GreenTick
              : isPremium
              ? Tick
              : NotIncluded
          }
          alt="tick"
        />
        <span className="pricing-box-icon-container__text">Soundscapes</span>
      </div>
      <div className="pricing-box-icon-container">
        <img
          className="pricing-box-icon-container__icon"
          src={
            isPremium && selectedTheme === 'youtube white'
              ? GreenTick
              : isPremium
              ? Tick
              : NotIncluded
          }
          alt={isPremium ? 'tick' : 'not-included'}
        />
        <span className="pricing-box-icon-container__text">Premium tracks</span>
      </div>
      <div className="pricing-box-icon-container pricing-box-icon-container--last-item">
        <img
          className="pricing-box-icon-container__icon"
          src={
            isPremium && selectedTheme === 'youtube white'
              ? GreenTick
              : isPremium
              ? Tick
              : NotIncluded
          }
          alt={isPremium ? 'tick' : 'not-included'}
        />
        <span className="pricing-box-icon-container__text">All genres</span>
      </div>
      <ThemeProvider
        theme={
          selectedTheme === 'dark blue'
            ? muiBlueTheme
            : selectedTheme === 'youtube white'
            ? muiWhiteTheme
            : muiTheme
        }
      >
        {!isPremium ? (
          <NavLink to="/categories">
            <Button
              fullWidth
              variant="contained"
              style={
                selectedTheme === 'youtube white'
                  ? rectangleButtonStylesWhiteTheme
                  : rectangleButtonStyles
              }
            >
              Choose Plan
            </Button>
          </NavLink>
        ) : isPremium && !isOnPaymentPage ? (
          <NavLink to={`/premium?plan=${id}`}>
            <Button
              fullWidth
              variant="contained"
              style={
                selectedTheme === 'youtube white'
                  ? rectangleButtonStylesWhiteTheme
                  : rectangleButtonStyles
              }
            >
              Choose Plan
            </Button>
          </NavLink>
        ) : (
          <form
            id="buy-premium"
            method="POST"
            action={process.env.REACT_APP_BTCPAY_URL}
          >
            <input id="email" type="hidden" name="email" value={email} />
            <input id="orderId" type="hidden" name="orderId" value={id} />
            <input
              id="welcome-to-premium"
              type="hidden"
              name="redirectUrl"
              value={`${process.env.REACT_APP_URL}/welcome-to-premium`}
            />

            <Button
              id="pay-button"
              type="submit"
              name="choiceKey"
              value={id}
              fullWidth
              variant="contained"
              style={
                selectedTheme === 'youtube white'
                  ? rectangleButtonStylesWhiteTheme
                  : rectangleButtonStyles
              }
            >
              Pay
            </Button>
          </form>
        )}
      </ThemeProvider>
    </div>
  );
};

export default PricingBox;
