import { Navigate } from 'react-router-dom';
import SignIn from './SignIn';
import { isUserLoggedIn } from '../api/auth/login';

const LoginPage = () => {
  const isLoggedIn = isUserLoggedIn();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  } else {
    return (
      <div className="row login-container">
        <SignIn />
      </div>
    );
  }
};

export default LoginPage;
