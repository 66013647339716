interface CategoryProps {
  title: string;
  icon: string;
}

const Category = (props: CategoryProps) => {
  return (
    <div className="category-container">
      {/* <img
        className="category-container__icon"
        src={props.icon}
        alt={props.title}
      /> */}
      <h1 className="category-container__title">{props.title}</h1>
    </div>
  );
};

export default Category;
