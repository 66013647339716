import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';
import { Navigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Loading from './Loading';
import { fetchUserData } from '../api/user/user';
import { isUserLoggedIn } from '../api/auth/login';
import { getSecondHash } from '../api/auth/login';
import { StoreState } from '../store/store';
import { updateUserVolumeSettings } from '../api/user/user';
import RelaxIcon from '../styles/icons/soundscape-relax.svg';
import SleepIcon from '../styles/icons/soundscape-sleep.svg';
import FocusIcon from '../styles/icons/soundscape-focus.svg';
import SpatialIcon from '../styles/icons/soundscape-spatial.svg';
import StudyIcon from '../styles/icons/soundscape-study.svg';
import RecoveryIcon from '../styles/icons/soundscape-recovery.svg';
import RainyIcon from '../styles/icons/soundscape-rainy.svg';
import WisdomIcon from '../styles/icons/soundscape-wisdom.svg';
import DeeperIcon from '../styles/icons/soundscape-deeper.svg';
import GrimesIcon from '../styles/icons/soundscape-grimes.svg';
import HibernationIcon from '../styles/icons/soundscape-hibernation.svg';
import WindDownIcon from '../styles/icons/soundscape-winddown.svg';
import PlayIcon from '../styles/icons/soundscape-play.svg';
import TimerIcon from '../styles/icons/soundscape-timer.svg';
import PauseIcon from '../styles/icons/soundscape-pause.svg';
import RefreshIcon from '../styles/icons/soundscape-refresh.svg';
import TimerModal from './timer/TimerModal';
import { useDebounceWithNumberState } from './Player';

const Soundscapes = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const currentHour = String(new Date().getHours()).padStart(2, '0');
  const [selectedSoundScapeIndex, setSelectedSoundScapeIndex] =
    useState<number>(0);
  const [playedPlaylistNumbers, setPlayedPlaylistNumbers] = useState<number[]>(
    [],
  );
  const [m3u8Url, setM3u8Url] = useState(
    `${process.env.REACT_APP_API_URL}/api/m3u?mode=${soundScapes[selectedSoundScapeIndex].mode}&hour=${currentHour}`,
  );
  const isLoggedIn = isUserLoggedIn();
  const [audioLoaded, setAudioLoaded] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isUserSubscribed, setIsUserSubscribed] = useState<boolean>(false);
  const location = useLocation();
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const audioRef = useRef(null);
  const token = getSecondHash();
  const [volumeLevel, setVolumeLevel] = useState<number>(0);
  const [volumeLevelToUpdate, setVolumeLevelToUpdate] =
    useDebounceWithNumberState(2000, volumeLevel);
  const isUserOnPhoneOrTablet =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ) ||
    (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) ||
    false;

  const subTitle = getSubtitleByHourAndMinute();
  const themeSrc = `${process.env.REACT_APP_API_URL}/api/themes/soundscape/${soundScapes[selectedSoundScapeIndex].mode}.webm?token=${token}`;
  const [error, setError] = useState(null);
  const [soundscapeRefresh, setSoundscapeRefresh] = useState(0);
  const savedDefaultAlarmTimer = useSelector(
    (state: StoreState) => state.user.defaultAlarmTimer,
  );
  const playAlarmSoundAfterTimerEnds = useSelector(
    (state: StoreState) => state.user.playAlarmSound,
  );
  const [pauseByTimer, setPauseByTimer] = useState(false);
  const [timerTime, setTimerTime] = useState(savedDefaultAlarmTimer);
  const [timer, setTimer] = useState(null);
  const [isTimerModalOpen, setTimerModalOpen] = useState(false);

  const toggleTimerModal = () => {
    setTimerModalOpen(!isTimerModalOpen);
  };

  const handleTimerEnd = () => {
    setPauseByTimer(true);

    if (playAlarmSoundAfterTimerEnds) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
      const audio = new Audio('/alarm/alarm-bell.mp3');
      audio.volume = volumeLevel;
      audio.play();

      setIsPlaying(false);
    } else {
      if (videoRef.current) {
        setIsPlaying(false);
        videoRef.current.pause();
      }
    }
  };

  useEffect(() => {
    if (timerTime === 0 && timer) {
      handleTimerEnd();
    }
  }, [timerTime]);

  useEffect(() => {
    const audioElement = videoRef.current;

    if (audioElement) {
      const handlePlay = () => {
        setIsPlaying(true);
      };

      const handlePause = () => {
        setIsPlaying(false);
      };

      audioElement.addEventListener('play', handlePlay);
      audioElement.addEventListener('pause', handlePause);

      return () => {
        audioElement.removeEventListener('play', handlePlay);
        audioElement.removeEventListener('pause', handlePause);
      };
    }
  }, [audioRef.current, setIsPlaying]);

  const delayedHandleSoundscapeSelect = debounce(
    async (newSoundscapeIndex: number) => {
      setPlayedPlaylistNumbers([]);
      setAudioLoaded(false);
      setSelectedSoundScapeIndex(newSoundscapeIndex);
      setM3u8Url(
        `${process.env.REACT_APP_API_URL}/api/m3u?mode=${soundScapes[newSoundscapeIndex].mode}&hour=${currentHour}`,
      );
    },
    500,
  );

  const handleSoundscapeSelect = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const newSoundscapeIndex = parseInt(e.currentTarget.id, 10);
    if (newSoundscapeIndex === selectedSoundScapeIndex) {
      return;
    }
    delayedHandleSoundscapeSelect(newSoundscapeIndex);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const fetchData = async () => {
      try {
        const userData = await fetchUserData();
        const { subscribed, volumeLevel } = userData;
        setIsUserSubscribed(subscribed);

        if (isUserOnPhoneOrTablet) {
          setVolumeLevel(1);
          setVolumeLevelToUpdate(volumeLevel);
        } else {
          setVolumeLevel(volumeLevel);
          setVolumeLevelToUpdate(volumeLevel);
        }

        setIsDataLoaded(true);
      } catch (error) {
        console.log(error);
        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, []);

  const handleTogglePlay = () => {
    const audioElement = videoRef.current;
    if (audioElement) {
      if (audioElement.paused) {
        audioElement.play();
        setIsPlaying(true);
      } else {
        audioElement.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleRefresh = async () => {
    setAudioLoaded(false);
    setSoundscapeRefresh(Math.random());
  };

  const handleUpdateUserVolumeSettings = async (volumeLevel: number) => {
    await updateUserVolumeSettings(volumeLevel);
  };

  useEffect(() => {
    const updateVolumeSettings = async () => {
      if (volumeLevelToUpdate !== volumeLevel) {
        try {
          await handleUpdateUserVolumeSettings(volumeLevel);
        } catch (error) {
          console.log(error);
        }
      }
    };

    updateVolumeSettings();
  }, [volumeLevelToUpdate]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const newVolumeLevel = newValue as number;
    setVolumeLevel(newVolumeLevel);

    if (videoRef.current) {
      videoRef.current.volume = newVolumeLevel;
      if (!isUserOnPhoneOrTablet) {
        setVolumeLevelToUpdate(Math.random());
      }
    }
  };

  useEffect(() => {
    const playStream = () => {
      if (!videoRef.current) return;
      let randomPlayListNumber;

      if (
        playedPlaylistNumbers.length === 0 ||
        playedPlaylistNumbers.length ===
          soundScapes[selectedSoundScapeIndex].playLists
      ) {
        randomPlayListNumber = getRandomPlaylistNumber(
          soundScapes[selectedSoundScapeIndex].playLists,
        );
        setPlayedPlaylistNumbers([randomPlayListNumber]);
      } else {
        randomPlayListNumber = getRandomPlaylistNumberExcludingPlayedNumbers(
          playedPlaylistNumbers,
          soundScapes[selectedSoundScapeIndex].playLists,
        );
        setPlayedPlaylistNumbers([
          ...playedPlaylistNumbers,
          randomPlayListNumber,
        ]);
      }

      const m3urlWithPlayListNumberQuery = `${m3u8Url}&list=${randomPlayListNumber}`;

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(m3urlWithPlayListNumberQuery);
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (videoRef.current) {
            videoRef.current.play().catch((error) => setError(error));
          }
        });
        hls.on(Hls.Events.ERROR, (event: any, data: any) => {
          setError(data);
        });
      } else {
        if (videoRef.current) {
          videoRef.current.src = m3urlWithPlayListNumberQuery;
          videoRef.current.play().catch((error: any) => setError(error));
        }
      }
    };

    const handlePlay = () => {
      setIsPlaying(true);
    };

    playStream();

    const handleAudioPlay = () => {
      setAudioLoaded(true);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('playing', handlePlay);
      videoRef.current.addEventListener('canplay', handleAudioPlay);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('playing', handlePlay);
        videoRef.current.removeEventListener('canplay', handleAudioPlay);
      }
    };
  }, [m3u8Url, videoRef.current, soundscapeRefresh]);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else if (!isDataLoaded) {
    return <Loading />;
  } else if (isDataLoaded && !isUserSubscribed) {
    return (
      <Navigate
        to="/premium?plan=all-plans"
        replace
        state={{ from: location }}
      />
    );
  } else {
    return (
      <div className="soundscapes">
        <div className="soundescapes-titles">
          <h2 className="soundescapes-titles__title">
            {soundScapes[selectedSoundScapeIndex].name}
          </h2>
          <h3 className="soundescapes-titles__subtitle">{subTitle}</h3>
        </div>
        <div className="soundscape-theme-container">
          <div className="soundscape-theme-container__video-box">
            <ReactPlayer
              webkit-playsinline="true"
              playsinline={true}
              onClick={handleTogglePlay}
              disablePictureInPicture={true}
              className="react-player"
              loop={true}
              url={themeSrc}
              playing={isPlaying}
              controls={false}
              muted={true}
              width="auto"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                    disablePictureInPicture: true,
                  },
                },
              }}
            />
          </div>
        </div>

        <footer className="soundscapes-footer">
          <div className="soundscapes-container">
            {soundScapes.map((soundscape, index) => {
              return (
                <div key={index} className="soundscape-container">
                  <div
                    id={index.toString()}
                    onClick={handleSoundscapeSelect}
                    className={
                      selectedSoundScapeIndex === index
                        ? 'soundscape-container__button soundscape-container__button--selected'
                        : 'soundscape-container__button'
                    }
                  >
                    <img
                      id={index.toString()}
                      title={soundscape.name}
                      src={soundscape.icon}
                      alt={soundscape.name}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <video
            preload="none"
            className="soundscape-audio"
            ref={videoRef}
            controls
            autoPlay={false}
            playsInline={true}
          />

          <div className="action-buttons-container">
            {!isUserOnPhoneOrTablet && (
              <div className="action-buttons-container__placeholder"></div>
            )}

            <div className="main-action-buttons-container">
              <div
                className="action-button-container action-button-container--small"
                onClick={handleRefresh}
              >
                <img
                  className="action-button-container__button action-button-container__button--small"
                  title={'Regenerate Soundscape'}
                  src={RefreshIcon}
                  alt={'Regenerate Soundscape'}
                />{' '}
              </div>

              <div className="play-button-wrap">
                <div
                  className="action-button-container"
                  onClick={handleTogglePlay}
                >
                  <img
                    className="action-button-container__button"
                    title={'Play'}
                    src={isPlaying ? PauseIcon : PlayIcon}
                    alt={'Play'}
                  />

                  {!audioLoaded && (
                    <div className="animated-play-loading"></div>
                  )}
                </div>
              </div>

              <div
                className="action-button-container action-button-container--small"
                onClick={toggleTimerModal}
              >
                <img
                  className="action-button-container__button action-button-container__button--small"
                  title={'Set Timer'}
                  src={TimerIcon}
                  alt={'Set Timer'}
                />
              </div>
            </div>

            {!isUserOnPhoneOrTablet && (
              <Box className="volume-container" sx={{ width: 150 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <VolumeUp fontSize="large" />
                  </Grid>
                  <Grid item xs>
                    <Slider
                      sx={{ color: '#ffffff' }}
                      value={typeof volumeLevel === 'number' ? volumeLevel : 0}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                      max={1}
                      step={0.01}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </div>
        </footer>
        <TimerModal
          timer={timer}
          setTimer={setTimer}
          isTimerModalOpen={isTimerModalOpen}
          toggleTimerModal={toggleTimerModal}
          timerTime={timerTime}
          setTimerTime={setTimerTime}
        />
      </div>
    );
  }
};

export default Soundscapes;

// const debounce = (func, delay) => {
//   let timeoutId;
//   return (...args) => {
//     clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       func(...args);
//     }, delay);
//   };
// };

const debounce = (
  func: Function,
  delay: number,
): ((...args: any[]) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: any[]): void => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function getSubtitleByHourAndMinute() {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  const schedule = [
    {
      id: 'morning-rise-1',
      title: 'Morning Energy Rise',
      startTime: '06:50',
      endTime: '07:10',
    },
    {
      id: 'morning-peak-1',
      title: 'Morning Energy Peak',
      startTime: '07:10',
      endTime: '07:45',
    },
    {
      id: 'morning-fade-1',
      title: 'Morning Energy Fade',
      startTime: '07:45',
      endTime: '08:05',
    },
    {
      id: 'morning-recharge-1',
      title: 'Morning Energy Recharge',
      startTime: '08:05',
      endTime: '08:40',
    },
    {
      id: 'morning-rise-2',
      title: 'Morning Energy Rise',
      startTime: '08:40',
      endTime: '09:00',
    },
    {
      id: 'morning-peak-2',
      title: 'Morning Energy Peak',
      startTime: '09:00',
      endTime: '09:35',
    },
    {
      id: 'morning-fade-2',
      title: 'Morning Energy Fade',
      startTime: '09:35',
      endTime: '09:55',
    },
    {
      id: 'morning-recharge-2',
      title: 'Morning Energy Recharge',
      startTime: '09:55',
      endTime: '10:30',
    },
    {
      id: 'midday-rise-1',
      title: 'Midday Energy Rise',
      startTime: '10:30',
      endTime: '10:50',
    },
    {
      id: 'midday-peak-1',
      title: 'Midday Energy Peak',
      startTime: '10:50',
      endTime: '11:25',
    },
    {
      id: 'midday-fade-1',
      title: 'Midday Energy Fade',
      startTime: '11:25',
      endTime: '11:45',
    },
    {
      id: 'midday-recharge-1',
      title: 'Midday Energy Recharge',
      startTime: '11:45',
      endTime: '12:20',
    },
    {
      id: 'afternoon-rise-1',
      title: 'Afternoon Energy Rise',
      startTime: '12:20',
      endTime: '12:40',
    },
    {
      id: 'afternoon-peak-1',
      title: 'Afternoon Energy Peak',
      startTime: '12:40',
      endTime: '13:15',
    },
    {
      id: 'afternoon-fade-1',
      title: 'Afternoon Energy Fade',
      startTime: '13:15',
      endTime: '13:35',
    },
    {
      id: 'afternoon-recharge-1',
      title: 'Afternoon Energy Recharge',
      startTime: '13:35',
      endTime: '14:10',
    },
    {
      id: 'afternoon-rise-2',
      title: 'Afternoon Energy Rise',
      startTime: '14:10',
      endTime: '14:30',
    },
    {
      id: 'afternoon-peak-2',
      title: 'Afternoon Energy Peak',
      startTime: '14:30',
      endTime: '15:05',
    },
    {
      id: 'afternoon-fade-2',
      title: 'Afternoon Energy Fade',
      startTime: '15:05',
      endTime: '15:25',
    },
    {
      id: 'afternoon-recharge-2',
      title: 'Afternoon Energy Recharge',
      startTime: '15:25',
      endTime: '16:00',
    },
    {
      id: 'afternoon-rise-3',
      title: 'Afternoon Energy Rise',
      startTime: '16:00',
      endTime: '16:20',
    },
    {
      id: 'afternoon-peak-3',
      title: 'Afternoon Energy Peak',
      startTime: '16:20',
      endTime: '16:55',
    },
    {
      id: 'afternoon-fade-3',
      title: 'Afternoon Energy Fade',
      startTime: '16:55',
      endTime: '17:15',
    },
    {
      id: 'afternoon-recharge-3',
      title: 'Afternoon Energy Recharge',
      startTime: '17:15',
      endTime: '17:50',
    },
    {
      id: 'evening-rise-1',
      title: 'Evening Energy Rise',
      startTime: '17:50',
      endTime: '18:10',
    },
    {
      id: 'evening-peak-1',
      title: 'Evening Energy Peak',
      startTime: '18:10',
      endTime: '18:45',
    },
    {
      id: 'evening-fade-1',
      title: 'Evening Energy Fade',
      startTime: '18:45',
      endTime: '19:05',
    },
    {
      id: 'evening-recharge-1',
      title: 'Evening Energy Recharge',
      startTime: '19:05',
      endTime: '19:40',
    },
    {
      id: 'evening-rise-2',
      title: 'Evening Energy Rise',
      startTime: '19:40',
      endTime: '20:00',
    },
    {
      id: 'evening-peak-2',
      title: 'Evening Energy Peak',
      startTime: '20:00',
      endTime: '20:35',
    },
    {
      id: 'evening-fade-2',
      title: 'Evening Energy Fade',
      startTime: '20:35',
      endTime: '20:55',
    },
    {
      id: 'evening-recharge-2',
      title: 'Evening Energy Recharge',
      startTime: '20:55',
      endTime: '21:30',
    },
    {
      id: 'evening-rise-3',
      title: 'Evening Energy Rise',
      startTime: '21:30',
      endTime: '21:50',
    },
    {
      id: 'evening-peak-3',
      title: 'Evening Energy Peak',
      startTime: '21:50',
      endTime: '22:25',
    },
    {
      id: 'evening-fade-3',
      title: 'Evening Energy Fade',
      startTime: '22:25',
      endTime: '22:45',
    },
    {
      id: 'evening-recharge-3',
      title: 'Evening Energy Recharge',
      startTime: '22:45',
      endTime: '23:20',
    },
    {
      id: 'night-rise-1',
      title: 'Night Energy Rise',
      startTime: '23:20',
      endTime: '23:40',
    },
    {
      id: 'night-optimum-1',
      title: 'Night Energy Optimum',
      startTime: '23:40',
      endTime: '00:15',
    },
    {
      id: 'night-fade-1',
      title: 'Night Energy Fade',
      startTime: '00:15',
      endTime: '00:35',
    },
    {
      id: 'night-dip-1',
      title: 'Night Energy Dip',
      startTime: '00:35',
      endTime: '01:10',
    },
    {
      id: 'night-rise-2',
      title: 'Night Energy Rise',
      startTime: '01:10',
      endTime: '01:30',
    },
    {
      id: 'night-optimum-2',
      title: 'Night Energy Optimum',
      startTime: '01:30',
      endTime: '02:05',
    },
    {
      id: 'night-fade-2',
      title: 'Night Energy Fade',
      startTime: '02:05',
      endTime: '02:25',
    },
    {
      id: 'night-dip-2',
      title: 'Night Energy Dip',
      startTime: '02:25',
      endTime: '03:00',
    },
    {
      id: 'night-rise-3',
      title: 'Night Energy Rise',
      startTime: '03:00',
      endTime: '03:20',
    },
    {
      id: 'night-optimum-3',
      title: 'Night Energy Optimum',
      startTime: '03:20',
      endTime: '03:55',
    },
    {
      id: 'night-fade-3',
      title: 'Night Energy Fade',
      startTime: '03:55',
      endTime: '04:15',
    },
    {
      id: 'night-dip-3',
      title: 'Night Energy Dip',
      startTime: '04:15',
      endTime: '04:50',
    },
    {
      id: 'night-rise-4',
      title: 'Night Energy Rise',
      startTime: '04:50',
      endTime: '05:10',
    },
    {
      id: 'night-optimum-4',
      title: 'Night Energy Optimum',
      startTime: '05:10',
      endTime: '05:45',
    },
    {
      id: 'night-fade-4',
      title: 'Night Energy Fade',
      startTime: '05:45',
      endTime: '06:05',
    },
    {
      id: 'night-dip-4',
      title: 'Night Energy Dip',
      startTime: '06:05',
      endTime: '06:50',
    },
  ];

  for (const entry of schedule) {
    const startHour = parseInt(entry.startTime.split(':')[0]);
    const startMinute = parseInt(entry.startTime.split(':')[1]);

    const endHour = parseInt(entry.endTime.split(':')[0]);
    const endMinute = parseInt(entry.endTime.split(':')[1]);

    // Check if the current time is within the range of startTime and endTime
    if (
      ((currentHour > startHour ||
        (currentHour === startHour && currentMinute >= startMinute)) &&
        (currentHour < endHour ||
          (currentHour === endHour && currentMinute <= endMinute))) ||
      (endHour < startHour &&
        ((currentHour === endHour && currentMinute <= endMinute) ||
          (currentHour === startHour && currentMinute >= startMinute)))
    ) {
      return entry.title;
    }

    // Check if the current time matches exactly with startTime or endTime
    if (
      (currentHour === startHour && currentMinute === startMinute) ||
      (currentHour === endHour && currentMinute === endMinute)
    ) {
      return entry.title;
    }
  }

  return '';
}

const soundScapes = [
  {
    name: 'Focus',
    mode: 'focus',
    icon: FocusIcon,
    playLists: 8,
  },
  {
    name: 'Deeper Focus',
    mode: 'deeper',
    icon: DeeperIcon,
    playLists: 3,
  },
  {
    name: 'Study',
    mode: 'study',
    icon: StudyIcon,
    playLists: 8,
  },
  {
    name: 'Wiggly Wisdom',
    mode: 'wisdom',
    icon: WisdomIcon,
    playLists: 8,
  },
  {
    name: 'Spatial Orbit',
    mode: 'spatial',
    icon: SpatialIcon,
    playLists: 2,
  },
  {
    name: 'Recovery',
    mode: 'recovery',
    icon: RecoveryIcon,
    playLists: 3,
  },
  {
    name: 'Relax',
    mode: 'relax',
    icon: RelaxIcon,
    playLists: 9,
  },
  {
    name: 'Wind Down',
    mode: 'winddown',
    icon: WindDownIcon,
    playLists: 4,
  },
  {
    name: 'Sleep',
    mode: 'sleep',
    icon: SleepIcon,
    playLists: 4,
  },
  {
    name: 'Hibernation',
    mode: 'hibernation',
    icon: HibernationIcon,
    playLists: 3,
  },
  {
    name: 'Rainy Outside',
    mode: 'rainy',
    icon: RainyIcon,
    playLists: 6,
  },
  {
    name: 'AI Lullaby',
    mode: 'grimes',
    icon: GrimesIcon,
    playLists: 3,
  },
];

function getRandomPlaylistNumber(playlistsNumber: number) {
  return Math.floor(Math.random() * playlistsNumber) + 1;
}

function getRandomPlaylistNumberExcludingPlayedNumbers(
  excludeList: number[],
  playlistsNumber: number,
) {
  let randomNumber;
  do {
    randomNumber = Math.floor(Math.random() * playlistsNumber) + 1;
  } while (excludeList.includes(randomNumber));
  return randomNumber;
}
