import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import {
  Track as TrackInterface,
  setisSearchActive,
} from '../../reducers/librarySlice';
import { muiTheme, muiRootStyles } from '../../styles/mui/mui-styles-library';
import {
  muiBlueLibraryTheme,
  muiBlueLibraryRootStyles,
} from '../../styles/mui/mui-styles-library-blue';
import { StoreState } from '../../store/store';
import Track from './Track';
import { PlayerRefType } from '../Player';
import {
  muiWhiteLibraryTheme,
  muiWhiteLibraryRootStyles,
} from '../../styles/mui/mui-styles-library-white';

interface SearchProps {
  tracksToSearchFrom?: TrackInterface[];
  isGenreSearch: boolean;
  playerRef: PlayerRefType;
}

const Search = (props: SearchProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const dispatch = useDispatch();
  const isSubscribed = useSelector(
    (state: StoreState) => state.user.subscribed,
  );
  const { tracksToSearchFrom, isGenreSearch, playerRef } = props;

  const allTracks = useSelector((state: StoreState) => state.library.tracks);
  let tracks = tracksToSearchFrom ? tracksToSearchFrom : allTracks;
  tracks = isSubscribed
    ? tracks
    : tracks.filter((track) => {
        const trackIsPremium = track.premium === 1;
        return !trackIsPremium;
      });

  const [searchValue, setSearchValue] = useDebounce(500, '');
  const [tracksBySearch, setTracksBySearch] = useState<TrackInterface[]>([]);
  const [premiumOnlyTrackNotication, setPremiumOnlyTrackNotication] =
    useState(false);

  const [filters, setFilters] = useState({
    trackNameFilters: true,
    genreFilters: false,
    tagFilters: false,
    instrumentFilters: false,
  });

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const handleSearchInputChange = (searchValue: string) => {
      if (searchValue !== '') {
        setTracksBySearch(
          filterTracksBySelectedFilters(tracks, searchValue, filters),
        );
        dispatch(setisSearchActive(true));
      } else {
        setTracksBySearch([]);
        dispatch(setisSearchActive(false));
      }
    };

    handleSearchInputChange(searchValue);
  }, [searchValue]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.checked,
    });
  };

  const { trackNameFilters, genreFilters, tagFilters, instrumentFilters } =
    filters;

  const openPremiumOnlyTrackNotication = () => {
    setPremiumOnlyTrackNotication(true);
  };

  const closePremiumOnlyTrackNotication = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setPremiumOnlyTrackNotication(false);
  };

  const handlePremiumOnlyTrackSnackbarClose = () => {
    setPremiumOnlyTrackNotication(false);
  };

  const premiumOnlyTrackAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handlePremiumOnlyTrackSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="search-box-and-filters-container">
      <ThemeProvider
        theme={
          selectedTheme === 'spotify dark'
            ? muiTheme
            : selectedTheme === 'dark blue'
            ? muiBlueLibraryTheme
            : muiWhiteLibraryTheme
        }
      >
        <TextField
          fullWidth
          onChange={handleSearchChange}
          name="search"
          style={textFieldStyle}
          type="input"
          id="search"
          sx={
            selectedTheme === 'spotify dark'
              ? { ...muiRootStyles }
              : selectedTheme === 'dark blue'
              ? { ...muiBlueLibraryRootStyles }
              : { ...muiWhiteLibraryRootStyles }
          }
          placeholder="Search"
          InputProps={{
            style: { fontSize: 16 },
          }}
          InputLabelProps={{
            style: {
              fontSize: 16,
              paddingRight: 10,
              backgroundColor: '#121212',
            },
          }}
        />
      </ThemeProvider>

      <Snackbar
        open={premiumOnlyTrackNotication}
        autoHideDuration={6000}
        onClose={closePremiumOnlyTrackNotication}
        message="Premium plan is required to listen to this track."
        action={premiumOnlyTrackAction}
      />

      {/* <div className="filters-container">
        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? expandIconStylesWhiteTheme
                    : expandIconStyles
                }
              />
            }
          >
            <h3
              className={
                selectedTheme === 'spotify dark'
                  ? 'filters-container__title filters-container__title--dark'
                  : selectedTheme === 'dark blue'
                  ? 'filters-container__title filters-container__title--blue'
                  : 'filters-container__title filters-container__title--white'
              }
            >
              Filters
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={
                        selectedTheme === 'spotify dark'
                          ? darkThemeColor
                          : selectedTheme === 'dark blue'
                          ? blueThemeColor
                          : whiteThemeColor
                      }
                      checked={trackNameFilters}
                      onChange={handleChange}
                      name="trackNameFilters"
                      disableRipple={true}
                    />
                  }
                  label={
                    <span
                      className={
                        selectedTheme === 'spotify dark'
                          ? 'filters-container__checkbox-label filters-container__checkbox-label--dark'
                          : selectedTheme === 'dark blue'
                          ? 'filters-container__checkbox-label filters-container__checkbox-label--blue'
                          : 'filters-container__checkbox-label filters-container__checkbox-label--white'
                      }
                    >
                      Track name
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={
                        selectedTheme === 'spotify dark'
                          ? darkThemeColor
                          : selectedTheme === 'dark blue'
                          ? blueThemeColor
                          : whiteThemeColor
                      }
                      checked={isGenreSearch ? true : genreFilters}
                      onChange={handleChange}
                      name="genreFilters"
                      disabled={isGenreSearch}
                      disableRipple={true}
                    />
                  }
                  label={
                    <span
                      className={
                        selectedTheme === 'spotify dark'
                          ? 'filters-container__checkbox-label filters-container__checkbox-label--dark'
                          : selectedTheme === 'dark blue'
                          ? 'filters-container__checkbox-label filters-container__checkbox-label--blue'
                          : 'filters-container__checkbox-label filters-container__checkbox-label--white'
                      }
                    >
                      Genre
                    </span>
                  }
                />
              </FormGroup>
            </FormControl>
            <FormControl
              required
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={
                        selectedTheme === 'spotify dark'
                          ? darkThemeColor
                          : selectedTheme === 'dark blue'
                          ? blueThemeColor
                          : whiteThemeColor
                      }
                      checked={tagFilters}
                      onChange={handleChange}
                      name="tagFilters"
                      disableRipple={true}
                    />
                  }
                  label={
                    <span
                      className={
                        selectedTheme === 'spotify dark'
                          ? 'filters-container__checkbox-label filters-container__checkbox-label--dark'
                          : selectedTheme === 'dark blue'
                          ? 'filters-container__checkbox-label filters-container__checkbox-label--blue'
                          : 'filters-container__checkbox-label filters-container__checkbox-label--white'
                      }
                    >
                      Tags
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={
                        selectedTheme === 'spotify dark'
                          ? darkThemeColor
                          : selectedTheme === 'dark blue'
                          ? blueThemeColor
                          : whiteThemeColor
                      }
                      checked={instrumentFilters}
                      onChange={handleChange}
                      name="instrumentFilters"
                      disableRipple={true}
                    />
                  }
                  label={
                    <span
                      className={
                        selectedTheme === 'spotify dark'
                          ? 'filters-container__checkbox-label filters-container__checkbox-label--dark'
                          : selectedTheme === 'dark blue'
                          ? 'filters-container__checkbox-label filters-container__checkbox-label--blue'
                          : 'filters-container__checkbox-label filters-container__checkbox-label--white'
                      }
                    >
                      Instruments
                    </span>
                  }
                />
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </div> */}

      {tracksBySearch.length !== 0 &&
        tracksBySearch.map((track: TrackInterface, i) => {
          return (
            <Track
              premium={track.premium === 1}
              openPremiumOnlyTrackNotication={openPremiumOnlyTrackNotication}
              playerRef={playerRef}
              key={i}
              name={track.name}
              path={track.path}
              genre={track.genre}
              tags={track.tags}
              instruments={track.instruments}
              id={track.id}
              addedOnDate={track.addedOnDate}
              category={track.category}
              additionalCategory={track.additionalCategory}
              additionalSecondCategory={track.additionalSecondCategory}
              imageUrl={track.imageUrl}
              displayImageUrl={track.displayImageUrl}
              description={track.description}
              pathLinkId={track.pathLinkId}
              lowNeuralPath={track.lowNeuralPath}
              mediumNeuralPath={track.mediumNeuralPath}
              highNeuralPath={track.highNeuralPath}
              linkPath={track.linkPath}
              lowNeuralLinkPath={track.lowNeuralLinkPath}
              mediumNeuralLinkPath={track.mediumNeuralLinkPath}
              highNeuralLinkPath={track.highNeuralLinkPath}
            />
          );
        })}
    </div>
  );
};

const textFieldStyle = {
  color: '#1ed760',
};

const darkThemeColor = {
  color: '#1ed760',
};

const blueThemeColor = {
  color: '#f39c12',
};

const whiteThemeColor = {
  color: '#2f2f2f',
};

const customAccordionStyles = {
  backgroundColor: 'transparent',
  borderRadius: '1.2rem',
  border: 'none',
};

const expandIconStyles = {
  fontSize: '24px',
  color: '#fefefc',
  cursor: 'pointer',
};

const expandIconStylesWhiteTheme = {
  fontSize: '24px',
  color: '#2f2f2f',
  cursor: 'pointer',
};

export const useDebounce = (
  time: number,
  initialValue: string,
): [string, (v: string) => void] => {
  const [value, setValue] = useState<string>(initialValue);
  const values = useState(() => new Subject<string>())[0];

  useEffect(() => {
    const sub = values.pipe(debounceTime(time)).subscribe(setValue);
    return () => sub.unsubscribe();
  }, [time, values]);

  return [value, (v: string) => values.next(v)];
};

interface SearchFiltersInterface {
  trackNameFilters: boolean;
  genreFilters: boolean;
  tagFilters: boolean;
  instrumentFilters: boolean;
}

const filterTracksBySelectedFilters = (
  tracks: TrackInterface[],
  searchValue: string,
  filters: SearchFiltersInterface,
) => {
  const { trackNameFilters, genreFilters, tagFilters, instrumentFilters } =
    filters;

  return tracks.filter((track: TrackInterface) => {
    const foundByTrackName = track.name
      .toLowerCase()
      .includes(searchValue.toLowerCase());
    const foundByGenre = track.genre
      .toLowerCase()
      .includes(searchValue.toLowerCase());
    const foundByTag = track.tags.some((tag) =>
      tag.toLowerCase().includes(searchValue.toLowerCase()),
    );
    const foundByInstrument = track.instruments.some((instrument) =>
      instrument.toLowerCase().includes(searchValue.toLowerCase()),
    );

    if (trackNameFilters && genreFilters && tagFilters && instrumentFilters) {
      return (
        foundByTrackName || foundByGenre || foundByTag || foundByInstrument
      );
    } else if (
      trackNameFilters &&
      genreFilters &&
      tagFilters &&
      !instrumentFilters
    ) {
      return foundByTrackName || foundByGenre || foundByTag;
    } else if (
      trackNameFilters &&
      genreFilters &&
      !tagFilters &&
      instrumentFilters
    ) {
      return foundByTrackName || foundByGenre || foundByInstrument;
    } else if (
      trackNameFilters &&
      !genreFilters &&
      tagFilters &&
      instrumentFilters
    ) {
      return foundByTrackName || foundByTag || foundByInstrument;
    } else if (
      !trackNameFilters &&
      genreFilters &&
      tagFilters &&
      instrumentFilters
    ) {
      return foundByGenre || foundByTag || foundByInstrument;
    } else if (
      trackNameFilters &&
      genreFilters &&
      !tagFilters &&
      !instrumentFilters
    ) {
      return foundByTrackName || foundByGenre;
    } else if (
      trackNameFilters &&
      !genreFilters &&
      tagFilters &&
      !instrumentFilters
    ) {
      return foundByTrackName || foundByTag;
    } else if (
      trackNameFilters &&
      !genreFilters &&
      !tagFilters &&
      instrumentFilters
    ) {
      return foundByTrackName || foundByInstrument;
    } else if (
      !trackNameFilters &&
      genreFilters &&
      tagFilters &&
      !instrumentFilters
    ) {
      return foundByGenre || foundByTag;
    } else if (
      !trackNameFilters &&
      genreFilters &&
      !tagFilters &&
      instrumentFilters
    ) {
      return foundByGenre || foundByInstrument;
    } else if (
      !trackNameFilters &&
      !genreFilters &&
      tagFilters &&
      instrumentFilters
    ) {
      return foundByTag || foundByInstrument;
    } else if (
      trackNameFilters &&
      !genreFilters &&
      tagFilters &&
      instrumentFilters
    ) {
      return foundByTrackName || foundByTag || foundByInstrument;
    } else if (
      trackNameFilters &&
      genreFilters &&
      !tagFilters &&
      instrumentFilters
    ) {
      return foundByTrackName || foundByGenre || foundByInstrument;
    } else if (
      trackNameFilters &&
      genreFilters &&
      tagFilters &&
      !instrumentFilters
    ) {
      return foundByTrackName || foundByGenre || foundByTag;
    } else if (
      trackNameFilters &&
      !genreFilters &&
      !tagFilters &&
      !instrumentFilters
    ) {
      return foundByTrackName;
    } else if (
      !trackNameFilters &&
      genreFilters &&
      !tagFilters &&
      !instrumentFilters
    ) {
      return foundByGenre;
    } else if (
      !trackNameFilters &&
      !genreFilters &&
      tagFilters &&
      !instrumentFilters
    ) {
      return foundByTag;
    } else if (
      !trackNameFilters &&
      !genreFilters &&
      !tagFilters &&
      instrumentFilters
    ) {
      return foundByInstrument;
    } else {
      return [];
    }
  });
};

export default Search;
