import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from '../styles/mui/mui-loading-styles';
// import { muiBlueTheme } from '../styles/mui/mui-styles-blue';
import { muiWhiteTheme } from '../styles/mui/mui-styles-white';

interface LoadingProps {
  withoutContainer?: boolean;
}

const Loading = (props: LoadingProps) => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const { withoutContainer } = props;
  return (
    <ThemeProvider
      theme={
        selectedTheme === 'spotify dark' || selectedTheme === 'dark blue'
          ? muiTheme
          : muiWhiteTheme
      }
    >
      {withoutContainer ? (
        <CircularProgress
          size={60}
          color="primary"
          className="loading-container__icon"
        />
      ) : (
        <div className="loading-container">
          <CircularProgress
            size={60}
            color="primary"
            className="loading-container__icon"
          />
        </div>
      )}
    </ThemeProvider>
  );
};

export default Loading;
