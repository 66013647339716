import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  return (
    <div className="not-found-container">
      <p className="not-found-container__paragraph">404 - Page not found.</p>
      <Link
        className={
          selectedTheme === 'spotify dark'
            ? 'link--dark'
            : selectedTheme === 'spotify blue'
            ? 'link--blue'
            : 'link--white'
        }
        to="/"
      >
        <p className="not-found-container__paragraph">Home</p>
      </Link>
    </div>
  );
};

export default NotFoundPage;
