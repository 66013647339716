import { useState, ChangeEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme, muiRootStyles } from '../styles/mui/mui-styles';
import { muiBlueTheme, muiBlueRootStyles } from '../styles/mui/mui-styles-blue';
import {
  muiWhiteTheme,
  muiWhiteRootStyles,
} from '../styles/mui/mui-styles-white';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { refreshTokens } from '../api/auth/login';
import { registerWithEmailandPassword } from '../api/auth/register';
import { sendWelcomeEmail } from '../api/auth/verify-email';
import {
  inputStyles,
  inputStylesForBlueTheme,
  inputStylesForWhiteTheme,
} from './SignIn';
import { isUserLoggedIn } from '../api/auth/login';
import Loading from './Loading';

const defaultFormFields = {
  email: '',
  password: '',
  confirmPassword: '',
};

export default function SignUp() {
  const selectedTheme = localStorage.getItem('lawfmtheme');

  const isLoggedIn = isUserLoggedIn();
  const [formFields, setFormFields] = useState(defaultFormFields);
  // const { email, password, confirmPassword } = formFields;
  const { email, password } = formFields;
  const [passwordErrorText, setPasswordErrorText] = useState('');
  // const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');
  // const [passWordsNotMatchError, setPassWordsNotMatchError] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEmailErrorText('');
    // setPassWordsNotMatchError('');
    setPasswordErrorText('');
    // setConfirmPasswordErrorText('');
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (email === '') {
      setIsLoading(false);
      setEmailErrorText('Please enter email.');
      return;
    }

    if (password === '') {
      setIsLoading(false);
      setPasswordErrorText('Please enter password.');
      return;
    }

    // if (confirmPassword === '') {
    //   setConfirmPasswordErrorText('Please enter password');
    //   return;
    // }

    // if (password !== confirmPassword) {
    //   setPassWordsNotMatchError('Passwords do not match.');
    //   return;
    // }

    try {
      const { user } = await registerWithEmailandPassword(email, password);
      const { firstHash, secondHash } = user;
      await sendWelcomeEmail(email);
      refreshTokens({ firstHash, secondHash });
      resetFormFields();

      navigate('/welcome');

      // if (locationState) {
      //   navigate(`${locationState.from.pathname}${locationState.from.search}`);
      //   window.location.reload();
      // } else {
      //   navigate('/');
      //   window.location.reload();
      // }
    } catch (error) {
      if (error.code === 'weak-password') {
        setIsLoading(false);
        setPasswordErrorText(error.message);
      } else if (error.code === 'email-already-in-use') {
        setIsLoading(false);
        setEmailErrorText(error.message);
      } else if (error.code === 'invalid-email') {
        setIsLoading(false);
        setEmailErrorText(error.message);
      } else if (error.code === 'too-many-requests') {
        setIsLoading(false);
        setPasswordErrorText(error.message);
      } else if (error instanceof TypeError) {
        setIsLoading(false);
        setPasswordErrorText(
          'Too many requests from this IP, please try again in 15 minutes.',
        );
      } else {
        setIsLoading(false);
        setPasswordErrorText(
          'Interal error, if the issue persists, please contact support.',
        );
      }
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  } else if (isLoading) {
    return <Loading />;
  } else {
    return (
      <ThemeProvider
        theme={
          selectedTheme === 'youtube white'
            ? muiWhiteTheme
            : selectedTheme === 'dark blue'
            ? muiBlueTheme
            : muiTheme
        }
      >
        <Container component="main" maxWidth="xs">
          {/* <CssBaseline /> */}
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    sx={
                      selectedTheme === 'youtube white'
                        ? { ...muiWhiteRootStyles }
                        : selectedTheme === 'dark blue'
                        ? { ...muiBlueRootStyles }
                        : { ...muiRootStyles }
                    }
                    value={email}
                    helperText={emailErrorText}
                    error={emailErrorText !== ''}
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={
                      selectedTheme === 'youtube white'
                        ? { style: inputStylesForWhiteTheme }
                        : selectedTheme === 'dark blue'
                        ? { style: inputStylesForBlueTheme }
                        : {
                            style: inputStyles,
                          }
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    sx={
                      selectedTheme === 'youtube white'
                        ? { ...muiWhiteRootStyles }
                        : selectedTheme === 'dark blue'
                        ? { ...muiBlueRootStyles }
                        : { ...muiRootStyles }
                    }
                    value={password}
                    // helperText={
                    //   passWordsNotMatchError !== ''
                    //     ? passWordsNotMatchError
                    //     : passWordErrorText
                    // }
                    helperText={passwordErrorText}
                    // error={
                    //   passWordsNotMatchError !== '' || passWordErrorText !== ''
                    // }
                    error={passwordErrorText !== ''}
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={
                      selectedTheme === 'youtube white'
                        ? { style: inputStylesForWhiteTheme }
                        : selectedTheme === 'dark blue'
                        ? { style: inputStylesForBlueTheme }
                        : {
                            style: inputStyles,
                          }
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirm-password"
                    autoComplete="new-password"
                    onChange={handleChange}
                       sx={
                selectedTheme === 'spotify dark'
                  ? { ...muiRootStyles }
                  : { ...muiBlueRootStyles }
              }
                    value={confirmPassword}
                    helperText={
                      passWordsNotMatchError !== ''
                        ? passWordsNotMatchError
                        : confirmPasswordErrorText
                    }
                    error={
                      passWordsNotMatchError !== '' ||
                      confirmPasswordErrorText !== ''
                    }
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{
                      style: inputStyles,
                    }}
                  />
                </Grid> */}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color:
                    selectedTheme === 'youtube white' ? '#e7e7e7' : '#121212',
                  fontSize: '12px',
                  fontWeight: '500',
                }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <RouterLink
                    to="/login"
                    className={
                      selectedTheme === 'youtube white' ? 'black-link' : 'link'
                    }
                  >
                    Already have an account? Login.
                  </RouterLink>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}
