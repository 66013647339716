import Accordion from '@mui/material/Accordion';
import YouTube from 'react-youtube';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FAQIcon from '../styles/icons/question-icon.svg';
// import BitcoinTransferIcon from '../styles/icons/bitcoin-transfer.svg';
// import BitcoinTransferIcon from '../styles/icons/bitcoin-transfer-icon.svg';
import FrequentlyAskedQuestionsIcon from '../styles/icons/faq-icon.svg';
import FrequentlyAskedQuestionsIconWhiteTheme from '../styles/icons/faq-icon-white-theme.svg';
import {
  iconStyles,
  iconStylesWhiteTheme,
  customAccordionStyles,
  accordionQuestionStyles,
  accordionQuestionAnswerStyles,
  accordionQuestionStylesWhiteTheme,
  accordionQuestionAnswerStylesWhiteTheme,
} from './FrequentlyAskedQuestions';

const PaymentFAQ = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

  const onReady = (event: any) => {
    event.target.pauseVideo();
  };

  return (
    <div className="row">
      <div className="payment-faq-container">
        <h2 className="payment-faq-container__heading secondary-heading">
          Payments FAQ
        </h2>
        {/* <div className="payment-faq-container-icon-container">
          <img
            className="payment-faq-container-icon-container__icon"
            src={FAQIcon}
            alt="payment-faq-icon"
          />
        </div> */}
        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              I don't own any Bitcoin. Can I still pay for the premium?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              Yes, if you don't own any Bitcoin then you can use services like{' '}
              <a
                className={
                  selectedTheme === 'spotify dark'
                    ? 'link--dark'
                    : selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : 'link--white'
                }
                href="https://www.moonpay.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                MoonPay
              </a>{' '}
              to buy Bitcoin with credit/debit card instantly. Keep in mind
              services like that have high fees in general. The cheapest way is
              to purchase Bitcoin on your preferred cryptocurrency exchange and
              make a transaction from there (some well known cryptocurrency
              exchanges - Coinbase, Kraken, Binance).{' '}
              <a
                className={
                  selectedTheme === 'spotify dark'
                    ? 'link--dark'
                    : selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : 'link--white'
                }
                href="https://cryptocurrencyhelp.com/article/how-to-buy-bitcoin"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about how to buy Bitcoin
              </a>
              .
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              How to pay for the premium using Bitcoin.com wallet app?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="h3"
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              <p className="accordion-paragraph-with-padding">
                Pay for the premium in minutes with the self-custodial
                Bitcoin.com wallet app:
              </p>
              1. Download the Bitcoin.com wallet. <br /> 2. Follow the
              instructions to buy Bitcoin.
              <YouTube
                className="accordion-video-container"
                videoId="b3FVnkA8PVU"
                opts={opts}
                onReady={onReady}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Can I pay using Lightning Network?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              Yes, you can use both Bitcoin network and Lightning network.
              Please make sure to choose Lightning network when you do decide to
              pay with it on the invoice page. Additionally, we recommend using
              Lightning network if possible not only because the payments are
              instant, but the sending fees are very low in general and some
              cryptocurrency exchanges have no fees at all for sending Bitcoin
              over Lightning network.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              My current premium plan is still active, but I want to extend it.
              How can I do that?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              Extending your current premium plan is easy. When you choose to
              extend, the additional time will be seamlessly added to your
              existing premium plan. For example, if your current premium plan
              is set to expire on 01/01/2024, purchasing a Premium month plan
              will result in your plan's end date to be 02/02/2024.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              How fast are the Bitcoin payments?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              Bitcoin payments over Lightning network are instant. Bitcoin
              payments using regular on-chain Bitcoin network are usually
              settled within 10 minutes (1 blockchain confirmation is required).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              I have more questions. How can I contact you?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              {`${process.env.REACT_APP_CONTACT_EMAIL}`}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default PaymentFAQ;
