import { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import Settings from './Settings';
import { StoreState } from '../../store/store';
import ModalBlur from '../../styles/icons/modal-blur.svg';

Modal.setAppElement('#root');

interface SettingsModalProps {
  isSettingsModalOpen: boolean;
  toggleSettingsModal: MouseEventHandler<SVGSVGElement>;
}

const SettingsModal = (props: SettingsModalProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const { isSettingsModalOpen, toggleSettingsModal } = props;

  const customStyles = {
    content: {
      backgroundImage: `url(${ModalBlur})`,
    },
  };

  return (
    <Modal
      style={displayTheme ? customStyles : {}}
      isOpen={isSettingsModalOpen}
      onRequestClose={toggleSettingsModal}
      className={
        displayTheme
          ? 'modal modal--general'
          : selectedTheme === 'spotify dark'
          ? 'modal modal--dark'
          : selectedTheme === 'dark blue'
          ? 'modal modal--blue'
          : 'modal modal--white'
      }
      overlayClassName="modal-overlay"
    >
      <div className="modal-outer-container">
        <Settings toggleSettingsModal={toggleSettingsModal} />
      </div>
    </Modal>
  );
};

export default SettingsModal;
