import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FAQIcon from '../styles/icons/question-icon.svg';
// import FrequentlyAskedQuestionsIcon from '../styles/icons/faq.svg';
// import FrequentlyAskedQuestionsIcon from '../styles/icons/faq-icon.svg';
// import FrequentlyAskedQuestionsIconWhiteTheme from '../styles/icons/faq-icon-white-theme.svg';

export const iconStyles = {
  fontSize: '24px',
  color: '#fefefc',
  cursor: 'pointer',
};

export const iconStylesWhiteTheme = {
  fontSize: '24px',
  color: '#2f2f2f',
  cursor: 'pointer',
};

export const customAccordionStyles = {
  backgroundColor: 'hsla(0, 0%, 100%, 0.1)',
};

export const accordionQuestionStyles = {
  color: '#e7e7e7',
  fontSize: '1.8rem',
};

export const accordionQuestionAnswerStyles = {
  color: 'hsla(0, 0%, 100%, 0.6)',
  fontSize: '1.6rem',
};

export const accordionQuestionStylesWhiteTheme = {
  color: '#414141',
  fontSize: '1.8rem',
};

export const accordionQuestionAnswerStylesWhiteTheme = {
  color: '#414141',
  fontSize: '1.6rem',
};

const FrequentlyAskedQuestions = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  return (
    <section className="section-frequently-asked-questions">
      <div className="row frequently-asked-questions-container">
        <h2 className="frequently-asked-questions-container__heading secondary-heading">
          FAQ
        </h2>

        {/* <div className="frequently-asked-questions-icon-container">
          <img
            className="frequently-asked-questions-icon-container__icon"
            // src={
            //   selectedTheme === 'youtube white'
            //     ? FrequentlyAskedQuestionsIconWhiteTheme
            //     : FrequentlyAskedQuestionsIcon
            // }
            src={FAQIcon}
            alt="frequently-asked-questions"
          />
        </div> */}

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Will this music really help me focus?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              Yes, we only use music that is scientifically proven to help you
              focus. The right music can also trigger a positive mood and
              motivation, creating an ideal environment for improved
              productivity. Experiment with different genres to find what works
              best for your concentration needs.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Why is this better for focus than normal music?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              The music on LAWFM is crafted to enhance your productivity by
              seamlessly blending into the background, enabling you to
              concentrate without distractions. It promotes sustained attention
              and productivity, fostering an optimal environment for
              accomplishing tasks effectively. On the other hand, normal music
              is engineered to captivate your attention, hindering your ability
              to think and work effectively, often without you even being aware
              of it.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Where do you get your music?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              Music, soundscapes are curated from{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://www.brain.fm/"
                target="_blank"
                rel="noopener noreferrer"
              >
                brainfm
              </a>
              ,{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://www.focusatwill.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                focus@will
              </a>
              ,{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://endel.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                endel
              </a>
              ,{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://www.headspace.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                headspace
              </a>{' '}
              and{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://calm.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                calm
              </a>
              . If you find the content of any particular source intriguing or
              valuable, we encourage you to consider subscribing directly to
              them to support their continued production of quality content.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Can I read more about the science behind this?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              Yes, you can read more about the science from following sources -{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://www.brain.fm/science"
                target="_blank"
                rel="noopener noreferrer"
              >
                brainfm
              </a>
              ,{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://www.focusatwill.com/app/pages/how-it-works"
                target="_blank"
                rel="noopener noreferrer"
              >
                focus@will
              </a>
              ,{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://endel.io/science"
                target="_blank"
                rel="noopener noreferrer"
              >
                endel
              </a>
              ,{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://www.headspace.com/science"
                target="_blank"
                rel="noopener noreferrer"
              >
                headspace
              </a>{' '}
              and{' '}
              <a
                className={
                  selectedTheme === 'dark blue'
                    ? 'link--blue'
                    : selectedTheme === 'youtube white'
                    ? 'link--white'
                    : 'link--dark'
                }
                href="https://business.calm.com/resources/clinical-studies/"
                target="_blank"
                rel="noopener noreferrer"
              >
                calm
              </a>
              .
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Are there different themes available?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              Yes, there are three themes to choose from: Spotify Dark, Youtube
              White, and Dark Blue. You can easily change the theme from the
              header dropdown menu. When 'Display theme' setting is enabled then
              the styling is the same for all of them inside Focus and Sleep
              categories. The soundscapes only has dark mode.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Are headphones required?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              No, headphones are not required. We recommend keeping the audio at
              the medium to low volume and if you are working in a noisy
              environment we certainly suggest using a set of noise canceling
              headphones if possible.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              It would be really awesome to have that x feature. Can I suggest
              you to implement it?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              {`Absolutely! We highly value user feedback and welcome your suggestions. Your input is crucial in shaping the future development of our offerings. While we cannot guarantee the implementation of every suggestion, we encourage you to share your ideas with us. Please feel free to email your suggestions to ${process.env.REACT_APP_CONTACT_EMAIL}. We appreciate your contribution to our ongoing efforts to deliver a better user experience. Thank you!`}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Does this website track or collect any data?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              No. We feel like every website nowadays tracks you to the obvilion
              and since privacy is very important for our users we don't collect
              or track any data. Not even Google Analytics. You could create an
              account with an anonymous email address if you wanted to.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={customAccordionStyles}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={
                  selectedTheme === 'youtube white'
                    ? iconStylesWhiteTheme
                    : iconStyles
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionStylesWhiteTheme
                  : accordionQuestionStyles
              }
            >
              Can you add other login options other than email and password?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={
                selectedTheme === 'youtube white'
                  ? accordionQuestionAnswerStylesWhiteTheme
                  : accordionQuestionAnswerStyles
              }
            >
              No, we don't want to share users data with anyone and adding 3rd
              party login options would compromise it.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </section>
  );
};

export default FrequentlyAskedQuestions;
