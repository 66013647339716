import { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { fetchUserDashboardData } from '../api/user/user';
import PricingBox from './Pricing-box';
import Loading from './Loading';
import { isUserLoggedIn, signUserOut } from '../api/auth/login';
import NotFoundPage from './NotFoundPage';
import { plansData } from './Pricing';
import PaymentFAQ from './PaymentFAQ';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Premium = () => {
  const isLoggedIn = isUserLoggedIn();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const planId = params.get('plan');
  const planData = plansData.find((plan) => plan.id === planId);
  const [user, setUser] = useState({
    email: '',
    subscribed: false,
    subscribedTillDate: '',
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const fetchData = async () => {
      try {
        const userData = await fetchUserDashboardData();
        const { email, subscribed, subscribedTillDate } = userData;
        setUser({ email, subscribed, subscribedTillDate });
        setIsDataLoaded(true);
      } catch (error) {
        if (
          error.message === 'Unauthorized.' ||
          error.code === 'too-many-requests'
        ) {
          signUserOut();
        }

        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else if (!isDataLoaded) {
    return <Loading />;
  } else if (
    planId !== 'premium-month' &&
    planId !== 'premium-year' &&
    planId !== 'all-plans'
  ) {
    return <NotFoundPage />;
  } else if (planData || planId === 'all-plans') {
    return (
      <section className="section-payment">
        <div className="premium-plan-container">
          <ScrollToTop />
          <h1 className="premium-plan-container__title secondary-heading">
            Premium Plan Payment
          </h1>

          {!user.subscribed && (
            <p className="premium-plan-container__paragraph-main paragraph">
              After clicking on the PAY button, you will be redirected to
              payment page where a unique BTC payment address will be generated
              for you.
            </p>
          )}

          {user.subscribed && (
            <p className="premium-plan-container__paragraph paragraph">
              {`Your current premium plan expires on ${user.subscribedTillDate}`}
              .
            </p>
          )}

          {user.subscribed && (
            <p className="premium-plan-container__paragraph premium-plan-container__paragraph--last paragraph">
              You have the option to extend the duration of your premium plan by
              making a payment now (more details in FAQ).
            </p>
          )}

          {planId === 'all-plans' && (
            <div className="row">
              <div className="col-1-of-2">
                <div className="premium-plan-pricing-box-container">
                  <PricingBox
                    title={plansData[1].title}
                    price={plansData[1].price}
                    promoPrice={plansData[1].promoPrice}
                    isPremium={plansData[1].isPremium}
                    isOnPaymentPage={true}
                    id={plansData[1].id}
                    email={user.email}
                  />
                </div>
              </div>

              <div className="col-1-of-2">
                <div className="premium-plan-pricing-box-container">
                  <PricingBox
                    title={plansData[2].title}
                    price={plansData[2].price}
                    promoPrice={plansData[2].promoPrice}
                    isPremium={plansData[2].isPremium}
                    isOnPaymentPage={true}
                    id={plansData[2].id}
                    email={user.email}
                  />
                </div>
              </div>
            </div>
          )}

          {planData &&
            (planId === 'premium-month' || planId === 'premium-year') && (
              <div className="premium-plan-pricing-box-container">
                <PricingBox
                  title={planData.title}
                  price={planData.price}
                  promoPrice={planData.promoPrice}
                  isPremium={planData.isPremium}
                  isOnPaymentPage={true}
                  id={planData.id}
                  email={user.email}
                />
              </div>
            )}

          <PaymentFAQ />
        </div>
      </section>
    );
  } else {
    return <NotFoundPage />;
  }
};

export default Premium;
