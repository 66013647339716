import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InfoTooltip from '../../styles/mui/InfoTooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { capitalizeFirstLetterOfString } from './Library';
import Track from './Track';
import Search from './Search';
import { PlayerRefType } from '../Player';
import { StoreState } from '../../store/store';

interface GenreProps {
  tabTitle: string;
  isGenreSearch: boolean;
  tooltipText?: string;
  noTracksFoundText?: string;
  playerRef: PlayerRefType;
}

const Genre = (props: GenreProps) => {
  const isSubscribed = useSelector(
    (state: StoreState) => state.user.subscribed,
  );

  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const { tabTitle, isGenreSearch, tooltipText, noTracksFoundText, playerRef } =
    props;
  const favororiteTrackIds = useSelector(
    (state: StoreState) => state.user.favorites,
  );
  const playedTodayTrackIds = useSelector(
    (state: StoreState) => state.user.playedToday,
  );
  const uninterestedTrackIds = useSelector(
    (state: StoreState) => state.user.uninterested,
  );
  const tracks = useSelector((state: StoreState) => state.library.tracks);
  const isSearchActive = useSelector(
    (state: StoreState) => state.library.isSearchActive,
  );
  const [premiumOnlyTrackNotication, setPremiumOnlyTrackNotication] =
    useState(false);

  let tabTracks;
  if (tabTitle === 'favorites') {
    tabTracks = tracks
      .filter((track) => favororiteTrackIds.includes(track.id))
      .sort((a, b) => (a.premium === b.premium ? 0 : a.premium ? 1 : -1));
  } else if (tabTitle === 'played today') {
    tabTracks = tracks
      .filter((track) => playedTodayTrackIds.includes(track.id))
      .sort((a, b) => (a.premium === b.premium ? 0 : a.premium ? 1 : -1));
  } else if (tabTitle === 'not interested') {
    tabTracks = tracks
      .filter((track) => uninterestedTrackIds.includes(track.id))
      .sort((a, b) => (a.premium === b.premium ? 0 : a.premium ? 1 : -1));
  } else {
    if (isSubscribed) {
      tabTracks = tracks
        .filter((track) => track.genre === tabTitle)
        .sort((a, b) => {
          if (a.addedOnDate === '' && b.addedOnDate === '') return 0;
          if (a.addedOnDate === '') return 1;
          if (b.addedOnDate === '') return -1;

          if (a.premium !== b.premium) {
            return a.premium ? 1 : -1;
          }

          const dateA = new Date(a.addedOnDate).getTime();
          const dateB = new Date(b.addedOnDate).getTime();
          return dateB - dateA;
        });
    } else {
      tabTracks = tracks
        .filter((track) => track.genre === tabTitle)
        .sort((a, b) => (a.premium === b.premium ? 0 : a.premium ? 1 : -1));
    }
  }

  const openPremiumOnlyTrackNotication = () => {
    setPremiumOnlyTrackNotication(true);
  };

  const closePremiumOnlyTrackNotication = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setPremiumOnlyTrackNotication(false);
  };

  const handlePremiumOnlyTrackSnackbarClose = () => {
    setPremiumOnlyTrackNotication(false);
  };

  const premiumOnlyTrackAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handlePremiumOnlyTrackSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="genre-container">
      {tooltipText ? (
        <div className="title-and-info-icon-container">
          <h2
            className={
              selectedTheme === 'spotify dark'
                ? 'genre-container__title genre-container__title--with-tooltip genre-container__title--dark'
                : selectedTheme === 'dark blue'
                ? 'genre-container__title genre-container__title--with-tooltip genre-container__title--blue'
                : 'genre-container__title genre-container__title--with-tooltip genre-container__title--white'
            }
          >
            {capitalizeFirstLetterOfString(tabTitle)}
          </h2>
          <InfoTooltip popoverText={tooltipText} />
        </div>
      ) : (
        <h2
          className={
            selectedTheme === 'spotify dark'
              ? 'genre-container__title genre-container__title--dark'
              : selectedTheme === 'dark blue'
              ? 'genre-container__title genre-container__title--blue'
              : 'genre-container__title genre-container__title--white'
          }
        >
          {capitalizeFirstLetterOfString(tabTitle)}
        </h2>
      )}

      <Search
        playerRef={playerRef}
        tracksToSearchFrom={tabTracks}
        isGenreSearch={isGenreSearch}
      />

      {tabTracks.length === 0 && !isGenreSearch && (
        <p className="paragraph">{noTracksFoundText}</p>
      )}

      <Snackbar
        open={premiumOnlyTrackNotication}
        autoHideDuration={6000}
        onClose={closePremiumOnlyTrackNotication}
        message="Premium plan is required to listen to this track."
        action={premiumOnlyTrackAction}
      />

      <div className="genre-tracks-container">
        {!isSearchActive &&
          tabTracks.length > 0 &&
          tabTracks.map((track, i) => {
            return (
              <Track
                openPremiumOnlyTrackNotication={openPremiumOnlyTrackNotication}
                playerRef={playerRef}
                premium={track.premium === 1}
                key={i}
                name={track.name}
                path={track.path}
                genre={track.genre}
                tags={track.tags}
                instruments={track.instruments}
                id={track.id}
                addedOnDate={track.addedOnDate}
                category={track.category}
                additionalCategory={track.additionalCategory}
                additionalSecondCategory={track.additionalSecondCategory}
                imageUrl={track.imageUrl}
                displayImageUrl={track.displayImageUrl}
                description={track.description}
                pathLinkId={track.pathLinkId}
                lowNeuralPath={track.lowNeuralPath}
                mediumNeuralPath={track.mediumNeuralPath}
                highNeuralPath={track.highNeuralPath}
                linkPath={track.linkPath}
                lowNeuralLinkPath={track.lowNeuralLinkPath}
                mediumNeuralLinkPath={track.mediumNeuralLinkPath}
                highNeuralLinkPath={track.highNeuralLinkPath}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Genre;
