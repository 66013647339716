const Hero = () => (
  <section className="section-hero">
    <div className="hero-container row">
      {/* <h1 className="hero-container__title primary-heading">
        Scientifically proven music
        <br />
        to help you focus.
        <br />
        Backed by neuroscience.
      </h1> */}

      <h1 className="hero-container__title primary-heading">
        Scientifically proven music
        <br />
        to help you focus, relax and sleep.
        <br />
        Backed by neuroscience.
      </h1>
    </div>
  </section>
);

export default Hero;
