export const sendWelcomeEmail = async (email: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/send-welcome-email`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );
  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const verifyEmail = async (token: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/verify-email`,
    {
      method: 'POST',
      body: JSON.stringify({
        token,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );

  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};
