import { MouseEventHandler } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import Timer from './Timer';
import { StoreState } from '../../store/store';
import ModalBlur from '../../styles/icons/modal-blur.svg';

Modal.setAppElement('#root');

interface TimerModalProps {
  timer: null;
  setTimer: React.Dispatch<React.SetStateAction<null>>;
  isTimerModalOpen: boolean;
  toggleTimerModal: MouseEventHandler<SVGSVGElement>;
  timerTime: number;
  setTimerTime: React.Dispatch<React.SetStateAction<number>>;
}

const TimerModal = (props: TimerModalProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const location = useLocation();
  const isOnSoundscapesPath = location.pathname === '/soundscapes';
  const {
    timer,
    setTimer,
    isTimerModalOpen,
    toggleTimerModal,
    timerTime,
    setTimerTime,
  } = props;

  const customStyles = {
    content: {
      backgroundImage: `url(${ModalBlur})`,
    },
  };

  return (
    <Modal
      style={isOnSoundscapesPath || !displayTheme ? {} : customStyles}
      isOpen={isTimerModalOpen}
      onRequestClose={toggleTimerModal}
      className={
        isOnSoundscapesPath
          ? 'modal modal--soundscapes'
          : displayTheme
          ? 'modal modal--general'
          : selectedTheme === 'spotify dark'
          ? 'modal modal--dark'
          : selectedTheme === 'dark blue'
          ? 'modal modal--blue'
          : 'modal modal--white'
      }
      overlayClassName={
        isOnSoundscapesPath ? 'modal-overlay--soundscapes' : 'modal-overlay'
      }
    >
      <div className="modal-outer-container">
        <Timer
          timer={timer}
          setTimer={setTimer}
          timerTime={timerTime}
          setTimerTime={setTimerTime}
          toggleTimerModal={toggleTimerModal}
        />
      </div>
    </Modal>
  );
};

export default TimerModal;
