import { getFirstHash, refreshTokens } from '../auth/login';

export const fetchCategoryTracks = async (pathNameWithoutForwardSlash: string) => {
  const token = getFirstHash(); 

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/fetchAllTracks?category=${pathNameWithoutForwardSlash}`, {
      method: 'GET',
      headers: {
        'x-api-key': `${process.env.REACT_APP_H}`,
        'Authorization': `Bearer ${token}`,
      },
    }
  );

  const firstHash = response.headers.get('Hash-1');
  const secondHash = response.headers.get('Hash-2');

  if (firstHash && secondHash) {
    refreshTokens({firstHash, secondHash});
  }

  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON.tracks;
  } else {
    throw responseJSON;
  }
};
