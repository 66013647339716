import PricingBox from './Pricing-box';

export const plansData = [
  {
    id: 'basic-free',
    title: 'Basic free',
    price: '0$',
    promoPrice: '0$',
    isPremium: false,
  },
  {
    id: 'premium-month',
    title: 'Premium month',
    price: '5$',
    promoPrice: '5$',
    isPremium: true,
  },
  {
    id: 'premium-year',
    title: 'Premium year',
    price: '50$',
    promoPrice: '50$',
    isPremium: true,
  },
];

const Pricing = () => {
  return (
    <section className="section-pricing" id="section-pricing">
      <div className="row pricing">
        <h2 className="secondary-heading pricing__heading">Pricing</h2>
        <p className="pricing__paragraph paragraph padding-s">
          The basic version is free. If you want to have access to all the
          tracks, genres and soundscapes then please choose a premium plan.
        </p>

        {/* <p className="pricing__paragraph paragraph">
      
        </p> */}

        {plansData.map((planData, index) => {
          return (
            <div className="col-1-of-3" key={index}>
              <div className="pricing-box-home-page">
                <PricingBox
                  id={planData.id}
                  title={planData.title}
                  price={planData.price}
                  promoPrice={planData.promoPrice}
                  isPremium={planData.isPremium}
                  isOnPaymentPage={false}
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Pricing;
