import { MouseEventHandler, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { changeSelectedTab } from '../../reducers/librarySlice';
import GenresTab from './GenresTab';
import Genre from './Genre';
import { focusAtWillGenres } from './Genres';
import { PlayerRefType } from '../Player';
import { StoreState } from '../../store/store';

interface LibraryProps {
  toggleLibraryModal: MouseEventHandler<SVGSVGElement>;
  playerRef: PlayerRefType;
  modalContentRef: React.RefObject<HTMLDivElement>;
}

const Library = (props: LibraryProps) => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const { toggleLibraryModal, playerRef, modalContentRef } = props;
  const dispatch = useDispatch();
  const activeTab = useSelector(
    (state: StoreState) => state.library.selectedTab,
  );
  const [tooltipText, setTooltipText] = useState('');

  const handleTabClick = (tab: string) => {
    dispatch(changeSelectedTab(tab));
  };

  const isFocusAtWillGenre = focusAtWillGenreNames.includes(activeTab);

  useEffect(() => {
    if (isFocusAtWillGenre) {
      focusAtWillGenres.forEach((genre) => {
        if (genre.name === activeTab && genre.description) {
          setTooltipText(genre.description);
        }
      });
    }
  }, [isFocusAtWillGenre]);

  useEffect(() => {
    if (modalContentRef.current) {
      modalContentRef.current?.scrollIntoView({
        block: 'start',
        inline: 'start',
      });
    }
  }, [activeTab]);

  return (
    <div className="library">
      <div className="library-modal-close-icon-container-wrapper">
        <div className="library-modal-close-icon-container">
          <CloseIcon onClick={toggleLibraryModal} style={closeIconStyles} />
        </div>
      </div>
      <div className="library-tabs">
        <button
          className={
            activeTab === 'genres' && displayTheme
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--general`
              : displayTheme
              ? `library-tabs__button library-tabs__button--general`
              : activeTab === 'genres' && selectedTheme === 'spotify dark'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--dark`
              : activeTab === 'genres' && selectedTheme === 'dark blue'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--blue`
              : activeTab === 'genres' && selectedTheme === 'youtube white'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--white`
              : selectedTheme === 'spotify dark'
              ? `library-tabs__button library-tabs__button--dark`
              : selectedTheme === 'dark blue'
              ? `library-tabs__button library-tabs__button--blue`
              : `library-tabs__button library-tabs__button--white`
          }
          onClick={() => handleTabClick('genres')}
        >
          Genres
        </button>

        {activeTab !== 'genres' &&
          activeTab !== 'favorites' &&
          activeTab !== 'played today' &&
          activeTab !== 'not interested' && (
            <button
              className={
                displayTheme
                  ? `library-tabs__button library-tabs__button--active library-tabs__button--active--general`
                  : selectedTheme === 'spotify dark'
                  ? `library-tabs__button library-tabs__button--active library-tabs__button--active--dark`
                  : selectedTheme === 'dark blue'
                  ? `library-tabs__button library-tabs__button--active library-tabs__button--active--blue`
                  : `library-tabs__button library-tabs__button--active library-tabs__button--active--white`
              }
            >
              {capitalizeFirstLetterOfString(activeTab)}
            </button>
          )}

        <button
          className={
            activeTab === 'favorites' && displayTheme
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--general`
              : displayTheme
              ? `library-tabs__button library-tabs__button--general`
              : activeTab === 'favorites' && selectedTheme === 'spotify dark'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--dark`
              : activeTab === 'favorites' && selectedTheme === 'dark blue'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--blue`
              : activeTab === 'favorites' && selectedTheme === 'youtube white'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--white`
              : selectedTheme === 'spotify dark'
              ? `library-tabs__button library-tabs__button--dark`
              : selectedTheme === 'dark blue'
              ? `library-tabs__button library-tabs__button--blue`
              : `library-tabs__button library-tabs__button--white`
          }
          onClick={() => handleTabClick('favorites')}
        >
          Favorites
        </button>
        <button
          className={
            activeTab === 'played today' && displayTheme
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--general`
              : displayTheme
              ? `library-tabs__button library-tabs__button--general`
              : activeTab === 'played today' && selectedTheme === 'spotify dark'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--dark`
              : activeTab === 'played today' && selectedTheme === 'dark blue'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--blue`
              : activeTab === 'played today' &&
                selectedTheme === 'youtube white'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--white`
              : selectedTheme === 'spotify dark'
              ? `library-tabs__button library-tabs__button--dark`
              : selectedTheme === 'dark blue'
              ? `library-tabs__button library-tabs__button--blue`
              : `library-tabs__button library-tabs__button--white`
          }
          onClick={() => handleTabClick('played today')}
        >
          Played Today
        </button>
        <button
          className={
            activeTab === 'not interested' && displayTheme
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--general`
              : displayTheme
              ? `library-tabs__button library-tabs__button--general`
              : activeTab === 'not interested' &&
                selectedTheme === 'spotify dark'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--dark`
              : activeTab === 'not interested' && selectedTheme === 'dark blue'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--blue`
              : activeTab === 'not interested' &&
                selectedTheme === 'youtube white'
              ? `library-tabs__button library-tabs__button--active library-tabs__button--active--white`
              : selectedTheme === 'spotify dark'
              ? `library-tabs__button library-tabs__button--dark`
              : selectedTheme === 'dark blue'
              ? `library-tabs__button library-tabs__button--blue`
              : `library-tabs__button library-tabs__button--white`
          }
          onClick={() => handleTabClick('not interested')}
        >
          Not Interested
        </button>
      </div>

      {activeTab === 'genres' ? (
        <GenresTab playerRef={playerRef} />
      ) : activeTab === 'favorites' ? (
        <Genre
          // modalContentRef={modalContentRef}
          playerRef={playerRef}
          tabTitle={activeTab}
          isGenreSearch={false}
          tooltipText={
            'Your favorites are listed here. Click the heart icon next to the track when it is playing to add or remove it from the favorites.'
          }
          noTracksFoundText={`You haven't added any tracks to favorites.`}
        />
      ) : activeTab === 'played today' ? (
        <Genre
          // modalContentRef={modalContentRef}
          playerRef={playerRef}
          tabTitle={activeTab}
          isGenreSearch={false}
          tooltipText={
            'The tracks that have been played today in the current session appear here.'
          }
          noTracksFoundText={`No tracks found.`}
        />
      ) : activeTab === 'not interested' ? (
        <Genre
          // modalContentRef={modalContentRef}
          playerRef={playerRef}
          tabTitle={activeTab}
          isGenreSearch={false}
          tooltipText={
            'The tracks you have marked as not interested are listed here. Click the not interested icon next to the track when it is playing to add or remove it from the not interested.'
          }
          noTracksFoundText={`You haven't added any tracks to not interested.`}
        />
      ) : (
        <Genre
          // modalContentRef={modalContentRef}
          playerRef={playerRef}
          tabTitle={activeTab}
          isGenreSearch={true}
          tooltipText={isFocusAtWillGenre ? tooltipText : ''}
        />
      )}
    </div>
  );
};

const focusAtWillGenreNames = [
  'jambient jungle',
  'the deep',
  'chinchilla',
  'the drop',
  'deep space',
  'symphonica',
  'tranquility',
  'organica',
  'powertool',
  'the duke',
  'bach beats',
  'edwardian',
  'atmosphere',
  'aquascope',
  'cinematic (focus@will)',
  'nature beat',
  'evolve',
  'zen piano',
];

export const closeIconStyles = {
  fontSize: '2.5rem',
  cursor: 'pointer',
};

export const closeIconStylesSoundscapes = {
  fontSize: '2.5rem',
  cursor: 'pointer',
  color: '#ffffff',
};

export const capitalizeFirstLetterOfString = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default Library;
