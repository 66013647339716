import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  favorites: string[],
  uninterested: string[],
  genresToPlay: string[],
  activitySettings: string[],
  neuralEffectSettings: string[],
  sleepGenresToPlay: string[],
  subscribed: boolean;
  playedToday: string[],
  displayTimePlayed: boolean;
  displayPlayerProgress: boolean;
  displayTheme: boolean;
  displayTrackInfo: boolean;
  displayRepeatButton: boolean;
  displayVolumeControls: boolean;
  displayCategorySelection: boolean;
  displayCategorySelectionLabel: boolean;
  displayBrowseButton: boolean;
  hideAllPlayerControls: boolean;
  volumeLevel: number;
  genresGotUpdated: number;
  playFromFavorites: boolean;
  playFromSleepFavorites: boolean;
  playAlarmSound: boolean;
  defaultAlarmTimer: number;
}

const initialState: UserState = {
  favorites: [],
  uninterested: [],
  genresToPlay: [],
  activitySettings: [],
  neuralEffectSettings: [],
  sleepGenresToPlay: [],
  subscribed: false,
  playedToday: [],
  displayTimePlayed: true,
  displayPlayerProgress: false,
  displayTheme: true,
  displayTrackInfo: true,
  displayRepeatButton: true,
  displayVolumeControls: true,
  displayCategorySelection: true,
  displayCategorySelectionLabel: true,
  displayBrowseButton: true,
  hideAllPlayerControls: false,
  volumeLevel: 1,
  genresGotUpdated: 0.1,
  playFromFavorites: false,
  playFromSleepFavorites: false,
  playAlarmSound: true,
  defaultAlarmTimer: 1500,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    addFavorite: (state, action) => {
      state.favorites = [...state.favorites, action.payload];
    },
    removeFavorite: (state, action) => {
      state.favorites = state.favorites.filter(
        (favorite) => favorite !== action.payload,
      );
    },
    addUninterested: (state, action) => {
      state.uninterested = [...state.uninterested, action.payload];
    },
    removeUninterested: (state, action) => {
      state.uninterested = state.uninterested.filter(
        (uniterested) => uniterested !== action.payload,
      );
    },
    initializeUserData: (state, action) => {
      return {
        ...state,
        favorites: [...action.payload.favorites],
        uninterested: [...action.payload.uninterested],
        genresToPlay: [...action.payload.genresToPlay],
        activitySettings:[...action.payload.activitySettings], 
        neuralEffectSettings:[...action.payload.neuralEffectSettings],
        sleepGenresToPlay: [...action.payload.sleepGenresToPlay],
        subscribed: action.payload.subscribed,
        displayTimePlayed: action.payload.displayTimePlayed,
        displayPlayerProgress: action.payload.displayPlayerProgress,
        displayTheme: action.payload.displayTheme,
        displayTrackInfo: action.payload.displayTrackInfo,
        displayRepeatButton: action.payload.displayRepeatButton,
        displayVolumeControls: action.payload.displayVolumeControls,
        displayCategorySelection: action.payload.displayCategorySelection,
        displayCategorySelectionLabel: action.payload.displayCategorySelectionLabel,
        displayBrowseButton: action.payload.displayBrowseButton,
        hideAllPlayerControls: action.payload.hideAllPlayerControls,
        volumeLevel: action.payload.volumeLevel,
        genresGotUpdated: 0.1,
        playFromFavorites: action.payload.playFromFavorites,
        playFromSleepFavorites: action.payload.playFromSleepFavorites,
        playAlarmSound: action.payload.playAlarmSound,
        defaultAlarmTimer: action.payload.defaultAlarmTimer,
      };
    },
    addToPlayedToday: (state, action) => {
      if (!state.playedToday.includes(action.payload)) {
        state.playedToday = [...state.playedToday, action.payload];
      } else {
        return state;
      }
    },
    confirmUserSettingChanges: (state, action) => {
      return {
        ...state,
        genresToPlay: [...action.payload.genresToPlay],
        activitySettings: [...action.payload.activitySettings],
        neuralEffectSettings: [...action.payload.neuralEffectSettings],
        sleepGenresToPlay: [...action.payload.sleepGenresToPlay],
        displayTimePlayed: action.payload.displayTimePlayed,
        displayPlayerProgress: action.payload.displayPlayerProgress,
        displayTheme: action.payload.displayTheme,
        displayTrackInfo: action.payload.displayTrackInfo,
        displayRepeatButton: action.payload.displayRepeatButton,
        displayVolumeControls: action.payload.displayVolumeControls,
        displayCategorySelection: action.payload.displayCategorySelection,
        displayCategorySelectionLabel: action.payload.displayCategorySelectionLabel,
        displayBrowseButton: action.payload.displayBrowseButton,
        hideAllPlayerControls: action.payload.hideAllPlayerControls,
        genresGotUpdated: action.payload.genresGotUpdated,
        playFromFavorites: action.payload.playFromFavorites,
        playFromSleepFavorites: action.payload.playFromSleepFavorites
      };
    },
    updateUserVolumeLevel: (state, action) => {
      state.volumeLevel = action.payload;
    },
    updateUserPlayAlarmSound: (state, action) => {
      state.playAlarmSound = action.payload;
    },
    updateUserDefaultAlarmTimer: (state, action) => {
      state.defaultAlarmTimer = action.payload;
    },
  },
});

export const {
  addFavorite,
  removeFavorite,
  addUninterested,
  removeUninterested,
  initializeUserData,
  addToPlayedToday,
  confirmUserSettingChanges,
  updateUserVolumeLevel,
  updateUserPlayAlarmSound,
  updateUserDefaultAlarmTimer
} = userSlice.actions;

export default userSlice.reducer;
