export const requestPassWordResetEmail = async (email: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/request-password-reset`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );
  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};

export const handleCheckIfPasswordResetLinkIsValid = async (resetToken: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/validate-reset-password-link`,
    {
      method: 'POST',
      body: JSON.stringify({
        resetToken,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );

  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const setNewPasswordWithToken = async (resetToken: string, newPassword: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/reset-password`,
    {
      method: 'POST',
      body: JSON.stringify({
        resetToken,
        newPassword,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );
  const responseJSON = await response.json();

  if (response.status === 200) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};
