import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import { verifyEmail } from '../api/auth/verify-email';

const VerifyEmailPage = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [emailGotVerified, setEmailGotVerified] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const response = await verifyEmail(token !== null ? token : '');
        setEmailGotVerified(response);
        setIsDataLoaded(true);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  if (isDataLoaded && emailGotVerified) {
    return (
      <div className="verify-email-container">
        <h1 className="secondary-heading">Thank you</h1>
        <p className="verify-email-container__paragraph">
          Your email has been verified successfully.
        </p>
        <Link
          className={
            selectedTheme === 'spotify dark'
              ? 'link--dark'
              : selectedTheme === 'spotify blue'
              ? 'link--blue'
              : 'link--white'
          }
          to="/"
        >
          <p className="verify-email-container__paragraph">Home</p>
        </Link>
      </div>
    );
  } else if (isDataLoaded && !emailGotVerified) {
    return (
      <div className="verify-email-container">
        <h1 className="secondary-heading">Link has expired</h1>
        <p className="verify-email-container__paragraph">
          If you think it is an error, please contact us at{' '}
          {process.env.REACT_APP_CONTACT_EMAIL}
        </p>
        <Link
          className={
            selectedTheme === 'spotify dark'
              ? 'link--dark'
              : selectedTheme === 'spotify blue'
              ? 'link--blue'
              : 'link--white'
          }
          to="/"
        >
          <p className="verify-email-container__paragraph">Home</p>
        </Link>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default VerifyEmailPage;
