import { createTheme } from '@mui/material/styles';

export const muiTheme = createTheme({
  palette: {
    primary: {
      // main: '#f89616',
      main: '#1ed760',
    },
    secondary: {
      light: '#f8961',
      // light: '#1ed760',
      // main: '#f39c12',
      // contrastText: '#f39c12',

      main: '#1ed760',
      contrastText: '#1ed760',
    },

    background: {
      default: '#242424',
    },
    text: {
      primary: '#e7e7e7',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 50px #242424 inset',
              borderRadius: '1.2rem',
              WebkitTextFillColor: '#e7e7e7',
            },
          },
        },
      },
    },
  },
});

export const muiRootStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#474646', // input default border
    borderRadius: '1.2rem',
    padding: '1rem',
  },
  '& .MuiInputLabel-outlined': {
    color: '#e7e7e7',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '11px',
  },
};
