import { Navigate, useLocation, useNavigate, Link } from 'react-router-dom';
import Player, { PlayerRefType } from './Player';
import Dropdown from 'react-dropdown';
import RefProps from 'react-h5-audio-player';
import { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { fetchUserData } from '../api/user/user';
import Loading from './Loading';
import { useDispatch, useSelector } from 'react-redux';
import { initializeTracks } from '../reducers/librarySlice';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideoSettingsOutlinedIcon from '@mui/icons-material/VideoSettingsOutlined';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import { initializeUserData } from '../reducers/userSlice';
import { fetchCategoryTracks } from '../api/tracks/tracks';
import LibraryModal from './library/LibraryModal';
import TimerModal from './timer/TimerModal';
import SettingsModal from './settings/SettingsModal';
import { isUserLoggedIn, signUserOut } from '../api/auth/login';
import { capitalizeFirstLetters } from './library/Track';
import {
  Genre,
  brainfmGenres,
  focusAtWillGenres,
  endelGenres,
  headspaceGenres,
  calmGenres,
  calmSleepGenres,
  sleepGenres,
  headspaceSleepGenres,
  otherGenres,
} from './library/Genres';
import {
  setisSearchActive,
  Track,
  changeSelectedTab,
} from '../reducers/librarySlice';
import { StoreState } from '../store/store';

interface StyledSectionProps {
  $imageUrl: string;
  $displayTheme: boolean;
}

const StyledSection = styled.section<StyledSectionProps>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
  z-index: 1;

  ${({ $displayTheme }) =>
    $displayTheme &&
    css`
      background-color: rgb(0, 0, 0);
    `}

  ${({ $displayTheme, $imageUrl }) =>
    $displayTheme &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        transition: all 0.4s ease-in-out 0s;
        background-image: linear-gradient(rgba(0, 0, 0, 0), 90%, rgb(0, 0, 0)),
          url(${$imageUrl});
        background-size: cover;
        background-position: center center;
        opacity: 0.5;
        z-index: 2;
      }
    `};
`;

interface StyledDivProps {
  $displayTheme: boolean;
}

export const StyledDiv = styled.div<StyledDivProps>`
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;

  /* Conditional styles based on displayTheme */
  ${({ $displayTheme }) =>
    $displayTheme
      ? css`
          background-color: rgba(0, 0, 0, 0.5);
          &:after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            transition: all 0.4s ease-in-out 0s;
            background-color: rgba(238, 136, 148, 0.1);
            z-index: 4;
          }
        `
      : css`
          position: relative;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 3;
        `}
`;

interface CategoryViewProps {
  title: string;
}

const CategoryView = (props: CategoryViewProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const isSubscribed = useSelector(
    (state: StoreState) => state.user.subscribed,
  );
  const areImagesLoaded = usePreloadIcons([
    brainfmGenres,
    focusAtWillGenres,
    endelGenres,
    headspaceGenres,
    calmGenres,
    calmSleepGenres,
    sleepGenres,
    headspaceSleepGenres,
    otherGenres,
  ]);
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const playingTrackGenre = useSelector(
    (state: StoreState) => state.library.playingTrack.genre,
  );

  const trackTheme = useSelector(
    (state: StoreState) => state.library.playingTrack.imageUrl,
  );

  useEffect(() => {
    if (
      localStorage.getItem('lawfmtheme') === 'youtube white' &&
      !displayTheme
    ) {
      const buttonsColorForWhiteTheme = '#2f2f2f';
      document.documentElement.style.setProperty(
        '--buttons-color',
        buttonsColorForWhiteTheme,
      );
    } else {
      const buttonsColor = '#e7e7e7';
      document.documentElement.style.setProperty(
        '--buttons-color',
        buttonsColor,
      );
    }
  }, [displayTheme]);

  const imageUrl = trackTheme
    ? trackTheme.replace(/\(/g, '\\(').replace(/\)/g, '\\)')
    : encodeSpecialChars(
        encodeUrlPath(`/theme-images/${playingTrackGenre}.png`),
      );

  const { title } = props;
  const [selectedCategory, setSelectedCategory] = useState(title.toLowerCase());
  const navigate = useNavigate();
  const displayCategorySelection = useSelector(
    (state: StoreState) => state.user.displayCategorySelection,
  );
  const savedDefaultAlarmTimer = useSelector(
    (state: StoreState) => state.user.defaultAlarmTimer,
  );
  const location = useLocation();
  const sleepCategoryGenres = location.pathname === '/sleep';
  const dispatch = useDispatch();
  const isLoggedIn = isUserLoggedIn();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isTimerModalOpen, setTimerModalOpen] = useState(false);
  const [timerTime, setTimerTime] = useState(savedDefaultAlarmTimer);
  const [timer, setTimer] = useState(null);
  const pathNameWithoutForwardSlash = location.pathname.substring(1);
  const [playerRef, setPlayerRef] = useState<
    React.RefObject<HTMLAudioElement> | RefProps | null
  >({ current: null });
  const [pauseByTimer, setPauseByTimer] = useState(false);

  const timerAudioRef = useRef<HTMLAudioElement | null>(null);
  const volumeLevel = useSelector(
    (state: StoreState) => state.user.volumeLevel,
  );

  const optionsClassName =
    selectedTheme === 'youtube white'
      ? 'category-selection-option category-selection-option--white'
      : 'category-selection-option';

  const options = [
    {
      value: 'focus',
      label: 'Focus',
      className: optionsClassName,
    },
    {
      value: 'soundscapes',
      label: 'Soundscapes',
      className: optionsClassName,
    },
    {
      value: 'sleep-categories',
      label: 'Sleep',
      className: optionsClassName,
    },
    {
      value: '',
      label: 'Home',
      className: optionsClassName,
    },
  ];

  const toggleLibraryModal = () => {
    setIsLibraryModalOpen(!isLibraryModalOpen);
    dispatch(setisSearchActive(false));
  };

  const toggleSettingsModal = () => {
    setSettingsModalOpen(!isSettingsModalOpen);
  };

  const toggleTimerModal = () => {
    setTimerModalOpen(!isTimerModalOpen);
  };

  const setPauseByTimerToFalse = () => {
    setPauseByTimer(false);
  };

  const playAlarmSoundAfterTimerEnds = useSelector(
    (state: StoreState) => state.user.playAlarmSound,
  );

  const handleTimerEnd = () => {
    setPauseByTimer(true);

    if (playAlarmSoundAfterTimerEnds && !sleepCategoryGenres) {
      const audio = new Audio('/alarm/alarm-bell.mp3');
      audio.volume = volumeLevel;
      audio.play();
      timerAudioRef.current = audio;
    }
  };

  useEffect(() => {
    if (timerTime === 0 && timer) {
      handleTimerEnd();
    }
  }, [timerTime]);

  useEffect(() => {
    (async () => {
      try {
        if (!isLoggedIn) {
          return;
        }
        const userData = await fetchUserData();
        const {
          favorites,
          uninterested,
          subscribed,
          genresToPlay,
          activitySettings,
          neuralEffectSettings,
          sleepGenresToPlay,
          displayTimePlayed,
          displayPlayerProgress,
          displayTheme,
          displayTrackInfo,
          volumeLevel,
          displayRepeatButton,
          displayVolumeControls,
          displayCategorySelection,
          displayCategorySelectionLabel,
          displayBrowseButton,
          hideAllPlayerControls,
          playFromFavorites,
          playFromSleepFavorites,
          playAlarmSound,
          defaultAlarmTimer,
        } = userData;

        dispatch(
          initializeUserData({
            favorites,
            uninterested,
            subscribed,
            genresToPlay,
            activitySettings,
            neuralEffectSettings,
            sleepGenresToPlay,
            displayTimePlayed,
            displayPlayerProgress,
            displayTheme,
            displayTrackInfo,
            volumeLevel,
            displayRepeatButton,
            displayVolumeControls,
            displayCategorySelection,
            displayCategorySelectionLabel,
            displayBrowseButton,
            hideAllPlayerControls,
            playFromFavorites,
            playFromSleepFavorites,
            playAlarmSound,
            defaultAlarmTimer,
          }),
        );

        const tracksData = await fetchCategoryTracks(
          pathNameWithoutForwardSlash,
        );

        const tracksInRandomizedOrder = randomizeTracksOrder(tracksData);
        dispatch(initializeTracks(tracksInRandomizedOrder));
        dispatch(changeSelectedTab('genres'));

        await new Promise((resolve) => setTimeout(resolve, 500));

        setIsDataLoaded(true);
      } catch (error) {
        if (error.message === 'Unauthorized.') {
          signUserOut();
        }
      }
    })();
  }, []);

  const handlePlayerContextValue = (playerContext: PlayerRefType) => {
    setPlayerRef(playerContext);
  };

  const handleCategoryChange = (event: any) => {
    const selectedPath = event.value;
    setSelectedCategory(selectedPath);
    navigate(`/${selectedPath}`);

    if (selectedPath !== 'soundscapes') {
      window.location.reload();
    }
  };

  useEffect(() => {
    setTimerTime(savedDefaultAlarmTimer);
  }, [savedDefaultAlarmTimer]);

  const filteredOptions = options.filter(
    (option) => option.value !== selectedCategory,
  );

  if (!isLoggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else if (!isDataLoaded) {
    return <Loading />;
  } else {
    return (
      <StyledSection $imageUrl={imageUrl} $displayTheme={displayTheme}>
        <StyledDiv $displayTheme={displayTheme}>
          <div className="category-content">
            <div className="category-view-menu-box">
              {displayCategorySelection ? (
                <Dropdown
                  controlClassName={
                    selectedTheme === 'youtube white'
                      ? 'category-selection category-selection--white'
                      : 'category-selection'
                  }
                  menuClassName={
                    selectedTheme === 'youtube white'
                      ? 'category-selection-menu category-selection-menu--white'
                      : 'category-selection-menu'
                  }
                  options={filteredOptions}
                  placeholder={capitalizeFirstLetters(selectedCategory)}
                  onChange={handleCategoryChange}
                />
              ) : (
                <Dropdown
                  controlClassName={
                    selectedTheme === 'youtube white'
                      ? 'category-selection category-selection--white category-selection--hidden'
                      : 'category-selection category-selection--hidden'
                  }
                  menuClassName={
                    selectedTheme === 'youtube white'
                      ? 'category-selection-menu category-selection-menu--white category-selection-menu--hidden'
                      : 'category-selection-menu category-selection-menu--hidden'
                  }
                  options={filteredOptions}
                  placeholder={capitalizeFirstLetters(selectedCategory)}
                  onChange={handleCategoryChange}
                />
              )}

              {!isSubscribed && (
                <div
                  className={
                    !displayTheme && selectedTheme === 'youtube white'
                      ? 'upgrade-notification upgrade-notification--white'
                      : !displayTheme && selectedTheme === 'dark blue'
                      ? 'upgrade-notification upgrade-notification--blue'
                      : 'upgrade-notification'
                  }
                >
                  <span className="upgrade-notification__text">
                    Please consider upgrading to a premium to help cover the
                    server fees and unlock all the content.
                  </span>

                  <Link
                    className="upgrade-notification__button upgrade-notification__link"
                    to="/premium?plan=all-plans"
                  >
                    Upgrade
                  </Link>
                </div>
              )}

              <div className="menu-main-items">
                <LibraryMusicOutlinedIcon
                  onClick={toggleLibraryModal}
                  style={
                    displayTheme
                      ? iconStylesGeneralWithMargin
                      : settingsAndLibraryIconStyles
                  }
                />

                <VideoSettingsOutlinedIcon
                  onClick={toggleSettingsModal}
                  style={
                    displayTheme
                      ? iconStylesGeneralWithMargin
                      : settingsAndLibraryIconStyles
                  }
                />

                <ScheduleIcon
                  onClick={toggleTimerModal}
                  style={displayTheme ? iconStylesGeneral : timerIconStyles}
                />
              </div>
            </div>

            <LibraryModal
              playerRef={playerRef}
              isLibraryModalOpen={isLibraryModalOpen}
              toggleLibraryModal={toggleLibraryModal}
            />

            <TimerModal
              timer={timer}
              setTimer={setTimer}
              isTimerModalOpen={isTimerModalOpen}
              toggleTimerModal={toggleTimerModal}
              timerTime={timerTime}
              setTimerTime={setTimerTime}
            />

            <SettingsModal
              isSettingsModalOpen={isSettingsModalOpen}
              toggleSettingsModal={toggleSettingsModal}
            />

            <Player
              sendPlayerContext={handlePlayerContextValue}
              isLibraryModalOpen={isLibraryModalOpen}
              isSettingsModalOpen={isSettingsModalOpen}
              pauseByTimer={pauseByTimer}
              setPauseByTimerToFalse={setPauseByTimerToFalse}
            />

            {timerAudioRef.current && (
              <audio
                ref={timerAudioRef}
                onEnded={() => {
                  timerAudioRef.current = null;
                }}
              />
            )}
          </div>
        </StyledDiv>
      </StyledSection>
    );
  }
};

const settingsAndLibraryIconStyles = {
  fontSize: '3.6rem',
  cursor: 'pointer',
  marginRight: '3.5rem',
};

const timerIconStyles = {
  fontSize: '3.6rem',
  cursor: 'pointer',
};

const iconStylesGeneral = {
  fontSize: '3.6rem',
  cursor: 'pointer',
  color: '#e7e7e7',
};

const iconStylesGeneralWithMargin = {
  fontSize: '3.6rem',
  cursor: 'pointer',
  color: '#e7e7e7',
  marginRight: '3.5rem',
};

const randomizeTracksOrder = (tracks: Track[]) => {
  const shuffledArray = [...tracks];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

const encodeSpecialChars = (str: string) => {
  return str.replace(
    /([!()*])/g,
    (match) => `%${match.charCodeAt(0).toString(16)}`,
  );
};

// Function to encode the entire URL path while preserving slashes
const encodeUrlPath = (path: string) => {
  return path
    .split('/')
    .map((part) => encodeURIComponent(part))
    .join('/');
};

const preloadImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(url);
    img.onerror = () => reject(url);
  });
};

// Function to preload all images in an array of URLs
const preloadImages = (urls: string[]) => {
  return Promise.all(urls.map(preloadImage));
};

const usePreloadIcons = (iconArrays: Genre[][]) => {
  const [areImagesLoaded, setAreImagesLoaded] = useState(false);

  useEffect(() => {
    // Extract all icon URLs from each array
    const allUrls = iconArrays.flatMap((array) =>
      array.flatMap((genre) => [genre.icon, genre.whiteThemeIcon]),
    );
    // Preload all images and update state
    preloadImages(allUrls)
      .then(() => {
        setAreImagesLoaded(true);
      })
      .catch((err) => {
        console.error(`Failed to preload image: ${err}`);
      });
  }, []);

  return areImagesLoaded;
};

export default CategoryView;
