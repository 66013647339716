import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  FC,
} from 'react';
import ModalBlur from '../styles/icons/modal-blur.svg';

type ThemeContextType = {
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('lawfmtheme') || 'spotify dark';
  });
  const isOnSoundscapesPath = window.location.pathname === '/soundscapes';

  useEffect(() => {
    localStorage.setItem('lawfmtheme', theme);
    updateBodyBackground(theme);
  }, [theme]);

  const updateBodyBackground = (theme: string) => {
    document.body.style.backgroundColor =
      isOnSoundscapesPath || theme === 'spotify dark'
        ? '#121212'
        : theme === 'dark blue'
        ? '#191736'
        : '#ffffff';

    const orangeBackground = '#f39c12';
    const greenBackground = '#1ed760';
    const buttonsColor = '#e7e7e7';
    const buttonsColorForWhiteTheme = '#2f2f2f';
    const snackbarColorDarkTheme = '#000000';
    const snackbarColorWhiteTheme = '#e7e7e7';

    document.documentElement.style.setProperty(
      '--modal-blur-svg',
      `url(${ModalBlur})`,
    );

    if (isOnSoundscapesPath || theme === 'spotify dark') {
      document.documentElement.style.setProperty(
        '--highlight-background',
        greenBackground,
      );
      document.documentElement.style.setProperty(
        '--buttons-color',
        buttonsColor,
      );
      document.documentElement.style.setProperty(
        '--snackbar-color',
        snackbarColorDarkTheme,
      );
    } else if (theme === 'dark blue') {
      document.documentElement.style.setProperty(
        '--highlight-background',
        orangeBackground,
      );
      document.documentElement.style.setProperty(
        '--buttons-color',
        buttonsColor,
      );
      document.documentElement.style.setProperty(
        '--snackbar-color',
        snackbarColorDarkTheme,
      );
    } else {
      document.documentElement.style.setProperty(
        '--highlight-background',
        '#121212',
      );
      document.documentElement.style.setProperty(
        '--buttons-color',
        buttonsColorForWhiteTheme,
      );
      document.documentElement.style.setProperty(
        '--snackbar-color',
        snackbarColorWhiteTheme,
      );
      document.body.style.color = '#2f2f2f';
    }
  };

  // Call updateBodyBackground on initial render
  useEffect(() => {
    updateBodyBackground(theme);
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
