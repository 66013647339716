import { createSlice } from '@reduxjs/toolkit';

export interface LibraryState {
  isPlaying: boolean;
  selectedTab: string;
  playingTrack: PlayingTrack,
  tracks: Track[],
  isSearchActive: boolean;
  isLastTrackOfTheShuffle: boolean;
  tracksQueData: TracksQueData
}

export interface TracksQueData {
  currentPlayingTrackIndex: number;
  trackStartPoint: number;
  playedTrackIndexes: number[];
  trackQueIndex: number;
  isFirstTrack: boolean;
  isLastTrack: boolean;
}

export interface PlayingTrack {
  path: string;
  name: string;
  id: string;
  genre: string;
  imageUrl?: string;
  displayImageUrl?: string;
  description?: string;
  pathLinkId?: string;
  lowNeuralPath?: string;
  mediumNeuralPath?: string;
  highNeuralPath?: string;
  linkPath?: string;
  lowNeuralLinkPath?: string;
  mediumNeuralLinkPath?: string;
  highNeuralLinkPath?: string;
}

export interface Track {
  id: string;
  category: string;
  additionalCategory: string;
  additionalSecondCategory: string;
  genre: string;
  name: string;
  path: string;
  premium: number | boolean; // to use only one type
  tags: string[];
  instruments: string[];
  theme?: string; // to change to required later when multiple themes
  picture?: string; // to remove later
  addedOnDate: string;
  imageUrl?: string;
  displayImageUrl?: string;
  description?: string;
  pathLinkId?: string;
  lowNeuralPath?: string;
  mediumNeuralPath?: string;
  highNeuralPath?: string;
  linkPath?: string;
  lowNeuralLinkPath?: string;
  mediumNeuralLinkPath?: string;
  highNeuralLinkPath?: string;
}

const initialState = {
  isPlaying: false,
  selectedTab: 'genres',
  playingTrack: {},
  tracks: [],
  isSearchActive: false,
  tracksQueData: {
    currentPlayingTrackIndex: 0,
    trackStartPoint: 0,
    playedTrackIndexes: [0],
    trackQueIndex: 0,
    isFirstTrack: true,
    isLastTrack: false,
  },
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },  
    changeSelectedTab: (state, action) => {
      return {
        ...state,
        selectedTab: action.payload,
        isSearchActive: false,
      };
    },
    changePlayingTrack: (state, action) => {
      return {
        ...state,
        playingTrack: action.payload.playingTrack,
        tracksQueData: action.payload.tracksQueData,
      };
    },

    initializeTracks: (state, action) => {
      state.tracks = action.payload;
    },
    setisSearchActive: (state, action) => {
      state.isSearchActive = action.payload;
    },

  },
});

export const {
  changeSelectedTab,
  changePlayingTrack,
  initializeTracks,
  setisSearchActive,
  setIsPlaying
} = librarySlice.actions;

export default librarySlice.reducer;
