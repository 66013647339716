import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useState, MouseEventHandler } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import GoldCrown from '../../styles/icons/gold-crown-icon.svg';
import {
  rectangleButtonStyles,
  rectangleButtonStylesWhiteTheme,
} from '../../styles/mui/rectangle-button';
import { ThemeProvider } from '@mui/material/styles';
import { confirmUserSettingChanges } from '../../reducers/userSlice';
import { updateUserSettings } from '../../api/user/user';
import InfoTooltip from '../../styles/mui/InfoTooltip';
import { muiTheme } from '../../styles/mui/mui-styles';
import { muiBlueTheme } from '../../styles/mui/mui-styles-blue';
import { muiWhiteTheme } from '../../styles/mui/mui-styles-white';
import { closeIconStyles } from '../library/Library';
import { StoreState } from '../../store/store';

interface SettingsProps {
  toggleSettingsModal: MouseEventHandler<any>;
}

const Settings = (props: SettingsProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const location = useLocation();
  // const deepSleepGenres = location.pathname === '/sleep';
  const deepSleepGenres = location.pathname === '/deep-sleep';
  const powerNapSleepGenres = location.pathname === '/power-nap';
  const guidedSleepGenres = location.pathname === '/guided-sleep';
  const { toggleSettingsModal } = props;
  const isUserOnPhoneOrTablet: boolean =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ) ||
    (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) ||
    false;
  const dispatch = useDispatch();
  const [premiumOnlyNotication, setPremiumOnlyNotication] = useState(false);
  const isSubscribed = useSelector(
    (state: StoreState) => state.user.subscribed,
  );
  const savedCheckedGenreSwitches = useSelector(
    (state: StoreState) => state.user.genresToPlay,
  );
  const savedCheckedActivitySettings = useSelector(
    (state: StoreState) => state.user.activitySettings,
  );
  const savedCheckedNeuralEffectSettings = useSelector(
    (state: StoreState) => state.user.neuralEffectSettings,
  );
  const savedCheckedSleepGenreSwitches = useSelector(
    (state: StoreState) => state.user.sleepGenresToPlay,
  );
  const savedCheckedFavorites = useSelector(
    (state: StoreState) => state.user.playFromFavorites,
  );

  const savedCheckedSleepFavorites = useSelector(
    (state: StoreState) => state.user.playFromSleepFavorites,
  );
  const savedDisplayTimePlayedSetting = useSelector(
    (state: StoreState) => state.user.displayTimePlayed,
  );
  const savedDisplayPlayerProgressSetting = useSelector(
    (state: StoreState) => state.user.displayPlayerProgress,
  );
  const savedDisplayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const savedDisplayTrackInfo = useSelector(
    (state: StoreState) => state.user.displayTrackInfo,
  );
  const genresToPlay = useSelector(
    (state: StoreState) => state.user.genresToPlay,
  );
  const sleepGenresToPlay = useSelector(
    (state: StoreState) => state.user.sleepGenresToPlay,
  );
  const genresGotUpdatedCurrent = useSelector(
    (state: StoreState) => state.user.genresGotUpdated,
  );
  const savedDisplayRepeatButton = useSelector(
    (state: StoreState) => state.user.displayRepeatButton,
  );
  const savedDisplayVolumeControls = useSelector(
    (state: StoreState) => state.user.displayVolumeControls,
  );
  const savedDisplayCategorySelection = useSelector(
    (state: StoreState) => state.user.displayCategorySelection,
  );
  const savedDisplayCategorySelectionLabel = useSelector(
    (state: StoreState) => state.user.displayCategorySelectionLabel,
  );
  const savedDisplayBrowseButton = useSelector(
    (state: StoreState) => state.user.displayBrowseButton,
  );
  const savedHideAllPlayerControls = useSelector(
    (state: StoreState) => state.user.hideAllPlayerControls,
  );
  const [newDisplayVolumeControls, setNewDisplayVolumeControls] = useState(
    savedDisplayVolumeControls,
  );
  const [newDisplayRepeatButton, setNewDisplayRepeatButton] = useState(
    savedDisplayRepeatButton,
  );
  const [newDisplayTimePlayed, setNewDisplayTimePlayed] = useState(
    savedDisplayTimePlayedSetting,
  );
  const [newDisplayPlayerProgress, setNewDisplayPlayerProgress] = useState(
    savedDisplayPlayerProgressSetting,
  );
  const [newDisplayTheme, setNewDisplayTheme] = useState(savedDisplayTheme);
  const [newDisplayTrackInfo, setNewDisplayTrackInfo] = useState(
    savedDisplayTrackInfo,
  );
  const [newHideAllPlayerControls, setNewHideAllPlayerControls] = useState(
    savedHideAllPlayerControls,
  );

  const [newCheckedGenres, setNewCheckedGenres] = useState(
    savedCheckedGenreSwitches,
  );

  const [newCheckedActivitySettings, setNewCheckedActivitySettings] = useState(
    savedCheckedActivitySettings,
  );

  const [newCheckedNeuralEffectSettings, setNewCheckedNeuralEffectSettings] =
    useState(savedCheckedNeuralEffectSettings);

  const [newCheckedSleepGenres, setNewCheckedSleepGenres] = useState(
    savedCheckedSleepGenreSwitches,
  );

  const [newCheckedFavorites, setNewCheckedFavorites] = useState(
    savedCheckedFavorites,
  );
  const [newCheckedSleepFavorites, setNewCheckedSleepFavorites] = useState(
    savedCheckedSleepFavorites,
  );
  const [newDisplayCategorySelection, setNewDisplayCategorySelection] =
    useState(savedDisplayCategorySelection);

  const [
    newDisplayCategorySelectionLabel,
    setNewDisplayCategorySelectionLabel,
  ] = useState(savedDisplayCategorySelectionLabel);

  const [newDisplayBrowseButton, setNewDisplayBrowseButton] = useState(
    savedDisplayBrowseButton,
  );

  const handleGenreSwitchChange = (genre: string) => {
    if (newCheckedGenres.includes(genre)) {
      const genresToPlay = newCheckedGenres.filter(
        (genreItem) => genreItem !== genre,
      );
      setNewCheckedGenres(genresToPlay);
    } else {
      const genresToPlay = [...newCheckedGenres, genre];
      setNewCheckedGenres(genresToPlay);
    }
  };

  const handleActivitySettingsSwitchChange = (activity: string) => {
    if (newCheckedActivitySettings.includes(activity)) {
      const activitySettings = newCheckedActivitySettings.filter(
        (activityItem) => activityItem !== activity,
      );
      setNewCheckedActivitySettings(activitySettings);
    } else {
      const activitySettings = [...newCheckedActivitySettings, activity];
      setNewCheckedActivitySettings(activitySettings);
    }
  };

  const handleNeuralEffectSettingsSwitchChange = (effect: string) => {
    if (newCheckedNeuralEffectSettings.includes(effect)) {
      const neuralEffectSettings = newCheckedNeuralEffectSettings.filter(
        (neuralEffectItem: string) => neuralEffectItem !== effect,
      );
      setNewCheckedNeuralEffectSettings(neuralEffectSettings);
    } else {
      const neuralEffectSettings = [...newCheckedNeuralEffectSettings, effect];
      setNewCheckedNeuralEffectSettings(neuralEffectSettings);
    }
  };

  const handlePlayFromFavoritesSwitchChange = () => {
    setNewCheckedFavorites(!newCheckedFavorites);
  };

  const handlePlayFromSleepFavoritesSwitchChange = () => {
    setNewCheckedSleepFavorites(!newCheckedSleepFavorites);
  };

  const handleSleepGenreSwitchChange = (genre: string) => {
    if (newCheckedSleepGenres.includes(genre)) {
      const sleepGenresToPlay = newCheckedSleepGenres.filter(
        (genreItem) => genreItem !== genre,
      );
      setNewCheckedSleepGenres(sleepGenresToPlay);
    } else {
      const sleepGenresToPlay = [...newCheckedSleepGenres, genre];
      setNewCheckedSleepGenres(sleepGenresToPlay);
    }
  };

  const toggleDisplayCategorySelection = () => {
    setNewDisplayCategorySelection(!newDisplayCategorySelection);
    setNewDisplayCategorySelectionLabel(false);
  };

  // const toggleDisplayCategorySelectionLabel = () => {
  //   setNewDisplayCategorySelectionLabel(!newDisplayCategorySelectionLabel);
  // };

  // const toggleDisplayBrowseButton = () => {
  //   setNewDisplayBrowseButton(!newDisplayBrowseButton);
  // };

  // const toggleShowTimePlayedSwitch = () => {
  //   setNewDisplayTimePlayed(!newDisplayTimePlayed);
  // };

  const toggleHideAllPlayerControls = () => {
    setNewHideAllPlayerControls(!newHideAllPlayerControls);
    if (!newHideAllPlayerControls) {
      setNewDisplayTimePlayed(false);
      setNewDisplayPlayerProgress(false);
      setNewDisplayTrackInfo(false);
      setNewDisplayRepeatButton(false);
      setNewDisplayVolumeControls(false);
    } else {
      setNewDisplayTimePlayed(true);
      setNewDisplayPlayerProgress(true);
      setNewDisplayTrackInfo(true);
      setNewDisplayRepeatButton(true);
      setNewDisplayVolumeControls(true);
    }
  };

  const toggleDisplayPlayerProgressSwitch = () => {
    setNewDisplayPlayerProgress(!newDisplayPlayerProgress);
  };

  const toggleDisplayTheme = () => {
    setNewDisplayTheme(!newDisplayTheme);
  };

  const toggleDisplayTrackInfo = () => {
    setNewDisplayTrackInfo(!newDisplayTrackInfo);
  };

  const toggleDisplayRepeatButton = () => {
    setNewDisplayRepeatButton(!newDisplayRepeatButton);
  };

  const toggleDisplayVolumeControls = () => {
    setNewDisplayVolumeControls(!newDisplayVolumeControls);
  };

  const handleSubmitSettingChanges = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    // deepSleepGenres
    // need to check if array is the same before updating, because else useEffect will get run with genresToPlay ( use genresGotUpdated instead) even though it didn't really change
    const genresGotUpdated =
      !deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres
        ? JSON.stringify(genresToPlay) === JSON.stringify(newCheckedGenres) &&
          newCheckedFavorites === savedCheckedFavorites &&
          JSON.stringify(savedCheckedActivitySettings) ===
            JSON.stringify(newCheckedActivitySettings) &&
          JSON.stringify(savedCheckedNeuralEffectSettings) ===
            JSON.stringify(newCheckedNeuralEffectSettings)
          ? genresGotUpdatedCurrent
          : Math.random()
        : JSON.stringify(sleepGenresToPlay) ===
            JSON.stringify(newCheckedSleepGenres) &&
          newCheckedSleepFavorites === savedCheckedSleepFavorites &&
          JSON.stringify(savedCheckedNeuralEffectSettings) ===
            JSON.stringify(newCheckedNeuralEffectSettings)
        ? genresGotUpdatedCurrent
        : Math.random();

    dispatch(
      confirmUserSettingChanges({
        displayTimePlayed: newDisplayTimePlayed,
        displayPlayerProgress: newDisplayPlayerProgress,
        displayTheme: newDisplayTheme,
        displayTrackInfo: newDisplayTrackInfo,
        genresToPlay: newCheckedGenres,
        activitySettings: newCheckedActivitySettings,
        neuralEffectSettings: newCheckedNeuralEffectSettings,
        sleepGenresToPlay: newCheckedSleepGenres,
        displayRepeatButton: newDisplayRepeatButton,
        displayVolumeControls: newDisplayVolumeControls,
        displayCategorySelection: newDisplayCategorySelection,
        displayCategorySelectionLabel: newDisplayCategorySelectionLabel,
        displayBrowseButton: newDisplayBrowseButton,
        hideAllPlayerControls: newHideAllPlayerControls,
        playFromFavorites: newCheckedFavorites,
        playFromSleepFavorites: newCheckedSleepFavorites,
        genresGotUpdated,
      }),
    );

    const settings = {
      displayTimePlayed: newDisplayTimePlayed,
      displayPlayerProgress: newDisplayPlayerProgress,
      displayTheme: newDisplayTheme,
      displayTrackInfo: newDisplayTrackInfo,
      genresToPlay: newCheckedGenres,
      activitySettings: newCheckedActivitySettings,
      neuralEffectSettings: newCheckedNeuralEffectSettings,
      sleepGenresToPlay: newCheckedSleepGenres,
      displayRepeatButton: newDisplayRepeatButton,
      displayVolumeControls: newDisplayVolumeControls,
      displayCategorySelection: newDisplayCategorySelection,
      displayCategorySelectionLabel: newDisplayCategorySelectionLabel,
      displayBrowseButton: newDisplayBrowseButton,
      hideAllPlayerControls: newHideAllPlayerControls,
      playFromFavorites: newCheckedFavorites,
      playFromSleepFavorites: newCheckedSleepFavorites,
    };
    await updateUserSettings(settings);
    toggleSettingsModal(event);
  };

  const closePremiumOnlyNotication = () => {
    setPremiumOnlyNotication(false);
  };

  const openPremiumOnlyNotication = () => {
    setPremiumOnlyNotication(true);
  };

  const handlePremiumOnlyGenreSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setPremiumOnlyNotication(false);
  };

  const premiumOnlyGenreAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handlePremiumOnlyGenreSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="settings">
      <div className="library-modal-close-icon-container-wrapper">
        <div className="library-modal-close-icon-container">
          <CloseIcon onClick={toggleSettingsModal} style={closeIconStyles} />
        </div>
      </div>

      <Snackbar
        open={premiumOnlyNotication}
        autoHideDuration={6000}
        onClose={closePremiumOnlyNotication}
        message="Premium plan is required to listen to this genre."
        action={premiumOnlyGenreAction}
      />

      <ThemeProvider
        theme={
          selectedTheme === 'spotify dark'
            ? muiTheme
            : selectedTheme === 'dark blue'
            ? muiBlueTheme
            : muiWhiteTheme
        }
      >
        <h2
          className={
            selectedTheme === 'spotify dark'
              ? 'settings__title settings__title--dark'
              : selectedTheme === 'dark blue'
              ? 'settings__title settings__title--blue'
              : 'settings__title settings__title--white'
          }
        >
          Settings
        </h2>

        <div className="main-settings">
          <FormGroup>
            <h3 className="main-settings__title">General</h3>

            {!isUserOnPhoneOrTablet && (
              <div className="main-settings-setting-box">
                <Switch
                  id="displayThemeSwitch"
                  color="secondary"
                  checked={newDisplayTheme}
                  onChange={toggleDisplayTheme}
                  disableRipple
                />
                <label
                  htmlFor="displayThemeSwitch"
                  className="main-settings-setting-box__label"
                >
                  Display theme
                </label>
              </div>
            )}

            <div className="main-settings-setting-box">
              <Switch
                id="displayCategorySelectionSwitch"
                color="secondary"
                checked={newDisplayCategorySelection}
                onChange={toggleDisplayCategorySelection}
                disableRipple
              />
              <label
                htmlFor="displayCategorySelectionSwitch"
                className="main-settings-setting-box__label"
              >
                Display category selection
              </label>
            </div>
            {/* <div className="main-settings-setting-box">
              <Switch
                id="displayCategorySelectionLabelSwitch"
                color="secondary"
                checked={newDisplayCategorySelectionLabel}
                onChange={toggleDisplayCategorySelectionLabel}
                disableRipple
                disabled={!newDisplayCategorySelection}
              />
              <label
                htmlFor="displayCategorySelectionLabelSwitch"
                className={
                  !newDisplayCategorySelection
                    ? 'main-settings-setting-box__label main-settings-setting-box__label--disabled'
                    : 'main-settings-setting-box__label'
                }
              >
                Display category selection label
              </label>
            </div> */}
            {/* <div className="main-settings-setting-box">
              <Switch
                id="displayBrowseButtonSwitch"
                color="secondary"
                checked={newDisplayBrowseButton}
                onChange={toggleDisplayBrowseButton}
                disableRipple
              />
              <label
                htmlFor="displayBrowseButtonSwitch"
                className="main-settings-setting-box__label"
              >
                Display browse tracks button
              </label>
            </div> */}
          </FormGroup>

          <FormGroup>
            <h3 className="main-settings__title">Player</h3>
            <div className="main-settings-setting-box">
              <Switch
                id="displayTrackInfoSwitch"
                color="secondary"
                checked={newDisplayTrackInfo}
                onChange={toggleDisplayTrackInfo}
                disableRipple
                disabled={newHideAllPlayerControls}
              />
              <label
                htmlFor="displayTrackInfoSwitch"
                className={
                  newHideAllPlayerControls
                    ? 'main-settings-setting-box__label main-settings-setting-box__label--disabled'
                    : 'main-settings-setting-box__label'
                }
              >
                Display track information
              </label>
            </div>
            <div className="main-settings-setting-box">
              <Switch
                id="displayPlayerProgress"
                color="secondary"
                checked={newDisplayPlayerProgress}
                onChange={toggleDisplayPlayerProgressSwitch}
                disableRipple
                disabled={newHideAllPlayerControls}
              />
              <label
                htmlFor="displayPlayerProgress"
                className={
                  newHideAllPlayerControls
                    ? 'main-settings-setting-box__label main-settings-setting-box__label--disabled'
                    : 'main-settings-setting-box__label'
                }
              >
                Display player progress bar
              </label>
            </div>

            {/* <div className="main-settings-setting-box">
              <Switch
                id="displayTimePlayedSwitch"
                color="secondary"
                checked={newDisplayTimePlayed}
                onChange={toggleShowTimePlayedSwitch}
                disableRipple
                disabled={newHideAllPlayerControls}
              />
              <label
                htmlFor="displayTimePlayedSwitch"
                className={
                  newHideAllPlayerControls
                    ? 'main-settings-setting-box__label main-settings-setting-box__label--disabled'
                    : 'main-settings-setting-box__label'
                }
              >
                Display time played
              </label>
            </div> */}
            <div className="main-settings-setting-box">
              <Switch
                id="displayRepeatSwitch"
                color="secondary"
                checked={newDisplayRepeatButton}
                onChange={toggleDisplayRepeatButton}
                disableRipple
                disabled={newHideAllPlayerControls}
              />
              <label
                htmlFor="displayRepeatSwitch"
                className={
                  newHideAllPlayerControls
                    ? 'main-settings-setting-box__label main-settings-setting-box__label--disabled'
                    : 'main-settings-setting-box__label'
                }
              >
                Display repeat button
              </label>
            </div>
            <div className="main-settings-setting-box">
              <Switch
                id="displayVolumeControlsSwitch"
                color="secondary"
                checked={newDisplayVolumeControls}
                onChange={toggleDisplayVolumeControls}
                disableRipple
                disabled={newHideAllPlayerControls}
              />
              <label
                htmlFor="displayVolumeControlsSwitch"
                className={
                  newHideAllPlayerControls
                    ? 'main-settings-setting-box__label main-settings-setting-box__label--disabled'
                    : 'main-settings-setting-box__label'
                }
              >
                Display volume controls
              </label>
            </div>
            <div className="main-settings-setting-box">
              <Switch
                id="hideAllPlayerControlsSwitch"
                color="secondary"
                checked={newHideAllPlayerControls}
                onChange={toggleHideAllPlayerControls}
                disableRipple
              />
              <label
                htmlFor="hideAllPlayerControlsSwitch"
                className="main-settings-setting-box__label"
              >
                Hide all player controls
              </label>
            </div>
          </FormGroup>
        </div>

        <div className="paragraph-and-info-tooltip-container">
          <p className="settings__paragraph">
            Select the genres and nature sounds to play.
          </p>
          <InfoTooltip
            popoverText={
              'Select the genres you want to listen to. Only the genres selected will be played.'
            }
          />
        </div>

        <div className="favorite-genre-selection-container">
          <FormGroup>
            <div
              className="favorite-genre-selection-container-switch-and-genre-container"
              key="favorites"
            >
              {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres ? (
                <Switch
                  id={'favorites'}
                  color="secondary"
                  checked={newCheckedFavorites}
                  onChange={handlePlayFromFavoritesSwitchChange}
                  name={'favorites'}
                  disableRipple
                />
              ) : (
                <Switch
                  id={'sleepFavorites'}
                  color="secondary"
                  checked={newCheckedSleepFavorites}
                  onChange={handlePlayFromSleepFavoritesSwitchChange}
                  name={'sleepFavorites'}
                  disableRipple
                />
              )}
              <label
                htmlFor={
                  !deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres
                    ? 'favorites'
                    : 'sleepFavorites'
                }
                className={
                  'favorite-genre-selection-container-switch-and-genre-container__label'
                }
              >
                Play from favorites
              </label>
            </div>
          </FormGroup>
        </div>

        <div className="genre-selection-container">
          <FormGroup>
            <h3 className="genre-selection-container__title">brainfm</h3>
            {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              genreSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedGenres.includes(switchItem.genre)}
                    onChange={() => handleGenreSwitchChange(switchItem.genre)}
                    name={switchItem.genre}
                    disableRipple
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                </div>
              ))}

            {!deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <div className="activity-settings-header-tooltip-container">
                <h4 className="genre-selection-container__subtitle">
                  brainfm activity settings
                </h4>

                <InfoTooltip
                  popoverText={`The brainfm tracks are categorized into 5 different categories -
                deep work, motivation, learning, creativity and light work. Choose which activity tracks
                you want to listen to (you can choose more than one, but at least one must be selected). Also, while
                the tracks are neatly categorized, it's important to understand that these categories
                serve as guiding themes rather than strict boundaries. Each
                category offers a unique ambiance, but it's perfectly natural to
                find tracks from one category enhancing your experience in
                another.`}
                />
              </div>
            )}
            {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              brainfmActivitySettingSwitches.map((switchItem) => (
                <div className="activity-container" key={switchItem.activity}>
                  <Switch
                    id={switchItem.activity}
                    color="secondary"
                    checked={newCheckedActivitySettings.includes(
                      switchItem.activity,
                    )}
                    onChange={() =>
                      handleActivitySettingsSwitchChange(switchItem.activity)
                    }
                    name={switchItem.activity}
                    disableRipple
                  />
                  <label
                    htmlFor={switchItem.activity}
                    className="activity-container__label"
                  >
                    {switchItem.label}
                  </label>

                  <InfoTooltip popoverText={switchItem.tooltipText} />
                </div>
              ))}

            {/* <div className="activity-settings-header-tooltip-container">
              <h4 className="genre-selection-container__subtitle">
                brainfm neural effect settings
              </h4>

              <InfoTooltip
                popoverText={`The strength of neuromodulation embedded in the music. Low is best if you are overstimulated or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation. There are three neural effects available: low, medium, and high. However, not all tracks offer all three effect levels. When more than one effect level is selected for a track, the lowest available effect level will be played. For instance, if a track has all three effect levels (low, medium, and high) available, the low effect will be chosen. If you select only one neural effect level, only tracks with that specific neural effect will be played.`}
              />
            </div> */}

            {/* {!deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <div className="activity-settings-header-tooltip-container">
                <h4 className="genre-selection-container__subtitle">
                  brainfm neural effect settings
                </h4>

                <InfoTooltip
                  popoverText={`The strength of neuromodulation embedded in the music. Low is best if you are overstimulated or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation. There are three neural effects available: low, medium, and high. However, not all tracks offer all three effect levels. When more than one effect level is selected for a track, the lowest available effect level will be played. For instance, if a track has all three effect levels (low, medium, and high) available, the low effect will be chosen. If you select only one neural effect level, only tracks with that specific neural effect will be played.`}
                />
              </div>
            )} */}
            {/* {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              brainfmNeuralEffectSettingSwitches.map((switchItem) => (
                <div className="activity-container" key={switchItem.effect}>
                  <Switch
                    id={switchItem.effect}
                    color="secondary"
                    checked={newCheckedNeuralEffectSettings.includes(
                      switchItem.effect,
                    )}
                    onChange={() =>
                      handleNeuralEffectSettingsSwitchChange(switchItem.effect)
                    }
                    name={switchItem.effect}
                    disableRipple
                  />
                  <label
                    htmlFor={switchItem.effect}
                    className="activity-container__label"
                  >
                    {switchItem.label}
                  </label>

                  <InfoTooltip popoverText={switchItem.tooltipText} />
                </div>
              ))} */}
            {/* <div className="activity-settings-header-tooltip-container">
              <h4 className="genre-selection-container__subtitle">
                brainfm neural effect settings
              </h4>

              <InfoTooltip
                popoverText={`The strength of neuromodulation embedded in the music. Low is best if you are overstimulated or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation. There are three neural effects available: low, medium, and high. However, not all tracks offer all three effect levels. When more than one effect level is selected for a track, the lowest available effect level will be played. For instance, if a track has all three effect levels (low, medium, and high) available, the low effect will be chosen. If you select only one neural effect level, only tracks with that specific neural effect will be played.`}
              />
            </div>

            {brainfmNeuralEffectSettingSwitches.map((switchItem) => (
              <div className="activity-container" key={switchItem.effect}>
                <Switch
                  id={switchItem.effect}
                  color="secondary"
                  checked={newCheckedNeuralEffectSettings.includes(
                    switchItem.effect,
                  )}
                  onChange={() =>
                    handleNeuralEffectSettingsSwitchChange(switchItem.effect)
                  }
                  name={switchItem.effect}
                  disableRipple
                />
                <label
                  htmlFor={switchItem.effect}
                  className="activity-container__label"
                >
                  {switchItem.label}
                </label>

                <InfoTooltip popoverText={switchItem.tooltipText} />
              </div>
            ))} */}

            {(deepSleepGenres || powerNapSleepGenres) &&
              !guidedSleepGenres &&
              sleepBrainfmSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedSleepGenres.includes(switchItem.genre)}
                    onChange={() =>
                      handleSleepGenreSwitchChange(switchItem.genre)
                    }
                    name={switchItem.genre}
                    disableRipple
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                </div>
              ))}

            {guidedSleepGenres &&
              sleepBrainfmGuidedSleepSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedSleepGenres.includes(switchItem.genre)}
                    onChange={() =>
                      handleSleepGenreSwitchChange(switchItem.genre)
                    }
                    name={switchItem.genre}
                    disableRipple
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                </div>
              ))}

            <div className="activity-settings-header-tooltip-container">
              <h4 className="genre-selection-container__subtitle">
                brainfm neural effect settings
              </h4>

              <InfoTooltip
                popoverText={`The strength of neuromodulation embedded in the music. Low is best if you are overstimulated or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation. There are three neural effects available: low, medium, and high. However, not all tracks offer all three effect levels. When more than one effect level is selected for a track, the lowest available effect level will be played. For instance, if a track has all three effect levels (low, medium, and high) available, the low effect will be chosen. If you select only one neural effect level, only tracks with that specific neural effect will be played.`}
              />
            </div>

            {brainfmNeuralEffectSettingSwitches.map((switchItem) => (
              <div className="activity-container" key={switchItem.effect}>
                <Switch
                  id={switchItem.effect}
                  color="secondary"
                  checked={newCheckedNeuralEffectSettings.includes(
                    switchItem.effect,
                  )}
                  onChange={() =>
                    handleNeuralEffectSettingsSwitchChange(switchItem.effect)
                  }
                  name={switchItem.effect}
                  disableRipple
                />
                <label
                  htmlFor={switchItem.effect}
                  className="activity-container__label"
                >
                  {switchItem.label}
                </label>

                <InfoTooltip popoverText={switchItem.tooltipText} />
              </div>
            ))}
          </FormGroup>
          <FormGroup>
            {!deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <h3 className="genre-selection-container__title">focus@will</h3>
            )}
            {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              focusAtWillSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                  onClick={() => {
                    if (!isSubscribed) {
                      openPremiumOnlyNotication();
                    }
                  }}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedGenres.includes(switchItem.genre)}
                    onChange={() => handleGenreSwitchChange(switchItem.genre)}
                    name={switchItem.genre}
                    disableRipple
                    disabled={!isSubscribed}
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                  {!isSubscribed && (
                    <div className="genre-premium-icon-container">
                      <img
                        className="genre-premium-icon-container__icon"
                        src={GoldCrown}
                        alt="premium"
                      />
                    </div>
                  )}
                </div>
              ))}

            {!deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <h3 className="genre-selection-container__title">endel</h3>
            )}
            {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              endelSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                  onClick={() => {
                    if (!isSubscribed) {
                      openPremiumOnlyNotication();
                    }
                  }}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedGenres.includes(switchItem.genre)}
                    onChange={() => handleGenreSwitchChange(switchItem.genre)}
                    name={switchItem.genre}
                    disableRipple
                    disabled={!isSubscribed}
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                  {!isSubscribed && (
                    <div className="genre-premium-icon-container">
                      <img
                        className="genre-premium-icon-container__icon"
                        src={GoldCrown}
                        alt="premium"
                      />
                    </div>
                  )}
                </div>
              ))}

            {!deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <h3 className="genre-selection-container__title">calm</h3>
            )}
            {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              calmSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                  onClick={() => {
                    if (!isSubscribed) {
                      openPremiumOnlyNotication();
                    }
                  }}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedGenres.includes(switchItem.genre)}
                    onChange={() => handleGenreSwitchChange(switchItem.genre)}
                    name={switchItem.genre}
                    disableRipple
                    disabled={!isSubscribed}
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                  {!isSubscribed && (
                    <div className="genre-premium-icon-container">
                      <img
                        className="genre-premium-icon-container__icon"
                        src={GoldCrown}
                        alt="premium"
                      />
                    </div>
                  )}
                </div>
              ))}

            {!deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <h3 className="genre-selection-container__title">headspace</h3>
            )}
            {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              headspaceSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                  onClick={() => {
                    if (!isSubscribed) {
                      openPremiumOnlyNotication();
                    }
                  }}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedGenres.includes(switchItem.genre)}
                    onChange={() => handleGenreSwitchChange(switchItem.genre)}
                    name={switchItem.genre}
                    disableRipple
                    disabled={!isSubscribed}
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                  {!isSubscribed && (
                    <div className="genre-premium-icon-container">
                      <img
                        className="genre-premium-icon-container__icon"
                        src={GoldCrown}
                        alt="premium"
                      />
                    </div>
                  )}
                </div>
              ))}

            {!deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <h3 className="genre-selection-container__title">other</h3>
            )}
            {!deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              otherSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                  onClick={() => {
                    if (!isSubscribed) {
                      openPremiumOnlyNotication();
                    }
                  }}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedGenres.includes(switchItem.genre)}
                    onChange={() => handleGenreSwitchChange(switchItem.genre)}
                    name={switchItem.genre}
                    disableRipple
                    disabled={!isSubscribed}
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                  {!isSubscribed && (
                    <div className="genre-premium-icon-container">
                      <img
                        className="genre-premium-icon-container__icon"
                        src={GoldCrown}
                        alt="premium"
                      />
                    </div>
                  )}
                </div>
              ))}

            {deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <h3 className="genre-selection-container__title">calm</h3>
            )}
            {deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              sleepCalmSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                  onClick={() => {
                    if (!isSubscribed) {
                      openPremiumOnlyNotication();
                    }
                  }}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedSleepGenres.includes(switchItem.genre)}
                    onChange={() =>
                      handleSleepGenreSwitchChange(switchItem.genre)
                    }
                    name={switchItem.genre}
                    disableRipple
                    disabled={!isSubscribed}
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                  {!isSubscribed && (
                    <div className="genre-premium-icon-container">
                      <img
                        className="genre-premium-icon-container__icon"
                        src={GoldCrown}
                        alt="premium"
                      />
                    </div>
                  )}
                </div>
              ))}

            {deepSleepGenres && !powerNapSleepGenres && !guidedSleepGenres && (
              <h3 className="genre-selection-container__title">headspace</h3>
            )}
            {deepSleepGenres &&
              !powerNapSleepGenres &&
              !guidedSleepGenres &&
              sleepHeadspaceSwitches.map((switchItem) => (
                <div
                  className="genre-selection-container-switch-and-genre-container"
                  key={switchItem.genre}
                  onClick={() => {
                    if (!isSubscribed) {
                      openPremiumOnlyNotication();
                    }
                  }}
                >
                  <Switch
                    id={switchItem.genre}
                    color="secondary"
                    checked={newCheckedSleepGenres.includes(switchItem.genre)}
                    onChange={() =>
                      handleSleepGenreSwitchChange(switchItem.genre)
                    }
                    name={switchItem.genre}
                    disableRipple
                    disabled={!isSubscribed}
                  />
                  <label
                    htmlFor={switchItem.genre}
                    className="genre-selection-container-switch-and-genre-container__label"
                  >
                    {switchItem.label}
                  </label>
                  {!isSubscribed && (
                    <div className="genre-premium-icon-container">
                      <img
                        className="genre-premium-icon-container__icon"
                        src={GoldCrown}
                        alt="premium"
                      />
                    </div>
                  )}
                </div>
              ))}
          </FormGroup>
        </div>

        <div className="settings-save-button-container">
          <Button
            onClick={handleSubmitSettingChanges}
            variant="contained"
            style={
              selectedTheme === 'youtube white'
                ? rectangleButtonStylesWhiteTheme
                : rectangleButtonStyles
            }
          >
            Save Changes
          </Button>
        </div>
      </ThemeProvider>
    </div>
  );
};

const genreSwitches = [
  { genre: 'acoustic', label: 'acoustic' },
  { genre: 'atmospheric', label: 'atmospheric' },
  { genre: 'cinematic', label: 'cinematic' },
  { genre: 'classical', label: 'classical' },
  { genre: 'drone', label: 'drone' },
  { genre: 'electronic', label: 'electronic' },
  { genre: 'grooves', label: 'grooves' },
  { genre: 'lofi', label: 'lofi' },
  { genre: 'piano', label: 'piano' },
  { genre: 'post rock', label: 'post rock' },
  { genre: 'beach', label: 'beach' },
  { genre: 'chimes & bowls', label: 'chimes & bowls' },
  { genre: 'forest', label: 'forest' },
  { genre: 'nightsounds', label: 'nightsounds' },
  { genre: 'rain', label: 'rain' },
  { genre: 'rainforest', label: 'rainforest' },
  { genre: 'river', label: 'river' },
  { genre: 'thunder', label: 'thunder' },
  { genre: 'underwater', label: 'underwater' },
  { genre: 'wind', label: 'wind' },
];

const focusAtWillSwitches = [
  { genre: 'jambient jungle', label: 'jambient jungle' },
  { genre: 'the deep', label: 'the deep' },
  { genre: 'chinchilla', label: 'chinchilla' },
  { genre: 'the drop', label: 'the drop' },
  { genre: 'deep space', label: 'deep space' },
  { genre: 'symphonica', label: 'symphonica' },
  { genre: 'tranquility', label: 'tranquility' },
  { genre: 'organica', label: 'organica' },
  { genre: 'powertool', label: 'powertool' },
  { genre: 'the duke', label: 'the duke' },
  { genre: 'bach beats', label: 'bach beats' },
  { genre: 'edwardian', label: 'edwardian' },
  { genre: 'atmosphere', label: 'atmosphere' },
  { genre: 'aquascope', label: 'aquascope' },
  { genre: 'cinematic (focus@will)', label: 'cinematic' },
  { genre: 'nature beat', label: 'nature beat' },
  { genre: 'evolve', label: 'evolve' },
  { genre: 'zen piano', label: 'zen piano' },
];

const endelSwitches = [
  { genre: 'endel focus', label: 'endel focus' },
  { genre: 'endel nature', label: 'endel nature' },
  { genre: 'endel winter', label: 'endel winter' },
];

const calmSwitches = [
  { genre: 'ambient & atmospheric', label: 'ambient & atmospheric' },
  { genre: 'beats', label: 'beats' },
  { genre: 'electronic (Calm)', label: 'electronic' },
  { genre: 'piano (Calm)', label: 'piano' },
];

const headspaceSwitches = [{ genre: 'headspace', label: 'headspace' }];

const otherSwitches = [{ genre: 'quantum', label: 'quantum' }];

const brainfmActivitySettingSwitches = [
  {
    activity: 'deep-work',
    label: 'deep work',
    tooltipText:
      'Designed for demanding tasks that require sustained periods of intense focus.',
  },
  {
    activity: 'motivation',
    label: 'motivation',
    tooltipText:
      'Energizing and upbeat, this music has been curated to enhance your drive and concentration.',
  },
  {
    activity: 'creativity',
    label: 'creativity',
    tooltipText:
      'Music designed to engage and inspire. Use it to help with creative design, ideation, or expression.',
  },
  {
    activity: 'study',
    label: 'learning',
    tooltipText:
      'This music helps with knowledge retention or long periods of reading.',
  },
  {
    activity: 'light-work',
    label: 'light work',
    tooltipText:
      'Created for multiple less-demanding tasks to be done in quick succession.',
  },
];

const brainfmNeuralEffectSettingSwitches = [
  {
    effect: 'low effect',
    label: 'low effect',
    tooltipText:
      'Use this effect level if you are generally sensitive to sounds, or if the higher effect levels feel too intense.',
  },
  {
    effect: 'medium effect',
    label: 'medium effect',
    tooltipText:
      'Our standard level of neural phase locking is a great place to start. Be sure to try the other levels to find what works best for you!',
  },
  {
    effect: 'high effect',
    label: 'high effect',
    tooltipText:
      'Try the strongest level of our neural phase locking technology if you need extra stimulation, or have attentional challenges (ADHD or similar).',
  },
];

const sleepBrainfmSwitches = [
  { genre: 'atmospheric', label: 'atmospheric' },
  { genre: 'beach', label: 'beach' },
  { genre: 'forest', label: 'forest' },
  { genre: 'nightsounds', label: 'nightsounds' },
  { genre: 'rain', label: 'rain' },
  { genre: 'rainforest', label: 'rainforest' },
  { genre: 'river', label: 'river' },
  { genre: 'thunder', label: 'thunder' },
  { genre: 'underwater', label: 'underwater' },
  { genre: 'wind', label: 'wind' },
];

const sleepBrainfmGuidedSleepSwitches = [
  { genre: 'atmospheric', label: 'atmospheric' },
];

const sleepCalmSwitches = [
  { genre: 'ambient & atmospheric', label: 'ambient & atmospheric' },
  { genre: 'classical & strings', label: 'classical & strings' },
  { genre: 'electronic (Calm sleep)', label: 'electronic' },
  { genre: 'lullabies', label: 'lullabies' },
  { genre: 'nature', label: 'nature' },
  { genre: 'piano (Calm)', label: 'piano' },
];

const sleepHeadspaceSwitches = [{ genre: 'headspace', label: 'headspace' }];

export default Settings;
