import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { changePlayingTrack, setIsPlaying } from '../reducers/librarySlice';
import { addToPlayedToday, updateUserVolumeLevel } from '../reducers/userSlice';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import RefProps from 'react-h5-audio-player';
import Theme from './Theme';
import TrackInformation from './TrackInformation';
import Stopwatch from './Stopwatch';
import { StoreState } from '../store/store';
import { Track, TracksQueData, PlayingTrack } from '../reducers/librarySlice';
import { updateUserVolumeSettings } from '../api/user/user';
import { signUserOut, getSecondHash } from '../api/auth/login';
import { brainfmGenres } from './library/Track';

interface PlayerProps {
  isLibraryModalOpen: boolean;
  isSettingsModalOpen: boolean;
  sendPlayerContext: (playerContextValue: PlayerRefType) => void;
  pauseByTimer: boolean;
  setPauseByTimerToFalse: () => void;
}

export type PlayerRefType = React.RefObject<HTMLAudioElement> | RefProps | null;

const Player = (props: PlayerProps) => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const location = useLocation();
  const savedDisplayPlayerProgressSetting = useSelector(
    (state: StoreState) => state.user.displayPlayerProgress,
  );
  const sleepCategoryGenres =
    location.pathname === '/deep-sleep' ||
    location.pathname === '/guided-sleep' ||
    location.pathname === '/power-nap';
  const activitySettings = useSelector(
    (state: StoreState) => state.user.activitySettings,
  );
  const neuralEffectSettings: string[] = useSelector(
    (state: StoreState) => state.user.neuralEffectSettings,
  );
  const [currentPlaybackTime, setCurrentPlaybackTime] = useState(0);
  const [lastFetchTimestamp, setLastFetchTimestamp] = useState(0);
  const [fetchingInProgress, setFetchingInProgress] = useState(false);
  const [failedFetchTimestamp, setFailedFetchTimestamp] = useState(0);

  const [userHasInteractedForiOS, setUserHasInteractedForiOS] = useState(false);
  const {
    isLibraryModalOpen,
    isSettingsModalOpen,
    sendPlayerContext,
    pauseByTimer,
    setPauseByTimerToFalse,
  } = props;
  const isSubscribed = useSelector(
    (state: StoreState) => state.user.subscribed,
  );
  const [noTracksToPlayNotification, setNoTracksToPlayNotification] =
    useState(false);
  const isPlaying = useSelector((state: StoreState) => state.library.isPlaying);
  const dispatch = useDispatch();
  const audioRef = useRef<RefProps | null>(null);
  const currentTracksQueData = useSelector(
    (state: StoreState) => state.library.tracksQueData,
  );
  const generalGenresToPlay = useSelector(
    (state: StoreState) => state.user.genresToPlay,
  );
  const sleepGenresToPlay = useSelector(
    (state: StoreState) => state.user.sleepGenresToPlay,
  );
  const genresToPlay = !sleepCategoryGenres
    ? generalGenresToPlay
    : sleepGenresToPlay;
  const shouldPlayFromFavorites = useSelector(
    (state: StoreState) => state.user.playFromFavorites,
  );
  const shouldPlayFromSleepFavorites = useSelector(
    (state: StoreState) => state.user.playFromSleepFavorites,
  );

  const playFromFavorites = !sleepCategoryGenres
    ? shouldPlayFromFavorites
    : shouldPlayFromSleepFavorites;

  const genresGotUpdated = useSelector(
    (state: StoreState) => state.user.genresGotUpdated,
  );
  const uninterestedTrackIds = useSelector(
    (state: StoreState) => state.user.uninterested,
  );
  const favoriteTrackIds = useSelector(
    (state: StoreState) => state.user.favorites,
  );
  const trackPath = useSelector(
    (state: StoreState) => state.library.playingTrack.path,
  );

  const tracks = useSelector((state: StoreState) => state.library.tracks);
  const displayRepeatButton = useSelector(
    (state: StoreState) => state.user.displayRepeatButton,
  );
  const displayVolumeControls = useSelector(
    (state: StoreState) => state.user.displayVolumeControls,
  );
  const hideAllPlayerControls = useSelector(
    (state: StoreState) => state.user.hideAllPlayerControls,
  );

  const volumeLevel = useSelector(
    (state: StoreState) => state.user.volumeLevel,
  );
  const token = useMemo(() => {
    return getSecondHash();
  }, []);

  useEffect(() => {
    if (displayRepeatButton) {
      addPlayerRepeatButton();
    } else {
      removePlayerRepeatButton();
    }
  }, [displayRepeatButton]);

  useEffect(() => {
    if (displayVolumeControls) {
      addPlayerVolumeControls();
    } else {
      removePlayerVolumeControls();
    }
  }, [displayVolumeControls]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isLibraryModalOpen || isSettingsModalOpen) {
        return;
      }

      if (event.key === ' ' || event.key === 'Spacebar') {
        event.preventDefault();
        if (audioRef.current && audioRef.current.audio.current) {
          if (audioRef.current.audio.current.paused) {
            audioRef.current.audio.current.play();
          } else {
            audioRef.current.audio.current.pause();
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isLibraryModalOpen, isSettingsModalOpen]);

  useEffect(() => {
    if (pauseByTimer) {
      if (audioRef.current && audioRef.current.audio.current) {
        audioRef.current.audio.current.pause();
        handlePause();
      }
      setPauseByTimerToFalse();
    }
  }, [pauseByTimer]);

  const getPathAccordingToNeuralSettingsAndIfExternalSource = (
    neuralEffectSettings: string[],
    playingTrack: PlayingTrack,
  ) => {
    if (playingTrack.linkPath) {
      return playingTrack.linkPath;
    } else if (brainfmGenres.includes(playingTrack.genre)) {
      const neuralEffectPath = getNeuralEffectTrackPath(
        neuralEffectSettings,
        playingTrack,
      );

      const isDirectLinkPath = neuralEffectPath.includes('dropboxusercontent');
      if (isDirectLinkPath) {
        return neuralEffectPath;
      } else {
        return `${process.env.REACT_APP_API_URL}/api/tracks/${neuralEffectPath}?token=${token}`;
      }
    } else {
      return `${process.env.REACT_APP_API_URL}/api/tracks/${playingTrack.path}?token=${token}`;
    }
  };

  const initializeTrackstoPlay = () => {
    if (tracks.length !== 0) {
      const tracksData = initializeTracks(
        tracks,
        genresToPlay,
        uninterestedTrackIds,
        favoriteTrackIds,
        isSubscribed,
        currentTracksQueData.currentPlayingTrackIndex,
        playFromFavorites,
        activitySettings,
        neuralEffectSettings,
        sleepCategoryGenres,
      );
      if (!tracksData) {
        openNoTracksToPlayNotification();
        disablePlayerPreviousButton();
        disablePlayerNextButton();
      } else {
        const { playingTrack, tracksQueData } = tracksData;

        const adjustedPath =
          getPathAccordingToNeuralSettingsAndIfExternalSource(
            neuralEffectSettings,
            playingTrack,
          );

        const playingTrackWithAdjustedPath = {
          ...playingTrack,
          path: adjustedPath,
        };

        dispatch(
          changePlayingTrack({
            playingTrack: playingTrackWithAdjustedPath,
            tracksQueData,
          }),
        );

        dispatch(addToPlayedToday(playingTrack.id));
        disablePlayerPreviousButton();
        if (tracksQueData.isLastTrack) {
          disablePlayerNextButton();
        }
      }
    }
  };

  useEffect(() => {
    const progressSection = document.querySelector(
      '.rhap_progress-section',
    ) as HTMLElement;

    if (savedDisplayPlayerProgressSetting) {
      progressSection.style.display = '';
    } else {
      progressSection.style.display = 'none';
    }
  }, [savedDisplayPlayerProgressSetting]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    initializeTrackstoPlay();
  }, [genresGotUpdated]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const handlePlayNextAndOnTrackEnd = () => {
    if (tracks.length !== 0) {
      const tracksData = getNextTrack(
        tracks,
        currentTracksQueData,
        genresToPlay,
        uninterestedTrackIds,
        favoriteTrackIds,
        isSubscribed,
        playFromFavorites,
        activitySettings,
        neuralEffectSettings,
        sleepCategoryGenres,
      );
      const { playingTrack, tracksQueData } = tracksData;
      const adjustedPath = getPathAccordingToNeuralSettingsAndIfExternalSource(
        neuralEffectSettings,
        playingTrack,
      );

      const playingTrackWithAdjustedPath = {
        ...playingTrack,
        path: adjustedPath,
      };

      dispatch(
        changePlayingTrack({
          playingTrack: playingTrackWithAdjustedPath,
          tracksQueData,
        }),
      );
      dispatch(addToPlayedToday(playingTrack.id));
      enablePlayerPreviousButton();
    }
  };

  const handlePlayNextTrack = () => {
    if (currentTracksQueData.isLastTrack) {
      initializeTrackstoPlay();
      disablePlayerPreviousButton();
    } else {
      handlePlayNextAndOnTrackEnd();
    }
  };

  const handePlayPreviousTrack = () => {
    const previousButton = document.querySelector('[aria-label="Previous"]');
    if (
      previousButton &&
      previousButton.classList.contains('prev-skip-button-disabled')
    ) {
      return;
    }
    const tracksData = getPreviousTrack(tracks, currentTracksQueData);
    const { playingTrack, tracksQueData } = tracksData;

    const adjustedPath = getPathAccordingToNeuralSettingsAndIfExternalSource(
      neuralEffectSettings,
      playingTrack,
    );

    const playingTrackWithAdjustedPath = {
      ...playingTrack,
      path: adjustedPath,
    };

    dispatch(
      changePlayingTrack({
        playingTrack: playingTrackWithAdjustedPath,
        tracksQueData,
      }),
    );

    if (tracksQueData.isFirstTrack) {
      disablePlayerPreviousButton();
    }
    enablePlayerNextButton();
  };

  const handlePause = () => {
    dispatch(setIsPlaying(false));
  };

  const handlePlay = () => {
    if (!userHasInteractedForiOS) {
      setUserHasInteractedForiOS(true);
    }

    dispatch(setIsPlaying(true));
    if (currentTracksQueData.isFirstTrack) {
      disablePlayerPreviousButton();
    }

    if (currentTracksQueData.isLastTrack && currentTracksQueData.isFirstTrack) {
      disablePlayerNextButton();
    } else {
      enablePlayerNextButton();
    }
  };

  const playerContextValue: PlayerRefType =
    audioRef.current !== null ? audioRef.current : null;

  useEffect(() => {
    const fetchData = async () => {
      sendPlayerContext(playerContextValue);
    };

    fetchData();
  }, [sendPlayerContext, playerContextValue]);

  const [volumeLevelToUpdate, setVolumeLevelToUpdate] =
    useDebounceWithNumberState(2000, volumeLevel);

  const handleVolumeChange = (event: any) => {
    const newVolumeLevel = event.target.volume;

    if (newVolumeLevel !== volumeLevel && !isUserOnPhoneOrTablet) {
      setVolumeLevelToUpdate(event.target.volume);
    }
  };

  const handleUpdateUserVolumeSettings = async (
    volumeLevelToUpdate: number,
  ) => {
    dispatch(updateUserVolumeLevel(volumeLevelToUpdate));
    await updateUserVolumeSettings(volumeLevelToUpdate);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (volumeLevelToUpdate !== volumeLevel) {
        try {
          await handleUpdateUserVolumeSettings(volumeLevelToUpdate);
        } catch (error) {
          if (
            error.message === 'Unauthorized.' ||
            error.code === 'too-many-requests'
          ) {
            signUserOut();
          }
        }
      }
    };

    fetchData();
  }, [volumeLevelToUpdate, volumeLevel]);

  const closeNoTracksToPlayNotification = () => {
    setNoTracksToPlayNotification(false);
  };

  const openNoTracksToPlayNotification = () => {
    setNoTracksToPlayNotification(true);
  };

  const handlePremiumOnlyGenreSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setNoTracksToPlayNotification(false);
  };

  const isUserOnPhoneOrTablet: boolean =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ) ||
    (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) ||
    false;

  const isUserOnIOS: boolean =
    /iPhone|iPad|iPod/i.test(navigator.userAgent) || false;

  const noTracksToPlayAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handlePremiumOnlyGenreSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    // Add an event listener to update currentPlaybackTime
    const updatePlaybackTime = () => {
      const playerInstance = audioRef.current;
      const audioElement = playerInstance?.audio.current;

      if (audioElement) {
        setCurrentPlaybackTime(audioElement.currentTime);
      }
    };

    // Attach the event listener
    const playerInstance = audioRef.current;
    const audioElement = playerInstance?.audio.current;
    if (audioElement) {
      audioElement.addEventListener('timeupdate', updatePlaybackTime);
    }

    // Clean up the event listener on component unmount
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('timeupdate', updatePlaybackTime);
      }
    };
  }, []);

  const handleStalled = async () => {
    console.log('onStalled event triggered');

    const playerInstance = audioRef.current;
    const audioElement = playerInstance?.audio.current;

    if (audioElement && isUserOnIOS) {
      if (audioElement.duration > 2100) {
        // 35 minutes * 60 seconds
        console.log('Audio is longer than 35 minutes. Skipping.');
        return;
      }

      // Check if the audio is already in a fetching retry loop
      if (fetchingInProgress) {
        console.log('Fetching already in progress. Skipping.');
        return;
      }

      // Store the current playback time
      const desiredStartTime = currentPlaybackTime;

      // Check if a fetch attempt failed in the last 30 seconds
      const cooldownAfterFailure = 20000; // 30 seconds (adjust as needed)
      const currentTime = Date.now();
      if (currentTime - failedFetchTimestamp < cooldownAfterFailure) {
        console.log('Cooldown after failure. Skipping.');
        return;
      }

      // Check if the cooldown period has passed since the last successful fetch
      const cooldownDuration = 10000; // 10 seconds (adjust as needed)
      if (currentTime - lastFetchTimestamp < cooldownDuration) {
        console.log('Cooldown period. Skipping.');
        return;
      }

      // Set a flag to indicate that a fetch operation is in progress
      setFetchingInProgress(true);

      // Reload the audio element
      audioElement.load();

      try {
        // Wait for the audio to load
        await audioElement.play();

        // Set the desired playback start time
        audioElement.currentTime = desiredStartTime;

        console.log('Audio loaded successfully after retry');
        // Update the timestamp of the last successful fetch
        setLastFetchTimestamp(currentTime);
      } catch (error) {
        // Handle errors during fetch
        console.error('Error fetching audio:', error);
        // Update the timestamp of the failed fetch
        setFailedFetchTimestamp(currentTime);
      } finally {
        // Reset the fetching in progress flag
        setFetchingInProgress(false);
      }
    }
  };

  const handlePlayError = () => {
    const playerInstance = audioRef.current;
    const audioElement = playerInstance?.audio.current;

    if (audioElement) {
      audioElement.src = '';

      // Wait for a short time
      setTimeout(() => {
        // Set the source back to the original URL to trigger a new fetch
        // audioElement.src = `${process.env.REACT_APP_API_URL}/api/tracks/${trackPath}?token=${token}`;
        audioElement.src = trackPath;
        // Retry playing
        audioElement.play().catch((playError) => {
          console.error('Error retrying playback:', playError);
        });
      }, 1000); // 1 second delay (adjust as needed)
    }
  };

  const handleOnCanPlayThrough = () => {
    if (audioRef.current && audioRef.current.audio.current?.readyState === 4) {
      // console.log('can play through');
    }
  };

  // const handleProgress = () => {
  //   const audio = audioRef.current.audio.current;
  //   if (audio.buffered.length > 0) {
  //     const bufferedEnd = audio.buffered.end(audio.buffered.length - 1);
  //     const duration = audio.duration;
  //     if (bufferedEnd === duration) {
  //       console.log('audio fully loaded');
  //       // setIsFullyLoaded(true);
  //     }
  //   }
  // };

  // console.log('currentTrackPath ', currentTrackPath);

  return (
    // <div className="row">
    <div>
      <Snackbar
        open={noTracksToPlayNotification}
        autoHideDuration={6000}
        onClose={closeNoTracksToPlayNotification}
        message="No tracks to play with current settings."
        action={noTracksToPlayAction}
      />

      <PlayerContext.Provider value={playerContextValue}>
        {/* <Theme
          isLibraryModalOpen={isLibraryModalOpen}
          isSettingsModalOpen={isSettingsModalOpen}
          isPlaying={isPlaying}
          token={token}
        /> */}

        <div
          className={
            hideAllPlayerControls
              ? `audio-player-container audio-player-container--hidden`
              : selectedTheme === 'spotify dark'
              ? `audio-player-container audio-player-container--dark`
              : selectedTheme === 'dark blue'
              ? `audio-player-container audio-player-container--blue`
              : `audio-player-container audio-player-container--white`
          }
        >
          <div className="audio-container">
            <div className="track-info-container">
              <TrackInformation />
            </div>
            <AudioPlayer
              // onProgress={handleProgress}
              ref={audioRef}
              autoPlay={false}
              autoPlayAfterSrcChange={
                isUserOnPhoneOrTablet
                  ? userHasInteractedForiOS
                    ? true
                    : false
                  : true
              }
              showSkipControls={true}
              showJumpControls={false}
              src={trackPath}
              // src={
              //   neuralEffectPath
              //     ? `${process.env.REACT_APP_API_URL}/api/tracks/${neuralEffectPath}?token=${token}`
              //     : pathLinkId
              //     ? `${process.env.REACT_APP_API_URL}/api/v10?id=${pathLinkId}`
              //     : `${process.env.REACT_APP_API_URL}/api/tracks/${track}?token=${token}`
              // }
              // src={
              //   currentTrackPath &&
              //   `${process.env.REACT_APP_API_URL}/api/tracks/${currentTrackPath}?token=${token}`
              // }
              onClickNext={handlePlayNextTrack}
              onClickPrevious={handePlayPreviousTrack}
              onPlay={handlePlay}
              onPause={handlePause}
              onEnded={handlePlayNextTrack}
              volume={isUserOnPhoneOrTablet ? 1 : volumeLevel}
              onVolumeChange={handleVolumeChange}
              onPlayError={handlePlayError}
              onStalled={() => handleStalled()}
              customIcons={customIcons}
              showDownloadProgress={false}
              showFilledProgress={false}
              onCanPlayThrough={handleOnCanPlayThrough}
            />
          </div>
        </div>
      </PlayerContext.Provider>
    </div>
  );
};

type NeuralEffect = 'low effect' | 'medium effect' | 'high effect';

export function getNeuralEffectTrackPath(
  neuralEffectSettings: string[],
  playingTrack: any,
): string {
  // Define the order of preference for the neural effects
  const effectOrder: NeuralEffect[] = [
    'low effect',
    'medium effect',
    'high effect',
  ];

  // Filter and map user's settings to valid NeuralEffect types
  const validSettings = neuralEffectSettings.filter(
    (setting): setting is NeuralEffect =>
      effectOrder.includes(setting as NeuralEffect),
  );

  // Sort the user's settings according to the order of preference
  const sortedSettings = validSettings.sort(
    (a, b) => effectOrder.indexOf(a) - effectOrder.indexOf(b),
  );

  // Define the mapping between effect names and the track properties
  const effectToPath: { [key in NeuralEffect]: string } = {
    'low effect': playingTrack.lowNeuralPath
      ? playingTrack.lowNeuralPath
      : playingTrack.lowNeuralLinkPath
      ? playingTrack.lowNeuralLinkPath
      : '',
    'medium effect': playingTrack.mediumNeuralPath
      ? playingTrack.mediumNeuralPath
      : playingTrack.mediumNeuralLinkPath
      ? playingTrack.mediumNeuralLinkPath
      : '',
    'high effect': playingTrack.highNeuralPath
      ? playingTrack.highNeuralPath
      : playingTrack.highNeuralLinkPath
      ? playingTrack.highNeuralLinkPath
      : '',
  };

  // If no valid settings are provided, use the default effectOrder
  const settingsToUse =
    sortedSettings.length === 0 ? effectOrder : sortedSettings;

  // Iterate over the sorted settings and return the first available path
  for (const setting of settingsToUse) {
    if (effectToPath[setting] !== '') {
      return effectToPath[setting];
    }
  }

  // If no valid settings match, return the first available path from the effectOrder
  for (const effect of effectOrder) {
    if (effectToPath[effect] !== '') {
      return effectToPath[effect];
    }
  }

  // If no path is found, return an empty string (this case should not occur given the problem constraints)
  return '';
}

export const PlayerContext = createContext<RefProps | null>(null);

export const initializeTracks = (
  tracks: Track[],
  genresToPlay: string[],
  uninterestedTrackIds: string[],
  favoriteTrackIds: string[],
  isSubscribed: boolean,
  currentPlayingTrackIndex: number,
  playFromFavorites: boolean,
  activitySettings: string[],
  neuralEffectSettings: string[],
  sleepCategoryGenres: boolean,
) => {
  const playedTrackIndexes: number[] = [];

  let trackIndex = findNextSuitableTrackIndex(
    tracks,
    playedTrackIndexes,
    currentPlayingTrackIndex,
    genresToPlay,
    uninterestedTrackIds,
    favoriteTrackIds,
    isSubscribed,
    playFromFavorites,
    activitySettings,
    neuralEffectSettings,
    sleepCategoryGenres,
  );

  if (trackIndex === -1) {
    trackIndex = findNextSuitableTrackIndex(
      tracks,
      [],
      0,
      genresToPlay,
      uninterestedTrackIds,
      favoriteTrackIds,
      isSubscribed,
      playFromFavorites,
      activitySettings,
      neuralEffectSettings,
      sleepCategoryGenres,
    );
  }

  // if no tracks to play, unlikely case when user is unsubscribed, probably has only one genre selected and that track is in not interested
  if (trackIndex === -1) {
    return;
  }

  const {
    path,
    name,
    id,
    genre,
    imageUrl,
    displayImageUrl,
    pathLinkId,
    lowNeuralPath,
    mediumNeuralPath,
    highNeuralPath,
    linkPath,
    lowNeuralLinkPath,
    mediumNeuralLinkPath,
    highNeuralLinkPath,
  } = tracks[trackIndex];
  const playingTrack = {
    path,
    name,
    id,
    genre,
    imageUrl,
    displayImageUrl,
    pathLinkId,
    lowNeuralPath,
    mediumNeuralPath,
    highNeuralPath,
    linkPath,
    lowNeuralLinkPath,
    mediumNeuralLinkPath,
    highNeuralLinkPath,
  };

  const isLastTrack =
    findNextSuitableTrackIndex(
      tracks,
      [trackIndex],
      trackIndex,
      genresToPlay,
      uninterestedTrackIds,
      favoriteTrackIds,
      isSubscribed,
      playFromFavorites,
      activitySettings,
      neuralEffectSettings,
      sleepCategoryGenres,
    ) === -1;

  return {
    playingTrack,
    tracksQueData: {
      currentPlayingTrackIndex: trackIndex,
      trackStartPoint: 0,
      playedTrackIndexes: [trackIndex],
      trackQueIndex: 0,
      isFirstTrack: true,
      isLastTrack,
    },
  };
};

function doesTrackMatchNeuralEffectSettings(
  userNeuralEffectSettings: string[],
  track: Track,
): boolean {
  // Define the valid neural effects
  const effectOrder: NeuralEffect[] = [
    'low effect',
    'medium effect',
    'high effect',
  ];

  // make changes here

  // Filter and map user's settings to valid NeuralEffect types
  const validSettings = userNeuralEffectSettings.filter(
    (setting): setting is NeuralEffect =>
      effectOrder.includes(setting as NeuralEffect),
  );

  // If settings are empty or contain all possible effects, return true
  if (
    validSettings.length === 0 ||
    validSettings.length === effectOrder.length
  ) {
    return true;
  }

  // Define the mapping between effect names and the track properties
  const effectToPath: { [key in NeuralEffect]: string } = {
    'low effect': track.lowNeuralPath
      ? track.lowNeuralPath
      : track.lowNeuralLinkPath
      ? track.lowNeuralLinkPath
      : '',
    'medium effect': track.mediumNeuralPath
      ? track.mediumNeuralPath
      : track.mediumNeuralLinkPath
      ? track.mediumNeuralLinkPath
      : '',
    'high effect': track.highNeuralPath
      ? track.highNeuralPath
      : track.highNeuralLinkPath
      ? track.highNeuralLinkPath
      : '',
  };

  // Check if the track matches any of the user's specific settings
  for (const setting of validSettings) {
    if (effectToPath[setting] !== '') {
      return true;
    }
  }

  // If no valid paths match, return false
  return false;
}

const filterTrackByUserSettings = (
  index: number,
  genresToPlay: string[],
  uninterestedTrackIds: string[],
  favoriteTrackIds: string[],
  tracks: Track[],
  isSubscribed: boolean,
  playFromFavorites: boolean,
  activitySettings: string[],
  neuralEffectSettings: string[],
  sleepCategoryGenres: boolean,
) => {
  const includedByBrainfmNeuralEffectSettings =
    doesTrackMatchNeuralEffectSettings(neuralEffectSettings, tracks[index]);

  if (
    brainfmGenres.includes(tracks[index].genre) &&
    !includedByBrainfmNeuralEffectSettings
  ) {
    return false;
  }

  const notIncludedByBrainfmActivitySettings =
    brainfmGenres.includes(tracks[index].genre) &&
    !activitySettings.includes(tracks[index].category) &&
    !activitySettings.includes(tracks[index].additionalCategory) &&
    !activitySettings.includes(tracks[index].additionalSecondCategory);

  if (notIncludedByBrainfmActivitySettings && !sleepCategoryGenres) {
    return false;
  }

  if (isSubscribed) {
    if (genresToPlay.length === 0 && !playFromFavorites) {
      return false;
    } else if (
      playFromFavorites &&
      favoriteTrackIds.some((id) => id === tracks[index].id)
    ) {
      return true;
    } else if (
      genresToPlay.length > 0 &&
      uninterestedTrackIds.length > 0 &&
      genresToPlay.some((genre) => genre === tracks[index].genre) &&
      !uninterestedTrackIds.some((id) => id === tracks[index].id)
    ) {
      return true;
    } else if (
      genresToPlay.length > 0 &&
      uninterestedTrackIds.length === 0 &&
      genresToPlay.some((genre) => genre === tracks[index].genre)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    if (genresToPlay.length === 0 && !playFromFavorites) {
      return false;
    } else if (
      playFromFavorites &&
      favoriteTrackIds.some((id) => id === tracks[index].id) &&
      tracks[index].premium !== 1
    ) {
      return true;
    } else if (
      genresToPlay.length > 0 &&
      uninterestedTrackIds.length > 0 &&
      genresToPlay.some((genre) => genre === tracks[index].genre) &&
      !uninterestedTrackIds.some((id) => id === tracks[index].id) &&
      tracks[index].premium !== 1
    ) {
      return true;
    } else if (
      genresToPlay.length > 0 &&
      uninterestedTrackIds.length === 0 &&
      genresToPlay.some((genre) => genre === tracks[index].genre) &&
      tracks[index].premium !== 1
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const findNextSuitableTrackIndex = (
  tracks: Track[],
  playedTrackIndexes: number[],
  currentPlayingTrackIndex: number,
  genresToPlay: string[],
  uninterestedTrackIds: string[],
  favoriteTrackIds: string[],
  isSubscribed: boolean,
  playFromFavorites: boolean,
  activitySettings: string[],
  neuralEffectSettings: string[],
  sleepCategoryGenres: boolean,
) => {
  const notAllowedIndex =
    currentPlayingTrackIndex === 0 ? undefined : currentPlayingTrackIndex;

  let index = currentPlayingTrackIndex;
  while (
    (notAllowedIndex && notAllowedIndex === index) ||
    playedTrackIndexes.includes(index) ||
    !filterTrackByUserSettings(
      index,
      genresToPlay,
      uninterestedTrackIds,
      favoriteTrackIds,
      tracks,
      isSubscribed,
      playFromFavorites,
      activitySettings,
      neuralEffectSettings,
      sleepCategoryGenres,
    )
  ) {
    index++;

    if (index >= tracks.length) {
      index = 0;
    }

    if (index === currentPlayingTrackIndex) {
      return -1;
    }
  }

  return index;
};

export const getNextTrack = (
  tracks: Track[],
  tracksQueData: TracksQueData,
  genresToPlay: string[],
  uninterestedTrackIds: string[],
  favoriteTrackIds: string[],
  isSubscribed: boolean,
  playFromFavorites: boolean,
  activitySettings: string[],
  neuralEffectSettings: string[],
  sleepCategoryGenres: boolean,
) => {
  const { currentPlayingTrackIndex, trackStartPoint, playedTrackIndexes } =
    tracksQueData;
  const isCurrentTrackTheLastOfPlayQue = trackStartPoint === 0;
  const nextTrackQueIndex =
    playedTrackIndexes.indexOf(currentPlayingTrackIndex) + 1;

  const nextTrackIndex = isCurrentTrackTheLastOfPlayQue
    ? findNextSuitableTrackIndex(
        tracks,
        playedTrackIndexes,
        currentPlayingTrackIndex,
        genresToPlay,
        uninterestedTrackIds,
        favoriteTrackIds,
        isSubscribed,
        playFromFavorites,
        activitySettings,
        neuralEffectSettings,
        sleepCategoryGenres,
      )
    : playedTrackIndexes[nextTrackQueIndex];
  const newPlayedTrackIndexes =
    trackStartPoint !== 0
      ? playedTrackIndexes
      : [...playedTrackIndexes, nextTrackIndex];

  const {
    path,
    name,
    id,
    genre,
    imageUrl,
    displayImageUrl,
    pathLinkId,
    lowNeuralPath,
    mediumNeuralPath,
    highNeuralPath,
    linkPath,
    lowNeuralLinkPath,
    mediumNeuralLinkPath,
    highNeuralLinkPath,
  } = tracks[nextTrackIndex];

  const nextTrack = {
    path,
    name,
    id,
    genre,
    imageUrl,
    displayImageUrl,
    pathLinkId,
    lowNeuralPath,
    mediumNeuralPath,
    highNeuralPath,
    linkPath,
    lowNeuralLinkPath,
    mediumNeuralLinkPath,
    highNeuralLinkPath,
  };

  const startPoint =
    trackStartPoint === 0 ? trackStartPoint : trackStartPoint - 1;
  const isLastTrack =
    findNextSuitableTrackIndex(
      tracks,
      newPlayedTrackIndexes,
      nextTrackIndex,
      genresToPlay,
      uninterestedTrackIds,
      favoriteTrackIds,
      isSubscribed,
      playFromFavorites,
      activitySettings,
      neuralEffectSettings,
      sleepCategoryGenres,
    ) === -1;

  return {
    playingTrack: nextTrack,
    tracksQueData: {
      currentPlayingTrackIndex: nextTrackIndex,
      trackStartPoint: startPoint,
      playedTrackIndexes: newPlayedTrackIndexes,
      trackQueIndex: nextTrackQueIndex,
      isFirstTrack: false,
      isLastTrack,
    },
  };
};

export const getPreviousTrack = (
  tracks: Track[],
  tracksQueData: TracksQueData,
) => {
  const { currentPlayingTrackIndex, trackStartPoint, playedTrackIndexes } =
    tracksQueData;
  const previousTrackQueIndex =
    playedTrackIndexes.indexOf(currentPlayingTrackIndex) - 1;
  const previousTrackIndex = playedTrackIndexes[previousTrackQueIndex];
  const firstTrackIndex = playedTrackIndexes[0];
  const isFirstTrack = previousTrackIndex === firstTrackIndex;
  const isLastTrack = tracks.length - 1 === previousTrackIndex;
  const {
    path,
    name,
    id,
    genre,
    imageUrl,
    displayImageUrl,
    pathLinkId,
    lowNeuralPath,
    mediumNeuralPath,
    highNeuralPath,
    linkPath,
    lowNeuralLinkPath,
    mediumNeuralLinkPath,
    highNeuralLinkPath,
  } = tracks[previousTrackIndex];

  const previousTrack = {
    path,
    name,
    id,
    genre,
    imageUrl,
    displayImageUrl,
    pathLinkId,
    lowNeuralPath,
    mediumNeuralPath,
    highNeuralPath,
    linkPath,
    lowNeuralLinkPath,
    mediumNeuralLinkPath,
    highNeuralLinkPath,
  };

  const startPoint = trackStartPoint + 1;

  return {
    playingTrack: previousTrack,
    tracksQueData: {
      currentPlayingTrackIndex: previousTrackIndex,
      trackStartPoint: startPoint,
      playedTrackIndexes,
      trackQueIndex: previousTrackQueIndex,
      isFirstTrack,
      isLastTrack,
    },
  };
};

export const addPlayerVolumeControls = () => {
  const repeatButton = document.querySelector('.rhap_volume-container');

  if (repeatButton) {
    if (repeatButton.classList.contains('rhap_volume-container--hidden')) {
      repeatButton.classList.remove('rhap_volume-container--hidden');
    }
  }
};

export const removePlayerVolumeControls = () => {
  const repeatButton = document.querySelector('.rhap_volume-container');
  if (repeatButton) {
    if (!repeatButton.classList.contains('rhap_volume-container--hidden')) {
      repeatButton.classList.add('rhap_volume-container--hidden');
    }
  }
};

export const addPlayerRepeatButton = () => {
  const repeatButton = document.querySelector('.rhap_repeat-button');

  if (repeatButton) {
    if (repeatButton.classList.contains('rhap_repeat-button--hidden')) {
      repeatButton.classList.remove('rhap_repeat-button--hidden');
    }
  }
};

export const removePlayerRepeatButton = () => {
  const repeatButton = document.querySelector('.rhap_repeat-button');
  if (repeatButton) {
    if (!repeatButton.classList.contains('rhap_repeat-button--hidden')) {
      repeatButton.classList.add('rhap_repeat-button--hidden');
    }
  }
};

export const disablePlayerPreviousButton = () => {
  const previousButton = document.querySelector('[aria-label="Previous"]');
  if (previousButton) {
    if (!previousButton.classList.contains('prev-skip-button-disabled')) {
      previousButton.classList.add('prev-skip-button-disabled');
    }
  }
};

export const enablePlayerPreviousButton = () => {
  const previousButton = document.querySelector('[aria-label="Previous"]');
  if (
    previousButton &&
    previousButton.classList.contains('prev-skip-button-disabled')
  ) {
    previousButton.classList.remove('prev-skip-button-disabled');
  }
};

export const disablePlayerNextButton = () => {
  const skipButton = document.querySelector('[aria-label="Skip"]');
  if (
    skipButton &&
    !skipButton.classList.contains('prev-skip-button-disabled')
  ) {
    skipButton.classList.add('prev-skip-button-disabled');
  }
};

export const enablePlayerNextButton = () => {
  const skipButton = document.querySelector('[aria-label="Skip"]');
  if (
    skipButton &&
    skipButton.classList.contains('prev-skip-button-disabled')
  ) {
    skipButton.classList.remove('prev-skip-button-disabled');
  }
};

export const useDebounceWithNumberState = (
  time: number,
  initialValue: number,
): [number, (v: number) => void] => {
  const [value, setValue] = useState<number>(initialValue);
  const values = useState(() => new Subject<number>())[0];

  useEffect(() => {
    const sub = values.pipe(debounceTime(time)).subscribe(setValue);
    return () => sub.unsubscribe();
  }, [time, values]);

  return [value, (v: number) => values.next(v)];
};

const customIcons = {
  loopOff: (
    <Tooltip title="Click to enable repeat.">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        className="iconify iconify--mdi"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M2 5.27L3.28 4L20 20.72L18.73 22l-3-3H7v3l-4-4l4-4v3h6.73L7 10.27V11H5V8.27zM17 13h2v4.18l-2-2zm0-8V2l4 4l-4 4V7H8.82l-2-2z"
        ></path>
      </svg>
    </Tooltip>
  ),
  loop: (
    <Tooltip title="Click to disable repeat.">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        className="iconify iconify--mdi"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M17 17H7v-3l-4 4l4 4v-3h12v-6h-2M7 7h10v3l4-4l-4-4v3H5v6h2z"
        ></path>
      </svg>
    </Tooltip>
  ),
};

export default Player;
