import { useState, useEffect, ChangeEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme, muiRootStyles } from '../styles/mui/mui-styles';
import { muiBlueTheme, muiBlueRootStyles } from '../styles/mui/mui-styles-blue';
import {
  muiWhiteTheme,
  muiWhiteRootStyles,
} from '../styles/mui/mui-styles-white';
import { Link as RouterLink } from 'react-router-dom';
import PasswordIcon from '@mui/icons-material/Password';
import Loading from './Loading';
import { useNavigate } from 'react-router-dom';
import {
  handleCheckIfPasswordResetLinkIsValid,
  setNewPasswordWithToken,
} from '../api/auth/forgot-reset-password';
import { refreshTokens } from '../api/auth/login';
import {
  inputStyles,
  inputStylesForBlueTheme,
  inputStylesForWhiteTheme,
} from './SignIn';

const defaultFormFields = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isResetTokenValid, setIsResetTokenValid] = useState(false);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { password, confirmPassword } = formFields;
  const [passwordError, setPasswordError] = useState('');
  const [passWordErrorText, setPassWordErrorText] = useState('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');
  const [messageAfterPasswordChange, setmessageAfterPasswordChange] =
    useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const token = params.get('resetToken');

  useEffect(() => {
    (async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const resetToken = params.get('resetToken');
        if (resetToken) {
          const isValid =
            await handleCheckIfPasswordResetLinkIsValid(resetToken);
          setIsResetTokenValid(isValid);
        } else {
          setIsResetTokenValid(false);
        }
        setIsDataLoaded(true);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (password !== confirmPassword) {
      setIsLoading(false);
      setPasswordError('Passwords do not match.');
      return;
    }

    if (password === '' && confirmPassword === '') {
      setIsLoading(false);
      setPasswordError(`Password can't be empty.`);
      return;
    }

    try {
      const { user } = await setNewPasswordWithToken(
        token !== null ? token : '',
        password,
      );
      const { firstHash, secondHash } = user;
      refreshTokens({ firstHash, secondHash });
      setIsLoading(false);
      setmessageAfterPasswordChange(
        'Password successfully changed, you will be automatically redirected in 3 seconds...',
      );

      setTimeout(() => {
        navigate('/');
        window.location.reload();
      }, 3000);
    } catch (error) {
      if (error.code === 'password-reset-token-expired') {
        setIsLoading(false);
        setmessageAfterPasswordChange(
          'Your password was not changed, because the password reset link has expired. Please request a new password reset link.',
        );
      } else if (error.code === 'previous-password-match') {
        setIsLoading(false);
        setPasswordError(error.message);
      } else if (error.code === 'too-many-requests') {
        setIsLoading(false);
        setPasswordError(error.message);
      } else {
        setIsLoading(false);
        setmessageAfterPasswordChange(
          'Internal issue, if the issue persists please contact support.',
        );
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordError('');
    setPassWordErrorText('');
    setConfirmPasswordErrorText('');
    setFormFields({ ...formFields, [name]: value });
  };

  if (isDataLoaded && isResetTokenValid) {
    return (
      <ThemeProvider
        theme={
          selectedTheme === 'youtube white'
            ? muiWhiteTheme
            : selectedTheme === 'dark blue'
            ? muiBlueTheme
            : muiTheme
        }
      >
        <Container component="main" maxWidth="xs">
          {/* <CssBaseline /> */}
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {!messageAfterPasswordChange && (
              <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <PasswordIcon />
              </Avatar>
            )}

            {!messageAfterPasswordChange && (
              <Typography component="h1" variant="h5">
                Set new password
              </Typography>
            )}

            {messageAfterPasswordChange && (
              <div className="reset-password-message-box">
                <p className="reset-password-message-box__paragraph">
                  {messageAfterPasswordChange}
                </p>

                <RouterLink
                  to="/"
                  className={
                    selectedTheme === 'spotify dark'
                      ? 'link--dark reset-password-message-box__paragraph'
                      : selectedTheme === 'spotify blue'
                      ? 'link--blue reset-password-message-box__paragraph'
                      : 'link--white reset-password-message-box__paragraph'
                  }
                >
                  Home
                </RouterLink>
              </div>
            )}

            {!messageAfterPasswordChange && (
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container>
                  <input hidden type="text" name="email" autoComplete="email" />
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onChange={handleChange}
                      sx={
                        selectedTheme === 'youtube white'
                          ? { ...muiWhiteRootStyles }
                          : selectedTheme === 'dark blue'
                          ? { ...muiBlueRootStyles }
                          : { ...muiRootStyles }
                      }
                      value={password}
                      helperText={
                        passwordError !== '' ? passwordError : passWordErrorText
                      }
                      error={passwordError !== '' || passWordErrorText !== ''}
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={
                        selectedTheme === 'youtube white'
                          ? { style: inputStylesForWhiteTheme }
                          : selectedTheme === 'dark blue'
                          ? { style: inputStylesForBlueTheme }
                          : {
                              style: inputStyles,
                            }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      id="confirm-password"
                      autoComplete="new-password"
                      onChange={handleChange}
                      sx={
                        selectedTheme === 'youtube white'
                          ? { ...muiWhiteRootStyles }
                          : selectedTheme === 'dark blue'
                          ? { ...muiBlueRootStyles }
                          : { ...muiRootStyles }
                      }
                      value={confirmPassword}
                      helperText={
                        passwordError !== ''
                          ? passwordError
                          : confirmPasswordErrorText
                      }
                      error={
                        passwordError !== '' || confirmPasswordErrorText !== ''
                      }
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={
                        selectedTheme === 'youtube white'
                          ? { style: inputStylesForWhiteTheme }
                          : selectedTheme === 'dark blue'
                          ? { style: inputStylesForBlueTheme }
                          : {
                              style: inputStyles,
                            }
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    color:
                      selectedTheme === 'youtube white' ? '#e7e7e7' : '#121212',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                >
                  Reset Password
                </Button>

                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <RouterLink
                      to="/login"
                      className={
                        selectedTheme === 'youtube white'
                          ? 'black-link'
                          : 'link'
                      }
                    >
                      Return to login
                    </RouterLink>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    );
  } else if (isDataLoaded && !isResetTokenValid) {
    return (
      <div className="reset-password-message-box">
        <p className="reset-password-message-box__paragraph">
          Password reset token expired
        </p>{' '}
        <RouterLink
          to="/forgot-password"
          className={
            selectedTheme === 'spotify dark'
              ? 'link--dark reset-password-message-box__paragraph'
              : selectedTheme === 'spotify blue'
              ? 'link--blue reset-password-message-box__paragraph'
              : 'link--white reset-password-message-box__paragraph'
          }
        >
          Request new password reset link
        </RouterLink>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default ResetPassword;
