import Button from '@mui/material/Button';
import { Element } from 'react-scroll';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from '../styles/mui/mui-styles';
import { muiBlueTheme } from '../styles/mui/mui-styles-blue';
import { muiWhiteTheme } from '../styles/mui/mui-styles-white';
// import ContactUsIcon from '../styles/icons/contact-us.svg';
import ContactUsIcon from '../styles/icons/contact-us-icon.svg';
import ContactUsIconWhiteTheme from '../styles/icons/contact-us-icon-white-theme.svg';
import {
  rectangleButtonStyles,
  rectangleButtonStylesWhiteTheme,
} from '../styles/mui/rectangle-button';

interface ContactProps {
  title: string;
  paragraph: string;
  isOnContactRoute: boolean;
}

const Contact = (props: ContactProps) => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const { title, paragraph, isOnContactRoute } = props;

  return (
    <section
      className={
        !isOnContactRoute
          ? 'section-contact'
          : 'section-contact section-contact--large-margin'
      }
    >
      <div className="row contact">
        <h2 className="secondary-heading">{title}</h2>
        {/* <div className="contact-image-container">
          <img
            className="contact-image-container__icon"
            src={
              selectedTheme === 'youtube white'
                ? ContactUsIconWhiteTheme
                : ContactUsIcon
            }
            alt="contact"
          />
        </div> */}
        <p className="paragraph">{paragraph}</p>

        <ThemeProvider
          theme={
            selectedTheme === 'spotify dark'
              ? muiTheme
              : selectedTheme === 'dark blue'
              ? muiBlueTheme
              : muiWhiteTheme
          }
        >
          <div className="contact__button-container">
            <Button
              type="button"
              variant="contained"
              style={
                selectedTheme === 'youtube white'
                  ? rectangleButtonStylesWhiteTheme
                  : rectangleButtonStyles
              }
            >
              <a
                className="contact__link"
                href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
              >
                {process.env.REACT_APP_CONTACT_EMAIL}
              </a>
            </Button>
          </div>
        </ThemeProvider>
      </div>
    </section>
  );
};

export default Contact;
