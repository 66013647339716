import { useLocation } from 'react-router-dom';
import React, { useState, MouseEventHandler, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularSlider from '@fseehawer/react-circular-slider';
import CircleIcon from '@mui/icons-material/Circle';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider } from '@mui/material/styles';
import InfoTooltip from '../../styles/mui/InfoTooltip';
import { muiTheme } from '../../styles/mui/mui-styles';
import { muiBlueTheme } from '../../styles/mui/mui-styles-blue';
import { muiWhiteTheme } from '../../styles/mui/mui-styles-white';
import { muiSoundscapesTheme } from '../../styles/mui/mui-styles-soundscapes';
import {
  closeIconStyles,
  closeIconStylesSoundscapes,
} from '../library/Library';
import { StoreState } from '../../store/store';
import {
  updateUserAlarmSoundSetting,
  updateUserDefaultAlarmTimerSetting,
} from '../../api/user/user';
import {
  updateUserPlayAlarmSound,
  updateUserDefaultAlarmTimer,
} from '../../reducers/userSlice';

interface TimerProps {
  timer: null;
  setTimer: React.Dispatch<React.SetStateAction<null>>;
  toggleTimerModal: MouseEventHandler<any>;
  timerTime: number;
  setTimerTime: React.Dispatch<React.SetStateAction<number>>;
}

interface Styles {
  [key: string]: string;
}

const Timer = (props: TimerProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const dispatch = useDispatch();
  const [resetKey, setResetKey] = useState(0);
  const savedDefaultAlarmTimer = useSelector(
    (state: StoreState) => state.user.defaultAlarmTimer,
  );
  const [timerSliderValue, setTimerSliderValue] = useState(
    savedDefaultAlarmTimer,
  );
  const { timer, setTimer, setTimerTime, timerTime, toggleTimerModal } = props;
  const savedPlayAlarmSoundSwitch = useSelector(
    (state: StoreState) => state.user.playAlarmSound,
  );

  const togglePlayAlarmSoundSwitch = async () => {
    dispatch(updateUserPlayAlarmSound(!savedPlayAlarmSoundSwitch));
    await updateUserAlarmSoundSetting(!savedPlayAlarmSoundSwitch);
  };

  const handleResetClick = () => {
    setResetKey((prevKey) => prevKey + 1);
  };

  const [inputDisabled, setInputDisabled] = useState(false);
  const location = useLocation();
  const sleepCategoryGenres =
    location.pathname === '/deep-sleep' ||
    location.pathname === '/guided-sleep' ||
    location.pathname === '/power-nap';
  const isOnSoundscapesPath = location.pathname === '/soundscapes';
  const startTimer = async () => {
    setTimer(
      setInterval(() => {
        setTimerTime((prevTime: number) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000) as any,
    );
    setInputDisabled(true);

    if (isFullMinute(timerTime) && savedDefaultAlarmTimer !== timerTime) {
      dispatch(updateUserDefaultAlarmTimer(timerTime));
      await updateUserDefaultAlarmTimerSetting(timerTime);
    }
  };

  const handleTimerSliderChange = (time: number) => {
    setTimerSliderValue(time);
    handleSetTimerTime(time);
  };

  const renderSliderLabelValue = () => {
    const outerDivStyle: Styles = {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      userSelect: 'none',
      zIndex: '1',
    };

    const innerDivStyle: Styles = {
      fontSize: '1rem',
    };

    const labelDivStyle: Styles = {
      fontSize: '5rem',
      position: 'relative',
      marginBottom: 'calc(1.5rem)',
    };

    const codeDivStyle: Styles = {
      fontFamily: 'sans-serif',
    };

    const spanStyle: Styles = {
      position: 'absolute',
      left: '0px',
      top: '0px',
      transform: 'translate(-100%, 0px)',
      textDecoration: 'none',
    };

    return (
      <div style={outerDivStyle}>
        <div style={innerDivStyle}> </div>
        <div style={labelDivStyle}>
          <code style={codeDivStyle}>
            <span
              // className={
              //   selectedTheme === 'youtube white'
              //     ? 'slider-label slider-label--youtube-white'
              //     : 'slider-label'
              // }
              className={
                selectedTheme === 'spotify dark' ||
                selectedTheme === 'dark blue' ||
                isOnSoundscapesPath
                  ? 'slider-label'
                  : 'slider-label slider-label--youtube-white'
              }
              // isOnSoundscapesPath
              style={spanStyle}
            ></span>
            {formatSliderTime(timerSliderValue)}
            <span
              style={{
                ...spanStyle,
                left: 'auto',
                right: '0px',
                transform: 'translate(100%, 0px)',
              }}
            ></span>
          </code>
        </div>
      </div>
    );
  };

  const PauseTimer = () => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
      setInputDisabled(false);
    }
  };

  const resetTimer = () => {
    handleResetClick();
    PauseTimer();
    setTimerTime(savedDefaultAlarmTimer);
    handleTimerSliderChange(savedDefaultAlarmTimer / 60);
    // setTimerSliderValue(savedDefaultAlarmTimer / 60);
    setInputDisabled(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!timer) {
      const inputTime = parseInt(event.target.value, 10);
      const isValidInput = !isNaN(inputTime) && inputTime >= 0;
      if (isValidInput) {
        setTimerTime(isNaN(inputTime) ? 0 : inputTime * 60);
      }
    }
  };

  const formatRemainingTime = (minutes: number) => {
    const hours = Math.floor(minutes / 3600)
      .toString()
      .padStart(2, '0');
    const mins = Math.floor((minutes % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const secs = (minutes % 60).toString().padStart(2, '0');
    return `${hours}:${mins}:${secs}`;
  };

  useEffect(() => {
    if (timerTime === 0) {
      PauseTimer();
    }
  }, [timerTime]);

  const handleSetTimerTime = (time: number) => {
    if (!timer) {
      setTimerTime(time * 60);
    }
  };

  const tooltipText = isOnSoundscapesPath
    ? 'After the timer ends the soundscape will stop playing and there will be a bell alarm sound (depending on your bell alarm setting).'
    : !sleepCategoryGenres
    ? 'After the timer ends the music will stop playing and there will be a bell alarm sound (depending on your bell alarm setting).'
    : 'After the timer ends the music will stop playing.';

  return (
    <div className="timer">
      <div className="library-modal-close-icon-container-wrapper">
        <div className="library-modal-close-icon-container">
          <CloseIcon
            onClick={toggleTimerModal}
            style={
              isOnSoundscapesPath ? closeIconStylesSoundscapes : closeIconStyles
            }
          />
        </div>
      </div>

      <ThemeProvider
        theme={
          isOnSoundscapesPath
            ? muiSoundscapesTheme
            : selectedTheme === 'spotify dark'
            ? muiTheme
            : selectedTheme === 'dark blue'
            ? muiBlueTheme
            : muiWhiteTheme
        }
      >
        <div className="timer-title-tooltip-container">
          <h2
            className={
              isOnSoundscapesPath
                ? 'timer-title-tooltip-container__title timer-title-tooltip-container__title--soundscapes'
                : selectedTheme === 'spotify dark'
                ? 'timer-title-tooltip-container__title timer-title-tooltip-container__title--dark'
                : selectedTheme === 'dark blue'
                ? 'timer-title-tooltip-container__title timer-title-tooltip-container__title--blue'
                : 'timer-title-tooltip-container__title timer-title-tooltip-container__title--white'
            }
          >
            Shut-Off Timer
          </h2>
          <InfoTooltip popoverText={tooltipText} />
        </div>
        <div
          className={
            isOnSoundscapesPath
              ? 'slider-container slider-container--soundscapes'
              : 'slider-container'
          }
        >
          <CircularSlider
            width={240}
            key={resetKey}
            onChange={handleTimerSliderChange}
            knobDraggable={!timer}
            hideKnob={timer !== null}
            trackColor={
              isOnSoundscapesPath || selectedTheme === 'spotify dark'
                ? '#5e5e5e'
                : selectedTheme === 'dark blue'
                ? '#5e5e5e'
                : '#C0C0C0'
            }
            progressColorFrom={
              isOnSoundscapesPath || selectedTheme === 'spotify dark'
                ? '#ffffff'
                : selectedTheme === 'dark blue'
                ? '#e7e7e7'
                : '#121212'
            }
            progressColorTo={
              isOnSoundscapesPath || selectedTheme === 'spotify dark'
                ? '#ffffff'
                : selectedTheme === 'dark blue'
                ? '#e7e7e7'
                : '#121212'
            }
            min={0}
            max={240}
            labelColor={
              isOnSoundscapesPath || selectedTheme === 'spotify dark'
                ? '#ffffff'
                : selectedTheme === 'dark blue'
                ? '#e7e7e7'
                : '#121212'
            }
            valueFontSize={'7rem'}
            useMouseAdditionalToTouch={true}
            hideLabelValue={true}
            label={' '}
            renderLabelValue={renderSliderLabelValue()}
            dataIndex={savedDefaultAlarmTimer / 60}
            knobColor={
              isOnSoundscapesPath || selectedTheme === 'spotify dark'
                ? '#ffffff'
                : selectedTheme === 'dark blue'
                ? '#e7e7e7'
                : '#121212'
            }
          >
            <CircleIcon x="9" y="9" width="18px" height="18px" />
          </CircularSlider>
        </div>
        <div
          className={
            isOnSoundscapesPath
              ? 'time-remaining-container time-remaining-container--soundscapes'
              : 'time-remaining-container'
          }
        >
          <p className="time-remaining-container__paragraph">Time remaining</p>
          <p className="time-remaining-container__paragraph">
            {formatRemainingTime(timerTime)}
          </p>
        </div>
        {!sleepCategoryGenres && (
          <div className="alarm-setting-container">
            <Switch
              id="playAlarmSoundSwitch"
              color="secondary"
              checked={savedPlayAlarmSoundSwitch}
              onChange={togglePlayAlarmSoundSwitch}
              disableRipple
            />
            <label
              htmlFor="playAlarmSoundSwitch"
              className={
                isOnSoundscapesPath
                  ? 'alarm-setting-container__label alarm-setting-container__label--soundscapes'
                  : 'alarm-setting-container__label'
              }
            >
              Bell alarm
            </label>
          </div>
        )}

        <div className="timer-action-buttons-container">
          <div className="timer-reset-button-container">
            {isOnSoundscapesPath ? (
              <Button
                onClick={resetTimer}
                style={timerActionButtonStylesSoundscapes}
                variant="contained"
              >
                Reset
              </Button>
            ) : selectedTheme === 'youtube white' ? (
              <Button
                onClick={resetTimer}
                style={timerActionButtonStylesWhiteTheme}
                variant="contained"
              >
                Reset
              </Button>
            ) : (
              <Button
                onClick={resetTimer}
                style={timerActionButtonStyles}
                variant="contained"
              >
                Reset
              </Button>
            )}
          </div>

          {isOnSoundscapesPath && (
            <div className="timer-button-container">
              {!timer ? (
                <Button
                  onClick={startTimer}
                  style={
                    inputDisabled || timerTime === 0
                      ? timerActionButtonDisabledStylesSoundscapes
                      : timerActionButtonStylesSoundscapes
                  }
                  variant="contained"
                  disabled={inputDisabled || timerTime === 0}
                >
                  Start
                </Button>
              ) : (
                <Button
                  onClick={PauseTimer}
                  style={timerActionButtonStylesSoundscapes}
                  variant="contained"
                >
                  Pause
                </Button>
              )}
            </div>
          )}

          {!isOnSoundscapesPath && selectedTheme === 'youtube white' && (
            <div className="timer-button-container">
              {!timer ? (
                <Button
                  onClick={startTimer}
                  style={
                    inputDisabled || timerTime === 0
                      ? timerActionButtonDisabledStylesWhiteTheme
                      : timerActionButtonStylesWhiteTheme
                  }
                  variant="contained"
                  disabled={inputDisabled || timerTime === 0}
                >
                  Start
                </Button>
              ) : (
                <Button
                  onClick={PauseTimer}
                  style={timerActionButtonStylesWhiteTheme}
                  variant="contained"
                >
                  Pause
                </Button>
              )}
            </div>
          )}

          {!isOnSoundscapesPath && selectedTheme === 'spotify dark' && (
            <div className="timer-button-container">
              {!timer ? (
                <Button
                  onClick={startTimer}
                  style={
                    inputDisabled || timerTime === 0
                      ? timerActionButtonDisabledStyles
                      : timerActionButtonStyles
                  }
                  variant="contained"
                  disabled={inputDisabled || timerTime === 0}
                >
                  Start
                </Button>
              ) : (
                <Button
                  onClick={PauseTimer}
                  style={timerActionButtonStyles}
                  variant="contained"
                >
                  Pause
                </Button>
              )}
            </div>
          )}

          {!isOnSoundscapesPath && selectedTheme === 'dark blue' && (
            <div className="timer-button-container">
              {!timer ? (
                <Button
                  onClick={startTimer}
                  style={
                    inputDisabled || timerTime === 0
                      ? timerActionButtonDisabledStylesDarkBlueTheme
                      : timerActionButtonStyles
                  }
                  variant="contained"
                  disabled={inputDisabled || timerTime === 0}
                >
                  Start
                </Button>
              ) : (
                <Button
                  onClick={PauseTimer}
                  style={timerActionButtonStyles}
                  variant="contained"
                >
                  Pause
                </Button>
              )}
            </div>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
};

const timerActionButtonStyles = {
  color: '#121212',
  fontSize: '1.4rem',
  fontWeight: 500,
  width: '12rem',
};

const timerActionButtonStylesSoundscapes = {
  color: '#e7e7e7',
  backgroundColor: '#262626',
  fontSize: '1.4rem',
  fontWeight: 500,
  width: '12rem',
};

const timerActionButtonDisabledStylesSoundscapes = {
  backgroundColor: '#5b5b5b',
  color: '#e7e7e7',
  fontSize: '1.4rem',
  fontWeight: 500,
  width: '12rem',
};

const timerActionButtonDisabledStyles = {
  backgroundColor: '#2f6e46',
  color: '#121212',
  fontSize: '1.4rem',
  fontWeight: 500,
  width: '12rem',
};

const timerActionButtonDisabledStylesDarkBlueTheme = {
  backgroundColor: '#825611',
  color: '#121212',
  fontSize: '1.4rem',
  fontWeight: 500,
  width: '12rem',
};

const timerActionButtonStylesWhiteTheme = {
  color: '#e7e7e7',
  fontSize: '1.4rem',
  fontWeight: 500,
  width: '12rem',
};

const timerActionButtonDisabledStylesWhiteTheme = {
  backgroundColor: '#707070',
  color: '#e7e7e7',
  fontSize: '1.4rem',
  fontWeight: 500,
  width: '12rem',
};

const isFullMinute = (seconds: number): boolean => {
  const minutes = seconds / 60;
  return Number.isInteger(minutes);
};

const formatSliderTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0');
  const mins = (minutes % 60).toString().padStart(2, '0');
  return `${hours}:${mins}`;
};

export default Timer;
