import { useState, CSSProperties } from 'react';
import Popover from '@mui/material/Popover';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { StoreState } from '../../store/store';

interface InfoTooltipProps {
  popoverText: string;
}

const InfoTooltip = (props: InfoTooltipProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  // const selectedTheme = localStorage.getItem('lawfmtheme');
  const location = useLocation();

  const isOnSoundscapesPath = location.pathname === '/soundscapes';
  const { popoverText } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        onClick={handleInfoClick}
        style={
          isOnSoundscapesPath
            ? soundscapesColor
            : selectedTheme === 'spotify dark'
            ? blackThemeColor
            : selectedTheme === 'dark blue'
            ? blueThemeColor
            : whiteThemeColor
        }
        disableRipple
      >
        <InfoIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={popoverStyle}>{popoverText}</div>
      </Popover>
    </div>
  );
};

const popoverStyle: CSSProperties = {
  backgroundColor: '#121212',
  color: '#e7e7e7',
  padding: '1rem',
  fontSize: '1.6rem',
  borderRadius: '1.2rem',
  maxWidth: '40rem',
};

const blackThemeColor = {
  color: '#1ed760',
};

const blueThemeColor = {
  color: '#766dff',
};

const whiteThemeColor = {
  color: '#2f2f2f',
};

const soundscapesColor = {
  color: '#ffffff',
};

export default InfoTooltip;
