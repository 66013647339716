import Genres from './Genres';
import { PlayerRefType } from '../Player';

interface GenresTabProps {
  playerRef: PlayerRefType;
}

const GenresTab = (props: GenresTabProps) => {
  const { playerRef } = props;
  return (
    <div className="genres-tab">
      <Genres playerRef={playerRef} />
    </div>
  );
};

export default GenresTab;
