import * as React from 'react';
import { useState, ChangeEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { muiTheme, muiRootStyles } from '../styles/mui/mui-styles';
import { muiBlueTheme, muiBlueRootStyles } from '../styles/mui/mui-styles-blue';
import Loading from './Loading';
import {
  muiWhiteTheme,
  muiWhiteRootStyles,
} from '../styles/mui/mui-styles-white';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  signInWithEmailandPassword,
  refreshTokens,
  isUserLoggedIn,
} from '../api/auth/login';

const defaultFormFields = {
  email: '',
  password: '',
};

export default function SignIn() {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const isLoggedIn = isUserLoggedIn();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const [emailErrorText, setEmailErrorText] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEmailErrorText('');
    setPasswordErrorText('');
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (email === '') {
      setIsLoading(false);
      setEmailErrorText('Please enter email.');
      return;
    }

    if (password === '') {
      setIsLoading(false);
      setPasswordErrorText('Please enter password.');
      return;
    }

    try {
      const { user } = await signInWithEmailandPassword(email, password);
      const { firstHash, secondHash } = user;
      refreshTokens({ firstHash, secondHash });
      resetFormFields();

      if (locationState) {
        navigate(`${locationState.from.pathname}${locationState.from.search}`);
        window.location.reload();
      } else {
        navigate('/');
        window.location.reload();
      }
    } catch (error) {
      if (error.code === 'wrong-password') {
        setIsLoading(false);
        setPasswordErrorText(error.message);
      } else if (error.code === 'user-not-found') {
        setIsLoading(false);
        setEmailErrorText(error.message);
      } else if (error.code === 'invalid-email') {
        setIsLoading(false);
        setEmailErrorText(error.message);
      } else if (error.code === 'too-many-requests') {
        setIsLoading(false);
        setPasswordErrorText(error.message);
      } else if (error instanceof TypeError) {
        setIsLoading(false);
        setPasswordErrorText(
          'Too many requests from this IP, please try again in 15 minutes.',
        );
      } else {
        setIsLoading(false);
        setPasswordErrorText(
          'Interal error, if the issue persists, please contact support.',
        );
      }
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  } else if (isLoading) {
    return <Loading />;
  } else {
    return (
      <ThemeProvider
        theme={
          selectedTheme === 'youtube white'
            ? muiWhiteTheme
            : selectedTheme === 'dark blue'
            ? muiBlueTheme
            : muiTheme
        }
      >
        <Container component="main" maxWidth="xs">
          {/* <CssBaseline /> */}
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              <TextField
                type="email"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={handleChange}
                autoComplete="email"
                autoFocus
                variant="outlined"
                helperText={emailErrorText}
                error={emailErrorText !== ''}
                sx={
                  selectedTheme === 'youtube white'
                    ? { ...muiWhiteRootStyles }
                    : selectedTheme === 'dark blue'
                    ? { ...muiBlueRootStyles }
                    : { ...muiRootStyles }
                }
                inputProps={{ style: { fontSize: 16 } }}
                InputLabelProps={
                  selectedTheme === 'youtube white'
                    ? { style: inputStylesForWhiteTheme }
                    : selectedTheme === 'dark blue'
                    ? { style: inputStylesForBlueTheme }
                    : {
                        style: inputStyles,
                      }
                }
                FormHelperTextProps={{
                  sx: {
                    style: { fontSize: '30px' },
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handleChange}
                value={password}
                autoComplete="current-password"
                helperText={passwordErrorText}
                error={passwordErrorText !== ''}
                sx={
                  selectedTheme === 'youtube white'
                    ? { ...muiWhiteRootStyles }
                    : selectedTheme === 'dark blue'
                    ? { ...muiBlueRootStyles }
                    : { ...muiRootStyles }
                }
                inputProps={{ style: { fontSize: 16 } }}
                InputLabelProps={
                  selectedTheme === 'youtube white'
                    ? { style: inputStylesForWhiteTheme }
                    : selectedTheme === 'dark blue'
                    ? { style: inputStylesForBlueTheme }
                    : {
                        style: inputStyles,
                      }
                }
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color:
                    selectedTheme === 'youtube white' ? '#e7e7e7' : '#121212',
                  fontSize: '12px',
                  fontWeight: '500',
                }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <RouterLink
                    to="/forgot-password"
                    className={
                      selectedTheme === 'youtube white' ? 'black-link' : 'link'
                    }
                  >
                    Forgot password?
                  </RouterLink>
                </Grid>
                <Grid item>
                  <RouterLink
                    to="/signup"
                    className={
                      selectedTheme === 'youtube white' ? 'black-link' : 'link'
                    }
                  >
                    Don't have an account? Sign Up.
                  </RouterLink>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

export const inputStyles = {
  fontSize: 16,
  paddingRight: 10,
  backgroundColor: '#121212',
  size: 'small',
  focused: true,
};

export const inputStylesForBlueTheme = {
  fontSize: 16,
  paddingRight: 10,
  backgroundColor: 'rgb(25, 23, 54)',
  size: 'small',
  focused: true,
};

export const inputStylesForWhiteTheme = {
  fontSize: 16,
  paddingRight: 10,
  backgroundColor: '#ffffff',
  color: '#2f2f2f',
  size: 'small',
  focused: true,
};
