import { useLocation, Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { isUserLoggedIn } from '../api/auth/login';
export const categoryPaths = [
  '/focus',
  '/soundscapes',
  '/deep-sleep',
  '/guided-sleep',
  '/power-nap',
  '/welcome',
  '/welcome/',
];

const Footer = () => {
  const isLoggedIn = isUserLoggedIn();
  const location = useLocation();
  const isOnPlayerPath = categoryPaths.includes(
    location.pathname.toLowerCase(),
  );

  const scrollTo = (sectionId: string) => {
    scroller.scrollTo(sectionId, {
      smooth: false,
      offset: -50,
    });
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className={!isOnPlayerPath ? 'footer' : `footer--hidden`}>
      <div className="footer-center-content">
        <h3 className="footer-center-content__heading">Resources</h3>
        <ul className="footer-center-content-list">
          <Link className="footer-center-content-list__link" to="/">
            <li className="footer-center-content-list__item">Home</li>
          </Link>
          {/* <Link
            className="footer-center-content-list__link"
            to="/#section-about"
            onClick={() => {
              if (isOnHomePage) {
                scrollTo('section-about');
              }
            }}
          >
            <li className="footer-center-content-list__item">About</li>
          </Link> */}

          <Link className="footer-center-content-list__link" to="/about">
            <li className="footer-center-content-list__item">About</li>
          </Link>

          {isLoggedIn ? (
            <Link
              className="footer-center-content-list__link"
              to="/premium?plan=all-plans"
            >
              <li className="footer-center-content-list__item">Pricing</li>
            </Link>
          ) : (
            <Link className="footer-center-content-list__link" to="/pricing">
              <li className="footer-center-content-list__item">Pricing</li>
            </Link>
          )}

          <Link className="footer-center-content-list__link" to="/dashboard">
            <li className="footer-center-content-list__item">Dashboard</li>
          </Link>
          <Link className="footer-center-content-list__link" to="/contact">
            <li className="footer-center-content-list__item">Contact</li>
          </Link>
        </ul>

        <p className="footer-center-content__copyright">
          &copy;listenatworkfm.com{' '}
          <span className="footer-center-content__copyright-span">
            {getYear()}
          </span>{' '}
          All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
