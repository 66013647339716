import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { categoriesInfo, sleepCategoriesInfo } from '../components/Categories';
import CategoryView from '../components/CategoryView';
import Soundscapes from '../components/Soundscapes';
import Header from '../components/Header';
import NotFoundPage from '../components/NotFoundPage';
import ListenAtWorkApp from '../components/ListenAtWorkApp';
import LoginPage from '../components/LoginPage';
import SignUp from '../components/SignUp';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';
import DashBoard from '../components/dashboard/DashBoard';
import Premium from '../components/Premium';
import SubscribeComplete from '../components/SubscribeComplete';
import Categories from '../components/Categories';
import VerifyEmailPage from '../components/VerifyEmailPage';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import About from '../components/About';
import Pricing from '../components/Pricing';
import Welcome from '../components/Welcome';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <div className="main-container">
        <Header />
        <Routes>
          <Route path="/" element={<ListenAtWorkApp />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-new-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/soundscapes" element={<Soundscapes />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/welcome" element={<Welcome />} />

          <Route path="/verify-email" element={<VerifyEmailPage />} />
          <Route path="/premium" element={<Premium />} />
          <Route
            path="/contact"
            element={
              <Contact
                title="Contact"
                paragraph="If you have any questions, please contact us at"
                isOnContactRoute={true}
              />
            }
          />
          <Route path="/welcome-to-premium" element={<SubscribeComplete />} />
          <Route
            path="/focus"
            element={<CategoryView title={categoriesInfo.focus.title} />}
          />

          <Route
            path="/deep-sleep"
            element={
              <CategoryView title={sleepCategoriesInfo.deepSleep.title} />
            }
          />

          <Route
            path="/guided-sleep"
            element={
              <CategoryView title={sleepCategoriesInfo.guidedSleep.title} />
            }
          />

          <Route
            path="/power-nap"
            element={
              <CategoryView title={sleepCategoriesInfo.powerNap.title} />
            }
          />

          <Route path="/categories" element={<Categories />} />
          <Route path="/sleep-categories" element={<Categories />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
