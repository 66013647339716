import { useRef, useEffect, useState } from 'react';
import { MouseEventHandler } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { PlayerRefType } from '../Player';
import Library from './Library';
import { StoreState } from '../../store/store';
import ModalBlur from '../../styles/icons/modal-blur.svg';

Modal.setAppElement('#root');

interface LibraryModalProps {
  isLibraryModalOpen: boolean;
  toggleLibraryModal: MouseEventHandler<SVGSVGElement>;
  playerRef: PlayerRefType;
}

const LibraryModal = (props: LibraryModalProps) => {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    preloadBlurSvg(ModalBlur, () => {
      setIsImageLoaded(true);
    });
  }, []);

  const customStyles = {
    content: {
      backgroundImage: isImageLoaded ? `url(${ModalBlur})` : 'none',
    },
  };

  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const { isLibraryModalOpen, toggleLibraryModal, playerRef } = props;
  return (
    <Modal
      style={displayTheme ? customStyles : {}}
      isOpen={isLibraryModalOpen}
      onRequestClose={toggleLibraryModal}
      className={
        displayTheme
          ? 'modal modal--general'
          : selectedTheme === 'spotify dark'
          ? 'modal modal--dark'
          : selectedTheme === 'dark blue'
          ? 'modal modal--blue'
          : 'modal modal--white'
      }
      overlayClassName="modal-overlay"
    >
      <div className="modal-outer-container" ref={modalContentRef}>
        <Library
          modalContentRef={modalContentRef}
          toggleLibraryModal={toggleLibraryModal}
          playerRef={playerRef}
        />
      </div>
    </Modal>
  );
};

const preloadBlurSvg = (url: string, onLoadCallback: any) => {
  const img = new Image();
  img.src = url;
  img.onload = onLoadCallback;
};

export default LibraryModal;
