import { Padding } from "@mui/icons-material";

export const rectangleButtonStyles = {
  color: '#121212',
  fontSize: '1.4rem',
  fontWeight: 500,
};

export const rectangleButtonStylesWhiteTheme = {
  color: '#e7e7e7',
  fontSize: '1.4rem',
  fontWeight: 500,
};
