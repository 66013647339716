import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Hero from './Hero';
import Categories from './Categories';
import About from './About';
import Pricing from './Pricing';
import Contact from './Contact';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import { isUserLoggedIn } from '../api/auth/login';

const ListenAtWorkApp = () => {
  const fragmentIdentifier = window.location.hash;

  // const isLoggedIn = isUserLoggedIn();
  // const isOnFirstPageOfNavigation = useSelector(
  //   (state) => state.user.isOnFirstPageOfNavigation,
  // );

  // if (isLoggedIn && isOnFirstPageOfNavigation) {
  //  return <Navigate to="/categories"/>
  // } else {

  useEffect(() => {
    // Check if the URL contains a fragment identifier (e.g., /#section-contact)

    if (fragmentIdentifier) {
      // Remove the '#' symbol to get the section name (e.g., 'section-contact')
      const sectionName = fragmentIdentifier.substring(1);

      // Scroll to the section with the corresponding name
      const element = document.getElementById(sectionName);

      if (element) {
        element.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [fragmentIdentifier]);

  return (
    <div className="app">
      <Hero />
      <Categories />
      {/* <About /> */}
      {/* <Pricing /> */}
      <FrequentlyAskedQuestions />
      {/* <Contact
        title="Contact"
        paragraph="Have any suggestions, issues?"
        isOnContactRoute={false}
      /> */}
    </div>
  );
};

export default ListenAtWorkApp;
