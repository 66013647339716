import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import 'react-h5-audio-player/lib/styles.css';
import 'react-dropdown/style.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './styles/styles.scss';
import { ThemeProvider } from './components/ThemeContext';
import Maintenance from './components/Maintenance';
import AppRouter from './routers/AppRouter';

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

if (isMaintenanceMode) {
  root.render(<Maintenance />);
} else {
  root.render(
    <Provider store={store}>
      <ThemeProvider>
        <AppRouter />
      </ThemeProvider>
    </Provider>,
  );
}

// root.render(
//   <Provider store={store}>
//     <AppRouter />
//   </Provider>,
// );

reportWebVitals();
