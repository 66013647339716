import { NavLink, Navigate, useLocation } from 'react-router-dom';
import { isUserLoggedIn } from '../api/auth/login';

const SubscribeComplete = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const isLoggedIn = isUserLoggedIn();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else {
    return (
      <div className="row subscribe-complete">
        <h1 className="subscribe-complete__heading secondary-heading">
          Thank you for choosing the premium plan
        </h1>

        <p className="subscribe-complete__paragraph paragraph">
          If you have any questions, need help or have any suggestions feel free
          to contact us at {process.env.REACT_APP_CONTACT_EMAIL}
        </p>
        <NavLink
          to="/categories"
          className={
            selectedTheme === 'spotify dark'
              ? 'link--dark'
              : selectedTheme === 'spotify blue'
              ? 'link--blue'
              : 'link--white'
          }
        >
          <p className="paragraph">Category selection</p>
        </NavLink>
      </div>
    );
  }
};

export default SubscribeComplete;
