import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Category from './Category';
import DeepWorkIcon from '../styles/icons/deep-work.svg';
import StudyIcon from '../styles/icons/study.svg';
import CreativityIcon from '../styles/icons/creativity.svg';
import DeepSleepIcon from '../styles/icons/deep-sleep.svg';
import PowerNapIcon from '../styles/icons/power-nap.svg';
import SoundscapesIcon from '../styles/icons/soundscapes.svg';

import FocusIcon from '../styles/icons/focus-icon.svg';
import SoundscapeIcon from '../styles/icons/soundscape-icon.svg';
import SleepIcon from '../styles/icons/sleep-icon.svg';
import SoundscapeIconWhiteTheme from '../styles/icons/soundscapes-icon-white-theme.svg';
import FocusIconWhiteTheme from '../styles/icons/focus-icon-white-theme.svg';
import SleepIconWhiteTheme from '../styles/icons/sleep-icon-white-theme.svg';

export const categoriesInfo = {
  focus: {
    title: 'Focus',
    paragraph:
      'Your hub for heightened concentration and creative inspiration.',
    // icon: FocusIcon,
    icon: FocusIcon,
    whiteThemeIcon: FocusIconWhiteTheme,
    path: '/focus',
  },
  soundscapes: {
    title: 'Soundscapes',
    paragraph:
      'Soundscapes to help you focus, relax, and sleep that adapt in real-time.',
    // icon: SoundscapeIcon,
    // icon: 'https://cdn3d.iconscout.com/3d/premium/preview/listening-to-music-emoji-8835090-7151065.png?f=webp&h=700',
    icon: SoundscapeIcon,
    whiteThemeIcon: SoundscapeIconWhiteTheme,
    path: '/soundscapes',
  },
  deepSleep: {
    title: 'Sleep',
    paragraph:
      'Soothing sounds to guide you into a peaceful and rejuvenating sleep.',
    // icon: SleepIcon,
    icon: SleepIcon,
    whiteThemeIcon: SleepIconWhiteTheme,
    path: '/sleep-categories',
  },
};

export const sleepCategoriesInfo = {
  deepSleep: {
    title: 'Deep Sleep',
    paragraph: 'Deep sleep.',
    path: '/deep-sleep',
  },
  guidedSleep: {
    title: 'Guided Sleep',
    paragraph: 'Guided sleep.',
    path: '/guided-sleep',
  },
  powerNap: {
    title: 'Power Nap',
    paragraph: 'Power nap.',
    path: '/power-nap',
  },
};

const Categories = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const location = useLocation();
  const navigate = useNavigate();
  const isOnCategoriesSelectionRoute = location.pathname === '/categories';
  const isOnSleepCategoriesSelectionRoute =
    location.pathname === '/sleep-categories';

  if (isOnCategoriesSelectionRoute) {
    return (
      <div className="categories-center">
        <section className="section-categories">
          <div className="categories-container row">
            <h2 className="categories-container__heading secondary-heading">
              Categories
            </h2>

            <div className="col-1-of-3">
              <div
                onClick={() => navigate(categoriesInfo.focus.path)}
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box category-box--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box category-box--white'
                    : 'category-box category-box--dark'
                }
              >
                <Category
                  title={categoriesInfo.focus.title}
                  icon={
                    selectedTheme === 'youtube white'
                      ? categoriesInfo.focus.whiteThemeIcon
                      : categoriesInfo.focus.icon
                  }
                  // icon={categoriesInfo.focus.icon}
                />
              </div>
            </div>
            <div className="col-1-of-3">
              <div
                onClick={() => navigate(categoriesInfo.soundscapes.path)}
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box category-box--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box category-box--white'
                    : 'category-box category-box--dark'
                }
              >
                <Category
                  title={categoriesInfo.soundscapes.title}
                  icon={
                    selectedTheme === 'youtube white'
                      ? categoriesInfo.soundscapes.whiteThemeIcon
                      : categoriesInfo.soundscapes.icon
                  }
                  // icon={categoriesInfo.soundscapes.icon}
                />
              </div>
            </div>
            <div className="col-1-of-3">
              <div
                onClick={() => navigate(categoriesInfo.deepSleep.path)}
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box category-box--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box category-box--white'
                    : 'category-box category-box--dark'
                }
              >
                <Category
                  title={categoriesInfo.deepSleep.title}
                  icon={
                    selectedTheme === 'youtube white'
                      ? categoriesInfo.deepSleep.whiteThemeIcon
                      : categoriesInfo.deepSleep.icon
                  }
                  // icon={categoriesInfo.deepSleep.icon}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else if (isOnSleepCategoriesSelectionRoute) {
    return (
      <div className="categories-center">
        <section className="section-categories">
          <div className="categories-container row">
            <h2 className="categories-container__heading secondary-heading">
              Sleep Categories
            </h2>

            <div className="col-1-of-3">
              <div
                onClick={() => navigate(sleepCategoriesInfo.deepSleep.path)}
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box category-box--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box category-box--white'
                    : 'category-box category-box--dark'
                }
              >
                <Category
                  title={sleepCategoriesInfo.deepSleep.title}
                  icon={
                    selectedTheme === 'youtube white'
                      ? categoriesInfo.focus.whiteThemeIcon
                      : categoriesInfo.focus.icon
                  }
                  // icon={categoriesInfo.focus.icon}
                />
              </div>
            </div>
            <div className="col-1-of-3">
              <div
                onClick={() => navigate(sleepCategoriesInfo.guidedSleep.path)}
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box category-box--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box category-box--white'
                    : 'category-box category-box--dark'
                }
              >
                <Category
                  title={sleepCategoriesInfo.guidedSleep.title}
                  icon={
                    selectedTheme === 'youtube white'
                      ? categoriesInfo.soundscapes.whiteThemeIcon
                      : categoriesInfo.soundscapes.icon
                  }
                  // icon={categoriesInfo.soundscapes.icon}
                />
              </div>
            </div>
            <div className="col-1-of-3">
              <div
                onClick={() => navigate(sleepCategoriesInfo.powerNap.path)}
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box category-box--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box category-box--white'
                    : 'category-box category-box--dark'
                }
              >
                <Category
                  title={sleepCategoriesInfo.powerNap.title}
                  icon={
                    selectedTheme === 'youtube white'
                      ? categoriesInfo.deepSleep.whiteThemeIcon
                      : categoriesInfo.deepSleep.icon
                  }
                  // icon={categoriesInfo.deepSleep.icon}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <section className="section-categories">
        <div className="categories-container row">
          <h2 className="categories-container__heading secondary-heading">
            Categories
          </h2>
          {/* <p className="categories-container__paragraph paragraph">
            Choose the suitable category based on your activity
          </p> */}

          <div className="col-1-of-3">
            <div
              onClick={() => navigate(categoriesInfo.focus.path)}
              className={
                selectedTheme === 'dark blue'
                  ? 'category-box category-box--blue category-box--with-description-min-height'
                  : selectedTheme === 'youtube white'
                  ? 'category-box category-box--white category-box--with-description-min-height'
                  : 'category-box category-box--dark category-box--with-description-min-height'
              }
            >
              <div
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box-inner category-box-inner--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box-inner category-box-inner--white'
                    : 'category-box-inner category-box-inner--dark'
                }
              >
                <div className="category-box-front">
                  <Category
                    title={categoriesInfo.focus.title}
                    icon={
                      selectedTheme === 'youtube white'
                        ? categoriesInfo.focus.whiteThemeIcon
                        : categoriesInfo.focus.icon
                    }
                    // icon={categoriesInfo.focus.icon}
                  />
                </div>

                <div className="category-box-back">
                  <p className="category-box-back__paragraph">
                    {categoriesInfo.focus.paragraph}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1-of-3">
            <div
              onClick={() => navigate(categoriesInfo.soundscapes.path)}
              className={
                selectedTheme === 'dark blue'
                  ? 'category-box category-box--blue category-box--with-description-min-height'
                  : selectedTheme === 'youtube white'
                  ? 'category-box category-box--white category-box--with-description-min-height'
                  : 'category-box category-box--dark category-box--with-description-min-height'
              }
            >
              <div
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box-inner category-box-inner--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box-inner category-box-inner--white'
                    : 'category-box-inner category-box-inner--dark'
                }
              >
                <div className="category-box-front">
                  <Category
                    title={categoriesInfo.soundscapes.title}
                    icon={
                      selectedTheme === 'youtube white'
                        ? categoriesInfo.soundscapes.whiteThemeIcon
                        : categoriesInfo.soundscapes.icon
                    }
                    // icon={categoriesInfo.soundscapes.icon}
                  />
                </div>
                <div className="category-box-back">
                  <p className="category-box-back__paragraph paragraph">
                    {categoriesInfo.soundscapes.paragraph}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1-of-3">
            <div
              onClick={() => navigate(categoriesInfo.deepSleep.path)}
              className={
                selectedTheme === 'dark blue'
                  ? 'category-box category-box--blue category-box--with-description-min-height'
                  : selectedTheme === 'youtube white'
                  ? 'category-box category-box--white category-box--with-description-min-height'
                  : 'category-box category-box--dark category-box--with-description-min-height'
              }
            >
              <div
                className={
                  selectedTheme === 'dark blue'
                    ? 'category-box-inner category-box-inner--blue'
                    : selectedTheme === 'youtube white'
                    ? 'category-box-inner category-box-inner--white'
                    : 'category-box-inner category-box-inner--dark'
                }
              >
                <div className="category-box-front">
                  <Category
                    title={categoriesInfo.deepSleep.title}
                    icon={
                      selectedTheme === 'youtube white'
                        ? categoriesInfo.deepSleep.whiteThemeIcon
                        : categoriesInfo.deepSleep.icon
                    }
                    // icon={categoriesInfo.deepSleep.icon}
                  />
                </div>
                <div className="category-box-back">
                  <p className="category-box-back__paragraph paragraph">
                    {categoriesInfo.deepSleep.paragraph}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Categories;
