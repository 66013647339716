import { useEffect, useState } from 'react';
import { Navigate, useLocation, Link } from 'react-router-dom';
import { fetchUserDashboardData } from '../../api/user/user';
import Loading from '../Loading';
import { isUserLoggedIn, signUserOut } from '../../api/auth/login';
// import DashboardIcon from '../../styles/icons/dashboard.svg';
// import DashboardIcon from '../../styles/icons/user-icon.svg';
// import DashboardIconWhiteTheme from '../../styles/icons/user-icon-white-theme.svg';
import DashboardIcon from '../../styles/icons/user-dashboard.svg';

const DashBoard = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscribedTillDate, setSubscribedTillDate] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const isLoggedIn = isUserLoggedIn();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        if (!isLoggedIn) {
          return;
        }
        const dashboardData = await fetchUserDashboardData();
        const { email, subscribed, subscribedTillDate } = dashboardData;

        setEmail(email);
        setIsSubscribed(subscribed);
        setSubscribedTillDate(subscribedTillDate);
        setIsDataLoaded(true);
      } catch (error) {
        if (
          error.message === 'Unauthorized.' ||
          error.code === 'too-many-requests'
        ) {
          signUserOut();
        }
      }
    })();
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else if (!isDataLoaded) {
    return <Loading />;
  } else {
    return (
      <section className="section-dashboard">
        <div className="row">
          <div className="dashboard">
            <h1 className="secondary-heading dashboard__heading">{`Hello, ${email}`}</h1>

            {/* <div className="dashboard-icon-container">
              <img
                className="dashboard-icon-container__icon"
                // src={
                //   selectedTheme === 'youtube white'
                //     ? DashboardIconWhiteTheme
                //     : DashboardIcon
                // }
                src={DashboardIcon}
                alt="dashboard"
              />
            </div> */}

            <p className="paragraph">
              Plan: {isSubscribed ? 'Premium' : 'Free'}
            </p>
            {isSubscribed && (
              <p className="paragraph">Plan expires: {subscribedTillDate}</p>
            )}

            <Link
              className={
                selectedTheme === 'spotify dark'
                  ? 'link--dark'
                  : selectedTheme === 'dark blue'
                  ? 'link--blue'
                  : 'link--white'
              }
              to="/premium?plan=all-plans"
            >
              <p className="paragraph">
                {isSubscribed ? 'Extend premium plan' : 'Upgrade to premium'}
              </p>
            </Link>
          </div>
        </div>
      </section>
    );
  }
};

export default DashBoard;
