import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { changeSelectedTab } from '../../reducers/librarySlice';
import AcousticIcon from '../../styles/icons/acoustic-icon.svg';
import AcousticIconWhiteTheme from '../../styles/icons/acoustic-icon-white-theme.svg';
import AtmosphericIcon from '../../styles/icons/atmospheric-icon.svg';
import AtmosphericIconWhiteTheme from '../../styles/icons/atmospheric-icon-white-theme.svg';
import CinematicIcon from '../../styles/icons/cinematic-icon.svg';
import CinematicIconWhiteTheme from '../../styles/icons/cinematic-icon-white-theme.svg';
import ClassicalIcon from '../../styles/icons/classical-icon.svg';
import ClassicalIconWhiteTheme from '../../styles/icons/classical-icon-white-theme.svg';
import DroneIcon from '../../styles/icons/drone-icon.svg';
import DroneIconWhiteTheme from '../../styles/icons/drone-icon-white-theme.svg';
import ElectronicIcon from '../../styles/icons/electronic-icon.svg';
import ElectronicIconWhiteTheme from '../../styles/icons/electronic-icon-white-theme.svg';
import GroovesIcon from '../../styles/icons/grooves-icon.svg';
import GroovesIconWhiteTheme from '../../styles/icons/grooves-icon-white-theme.svg';
import LofiIcon from '../../styles/icons/lofi-icon.svg';
import LofiIconWhiteTheme from '../../styles/icons/lofi-icon-white-theme.svg';
import PianoIcon from '../../styles/icons/piano-icon.svg';
import PianoIconWhiteTheme from '../../styles/icons/piano-icon-white-theme.svg';
import PostRockIcon from '../../styles/icons/post-rock-icon.svg';
import PostRockIconWhiteTheme from '../../styles/icons/post-rock-icon-white-theme.svg';
import BeachIcon from '../../styles/icons/beach-icon.svg';
import BeachIconWhiteTheme from '../../styles/icons/beach-icon-white-theme.svg';
import ChimesAndBowlsIcon from '../../styles/icons/chimes-and-bowls-icon.svg';
import ChimesAndBowlsIconWhiteTheme from '../../styles/icons/chimes-and-bowls-icon-white-theme.svg';
import ForestIcon from '../../styles/icons/forest-icon.svg';
import ForestIconWhiteTheme from '../../styles/icons/forest-icon-white-theme.svg';
import NightsoundsIcon from '../../styles/icons/nightsounds-icon.svg';
import NightsoundsIconWhiteTheme from '../../styles/icons/nightsounds-icon-white-theme.svg';
import RainIcon from '../../styles/icons/rain-icon.svg';
import RainIconWhiteTheme from '../../styles/icons/rain-icon-white-theme.svg';
import RainforestIcon from '../../styles/icons/rainforest-icon.svg';
import RainforestIconWhiteTheme from '../../styles/icons/rainforest-icon-white-theme.svg';
import RiverIcon from '../../styles/icons/river-icon.svg';
import RiverIconWhiteTheme from '../../styles/icons/river-icon-white-theme.svg';
import ThunderIcon from '../../styles/icons/thunder-icon.svg';
import ThunderIconWhiteTheme from '../../styles/icons/thunder-icon-white-theme.svg';
import UnderwaterIcon from '../../styles/icons/underwater-icon.svg';
import UnderwaterIconWhiteTheme from '../../styles/icons/underwater-icon-white-theme.svg';
import WindIcon from '../../styles/icons/wind-icon.svg';
import WindIconWhiteTheme from '../../styles/icons/wind-icon-white-theme.svg';
import DeepSpaceIcon from '../../styles/icons/deep-space-icon.svg';
import DeepSpaceIconWhiteTheme from '../../styles/icons/deep-space-icon-white-theme.svg';
import TheDeepIcon from '../../styles/icons/the-deep-icon.svg';
import TheDeepIconWhiteTheme from '../../styles/icons/the-deep-icon-white-theme.svg';
import JambientJungleIcon from '../../styles/icons/jambient-jungle-icon.svg';
import JambientJungleIconWhiteTheme from '../../styles/icons/jambient-jungle-icon-white-theme.svg';
import ChinchillaIcon from '../../styles/icons/chinchilla-icon.svg';
import ChinchillaIconWhiteTheme from '../../styles/icons/chinchilla-icon-white-theme.svg';
import TheDropIcon from '../../styles/icons/the-drop-icon.svg';
import TheDropIconWhiteTheme from '../../styles/icons/the-drop-icon-white-theme.svg';
import SymphonicaIcon from '../../styles/icons/symphonica-icon.svg';
import SymphonicaIconWhiteTheme from '../../styles/icons/symphonica-icon-white-theme.svg';
import TranquilityIcon from '../../styles/icons/tranquility-icon.svg';
import TranquilityIconWhiteTheme from '../../styles/icons/tranquility-icon-white-theme.svg';
import OrganicaIcon from '../../styles/icons/organica-icon.svg';
import OrganicaIconWhiteTheme from '../../styles/icons/organica-icon-white-theme.svg';
import PowertoolIcon from '../../styles/icons/powertool-icon.svg';
import PowertoolIconWhiteTheme from '../../styles/icons/powertool-icon-white-theme.svg';
import TheDukeIcon from '../../styles/icons/the-duke-icon.svg';
import TheDukeIconWhiteTheme from '../../styles/icons/the-duke-icon-white-theme.svg';
import BachBeatshIcon from '../../styles/icons/bach-beats-icon.svg';
import BachBeatshIconWhiteTheme from '../../styles/icons/bach-beats-icon-white-theme.svg';
import EdwardianIcon from '../../styles/icons/edwardian-icon.svg';
import EdwardianIconWhiteTheme from '../../styles/icons/edwardian-icon-white-theme.svg';
import AtmosphereIcon from '../../styles/icons/atmosphere-icon.svg';
import AtmosphereIconWhiteTheme from '../../styles/icons/atmosphere-icon-white-theme.svg';
import AquascopeIcon from '../../styles/icons/aquascope-icon.svg';
import AquascopeIconWhiteTheme from '../../styles/icons/aquascope-icon-white-theme.svg';
import CinematicFocusAtWillIcon from '../../styles/icons/cinematic-focus-at-will-icon.svg';
import CinematicFocusAtWillIconWhiteTheme from '../../styles/icons/cinematic-focus-at-will-icon-white-theme.svg';
import NatureBeatIcon from '../../styles/icons/nature-beat-icon.svg';
import NatureBeatIconWhiteTheme from '../../styles/icons/nature-beat-icon-white-theme.svg';
import EvolveIcon from '../../styles/icons/evolve-icon.svg';
import EvolveIconWhiteTheme from '../../styles/icons/evolve-icon-white-theme.svg';
import ZenPianoIcon from '../../styles/icons/zen-piano-icon.svg';
import ZenPianoIconWhiteTheme from '../../styles/icons/zen-piano-icon-white-theme.svg';
import HeadspaceIcon from '../../styles/icons/headspace-icon.svg';
import HeadspaceIconWhiteTheme from '../../styles/icons/headspace-icon-white-theme.svg';
import QuantumIcon from '../../styles/icons/quantum-icon.svg';
import QuantumIconWhiteTheme from '../../styles/icons/quantum-icon-white-theme.svg';
import GoldCrown from '../../styles/icons/gold-crown-icon.svg';
import AmbientAtmosphericIcon from '../../styles/icons/ambient-atmospheric-icon.svg';
import AmbientAtmosphericIconWhiteTheme from '../../styles/icons/ambient-atmospheric-icon-white-theme.svg';
import BeatsIcon from '../../styles/icons/beats-icon.svg';
import BeatsIconWhiteTheme from '../../styles/icons/beats-icon-white-theme.svg';
import NatureIcon from '../../styles/icons/nature-icon.svg';
import NatureIconWhiteTheme from '../../styles/icons/nature-icon-white-theme.svg';
import LullabyIcon from '../../styles/icons/lullaby-icon.svg';
import LullabyIconWhiteTheme from '../../styles/icons/lullaby-icon-white-theme.svg';
import CalmElectronicIcon from '../../styles/icons/calm-electronic-icon.svg';
import CalmElectronicIconWhiteTheme from '../../styles/icons/calm-electronic-icon-white-theme.svg';
import ClassicalAndStringsIcon from '../../styles/icons/classical-strings-icon.svg';
import ClassicalAndStringsIconWhiteTheme from '../../styles/icons/classical-strings-icon-white-theme.svg';
import CalmPianoIcon from '../../styles/icons/calm-piano-icon.svg';
import CalmPianoIconWhiteTheme from '../../styles/icons/calm-piano-icon-white-theme.svg';
import EndelFocusIcon from '../../styles/icons/endel-focus-icon.svg';
import EndelFocusIconWhiteTheme from '../../styles/icons/endel-focus-icon-white-theme.svg';
import EndelNatureIcon from '../../styles/icons/endel-nature-icon.svg';
import EndelNatureIconWhiteTheme from '../../styles/icons/endel-nature-icon-white-theme.svg';
import EndelWinterIcon from '../../styles/icons/endel-winter-icon.svg';
import EndelWinterIconWhiteTheme from '../../styles/icons/endel-winter-icon-white-theme.svg';
import Search from './Search';
import { StoreState } from '../../store/store';
import { PlayerRefType } from '../Player';

interface GenresProps {
  playerRef: PlayerRefType;
}

const Genres = (props: GenresProps) => {
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  // const areImagesLoaded = usePreloadIcons([
  //   brainfmGenres,
  //   focusAtWillGenres,
  //   endelGenres,
  //   headspaceGenres,
  //   calmGenres,
  //   calmSleepGenres,
  //   sleepGenres,
  //   headspaceSleepGenres,
  // ]);
  const selectedTheme = displayTheme
    ? 'spotify dark'
    : localStorage.getItem('lawfmtheme');
  const location = useLocation();
  const deepSleepGenres = location.pathname === '/deep-sleep';
  const powerNapGenres = location.pathname === '/power-nap';
  const guidedSleepGenres = location.pathname === '/guided-sleep';

  const isSubscribed = useSelector(
    (state: StoreState) => state.user.subscribed,
  );
  const { playerRef } = props;
  const [premiumOnlyNotication, setPremiumOnlyNotication] = useState(false);
  const dispatch = useDispatch();
  const isSearchActive = useSelector(
    (state: StoreState) => state.library.isSearchActive,
  );

  const handleGenreSelection = (index: number) => {
    dispatch(changeSelectedTab(brainfmGenres[index].name));
  };

  const handleEndelGenreSelection = (index: number) => {
    dispatch(changeSelectedTab(endelGenres[index].name));
  };

  const handCalmGenreSelection = (index: number) => {
    dispatch(changeSelectedTab(calmGenres[index].name));
  };

  const handHeadspaceGenreSelection = (index: number) => {
    dispatch(changeSelectedTab(headspaceGenres[index].name));
  };

  const handOtherGenreSelection = (index: number) => {
    dispatch(changeSelectedTab(otherGenres[index].name));
  };

  const handlefocusAtWillGenreSelection = (index: number) => {
    dispatch(changeSelectedTab(focusAtWillGenres[index].name));
  };

  const handleSleepGenreSelection = (index: number) => {
    dispatch(changeSelectedTab(sleepGenres[index].name));
  };

  const handleHeadspaceSleepGenreSelectrion = (index: number) => {
    dispatch(changeSelectedTab(headspaceSleepGenres[index].name));
  };

  const handleCalmSleepGenreSelectrion = (index: number) => {
    dispatch(changeSelectedTab(calmSleepGenres[index].name));
  };

  const closePremiumOnlyNotication = () => {
    setPremiumOnlyNotication(false);
  };

  const openPremiumOnlyNotication = () => {
    setPremiumOnlyNotication(true);
  };

  const handlePremiumOnlyGenreSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setPremiumOnlyNotication(false);
  };

  const premiumOnlyGenreAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handlePremiumOnlyGenreSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="genres">
      <h2
        className={
          selectedTheme === 'spotify dark'
            ? 'genres__title genres__title--dark'
            : selectedTheme === 'dark blue'
            ? 'genres__title genres__title--blue'
            : 'genres__title genres__title--white'
        }
      >
        Genres
      </h2>
      <Search playerRef={playerRef} isGenreSearch={false} />

      <Snackbar
        open={premiumOnlyNotication}
        autoHideDuration={6000}
        onClose={closePremiumOnlyNotication}
        message="Premium plan is required to listen to this genre."
        action={premiumOnlyGenreAction}
      />

      {!isSearchActive && (
        <div className="genres-list-container">
          <ul className="genres-list">
            <h3
              className={
                displayTheme
                  ? 'genres-list__heading genres-list__heading--general'
                  : selectedTheme === 'spotify dark'
                  ? 'genres-list__heading genres-list__heading--dark'
                  : selectedTheme === 'dark blue'
                  ? 'genres-list__heading genres-list__heading--blue'
                  : 'genres-list__heading genres-list__heading--white'
              }
            >
              brainfm
            </h3>
            {!deepSleepGenres &&
              !powerNapGenres &&
              !guidedSleepGenres &&
              brainfmGenres.map((genre, index) => {
                return (
                  <li
                    className={
                      displayTheme
                        ? 'genres-list__item genres-list__item--general'
                        : selectedTheme === 'spotify dark'
                        ? 'genres-list__item genres-list__item--dark'
                        : selectedTheme === 'dark blue'
                        ? 'genres-list__item genres-list__item--blue'
                        : 'genres-list__item genres-list__item--white'
                    }
                    onClick={() => handleGenreSelection(index)}
                    key={index}
                  >
                    <div className="genres-list-item-icon-container">
                      <img
                        className="genres-list-item-icon-container__icon"
                        src={
                          displayTheme
                            ? genre.icon
                            : selectedTheme === 'youtube white'
                            ? genre.whiteThemeIcon
                            : genre.icon
                        }
                        alt={genre.name}
                      />
                    </div>
                    <span className="genres-list__item__text">
                      {genre.name}
                    </span>
                  </li>
                );
              })}
            {(deepSleepGenres || powerNapGenres) &&
              !guidedSleepGenres &&
              sleepGenres.map((genre, index) => {
                return (
                  <li
                    className={
                      displayTheme
                        ? 'genres-list__item genres-list__item--general'
                        : selectedTheme === 'spotify dark'
                        ? 'genres-list__item genres-list__item--dark'
                        : selectedTheme === 'dark blue'
                        ? 'genres-list__item genres-list__item--blue'
                        : 'genres-list__item genres-list__item--white'
                    }
                    onClick={() => handleSleepGenreSelection(index)}
                    key={index}
                  >
                    <div className="genres-list-item-icon-container">
                      <img
                        className="genres-list-item-icon-container__icon"
                        src={
                          displayTheme
                            ? genre.icon
                            : selectedTheme === 'youtube white'
                            ? genre.whiteThemeIcon
                            : genre.icon
                        }
                        alt={genre.name}
                      />
                    </div>
                    <span className="genres-list__item__text">
                      {genre.name}
                    </span>
                  </li>
                );
              })}

            {guidedSleepGenres &&
              sleepGenresGuidedSleep.map((genre, index) => {
                return (
                  <li
                    className={
                      displayTheme
                        ? 'genres-list__item genres-list__item--general'
                        : selectedTheme === 'spotify dark'
                        ? 'genres-list__item genres-list__item--dark'
                        : selectedTheme === 'dark blue'
                        ? 'genres-list__item genres-list__item--blue'
                        : 'genres-list__item genres-list__item--white'
                    }
                    onClick={() => handleSleepGenreSelection(index)}
                    key={index}
                  >
                    <div className="genres-list-item-icon-container">
                      <img
                        className="genres-list-item-icon-container__icon"
                        src={
                          displayTheme
                            ? genre.icon
                            : selectedTheme === 'youtube white'
                            ? genre.whiteThemeIcon
                            : genre.icon
                        }
                        alt={genre.name}
                      />
                    </div>
                    <span className="genres-list__item__text">
                      {genre.name}
                    </span>
                  </li>
                );
              })}
          </ul>

          <ul className="genres-list">
            {!deepSleepGenres && !powerNapGenres && !guidedSleepGenres && (
              <ul className="genres-list">
                <h3
                  className={
                    displayTheme
                      ? 'genres-list__heading genres-list__heading--general'
                      : selectedTheme === 'spotify dark'
                      ? 'genres-list__heading genres-list__heading--dark'
                      : selectedTheme === 'dark blue'
                      ? 'genres-list__heading genres-list__heading--blue'
                      : 'genres-list__heading genres-list__heading--white'
                  }
                >
                  focus@will
                </h3>

                {focusAtWillGenres.map((genre, index) => {
                  return (
                    <li
                      className={
                        displayTheme
                          ? 'genres-list__item genres-list__item--general'
                          : selectedTheme === 'spotify dark'
                          ? 'genres-list__item genres-list__item--dark'
                          : selectedTheme === 'dark blue'
                          ? 'genres-list__item genres-list__item--blue'
                          : 'genres-list__item genres-list__item--white'
                      }
                      onClick={() => {
                        if (isSubscribed) {
                          handlefocusAtWillGenreSelection(index);
                        } else {
                          openPremiumOnlyNotication();
                        }
                      }}
                      key={index}
                    >
                      <div className="genres-list-item-icon-container">
                        <img
                          className="genres-list-item-icon-container__icon"
                          src={
                            displayTheme
                              ? genre.icon
                              : selectedTheme === 'youtube white'
                              ? genre.whiteThemeIcon
                              : genre.icon
                          }
                          alt={genre.name}
                        />
                      </div>
                      <span className="genres-list__item__text">
                        {genre.displayName ? genre.displayName : genre.name}
                      </span>

                      {!isSubscribed && (
                        <div className="genres-list-premium-icon-container">
                          <img
                            className="genres-list-premium-icon-container__icon"
                            src={GoldCrown}
                            alt="premium"
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}

            {deepSleepGenres && !guidedSleepGenres && !powerNapGenres && (
              <ul className="genres-list">
                <h3
                  className={
                    displayTheme
                      ? 'genres-list__heading genres-list__heading--general'
                      : selectedTheme === 'spotify dark'
                      ? 'genres-list__heading genres-list__heading--dark'
                      : selectedTheme === 'dark blue'
                      ? 'genres-list__heading genres-list__heading--blue'
                      : 'genres-list__heading genres-list__heading--white'
                  }
                >
                  calm
                </h3>

                {calmSleepGenres.map((genre, index) => {
                  return (
                    <li
                      className={
                        displayTheme
                          ? 'genres-list__item genres-list__item--general'
                          : selectedTheme === 'spotify dark'
                          ? 'genres-list__item genres-list__item--dark'
                          : selectedTheme === 'dark blue'
                          ? 'genres-list__item genres-list__item--blue'
                          : 'genres-list__item genres-list__item--white'
                      }
                      onClick={() => {
                        if (isSubscribed) {
                          handleCalmSleepGenreSelectrion(index);
                        } else {
                          openPremiumOnlyNotication();
                        }
                      }}
                      key={index}
                    >
                      <div className="genres-list-item-icon-container">
                        <img
                          className="genres-list-item-icon-container__icon"
                          src={
                            displayTheme
                              ? genre.icon
                              : selectedTheme === 'youtube white'
                              ? genre.whiteThemeIcon
                              : genre.icon
                          }
                          alt={genre.name}
                        />
                      </div>
                      <span className="genres-list__item__text">
                        {genre.displayName ? genre.displayName : genre.name}
                      </span>

                      {!isSubscribed && (
                        <div className="genres-list-premium-icon-container">
                          <img
                            className="genres-list-premium-icon-container__icon"
                            src={GoldCrown}
                            alt="premium"
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}

            {deepSleepGenres && !guidedSleepGenres && !powerNapGenres && (
              <ul className="genres-list">
                <h3
                  className={
                    displayTheme
                      ? 'genres-list__heading genres-list__heading--general'
                      : selectedTheme === 'spotify dark'
                      ? 'genres-list__heading genres-list__heading--dark'
                      : selectedTheme === 'dark blue'
                      ? 'genres-list__heading genres-list__heading--blue'
                      : 'genres-list__heading genres-list__heading--white'
                  }
                >
                  headspace
                </h3>

                {headspaceSleepGenres.map((genre, index) => {
                  return (
                    <li
                      className={
                        displayTheme
                          ? 'genres-list__item genres-list__item--general'
                          : selectedTheme === 'spotify dark'
                          ? 'genres-list__item genres-list__item--dark'
                          : selectedTheme === 'dark blue'
                          ? 'genres-list__item genres-list__item--blue'
                          : 'genres-list__item genres-list__item--white'
                      }
                      onClick={() => {
                        if (isSubscribed) {
                          handleHeadspaceSleepGenreSelectrion(index);
                        } else {
                          openPremiumOnlyNotication();
                        }
                      }}
                      key={index}
                    >
                      <div className="genres-list-item-icon-container">
                        <img
                          className="genres-list-item-icon-container__icon"
                          src={
                            displayTheme
                              ? genre.icon
                              : selectedTheme === 'youtube white'
                              ? genre.whiteThemeIcon
                              : genre.icon
                          }
                          alt={genre.name}
                        />
                      </div>
                      <span className="genres-list__item__text">
                        {genre.name}
                      </span>

                      {!isSubscribed && (
                        <div className="genres-list-premium-icon-container">
                          <img
                            className="genres-list-premium-icon-container__icon"
                            src={GoldCrown}
                            alt="premium"
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </ul>

          {!deepSleepGenres && !powerNapGenres && !guidedSleepGenres && (
            <ul className="genres-list">
              <h3
                className={
                  displayTheme
                    ? 'genres-list__heading genres-list__heading--general'
                    : selectedTheme === 'spotify dark'
                    ? 'genres-list__heading genres-list__heading--dark'
                    : selectedTheme === 'dark blue'
                    ? 'genres-list__heading genres-list__heading--blue'
                    : 'genres-list__heading genres-list__heading--white'
                }
              >
                calm
              </h3>
              {calmGenres.map((genre, index) => {
                return (
                  <li
                    className={
                      displayTheme
                        ? 'genres-list__item genres-list__item--general'
                        : selectedTheme === 'spotify dark'
                        ? 'genres-list__item genres-list__item--dark'
                        : selectedTheme === 'dark blue'
                        ? 'genres-list__item genres-list__item--blue'
                        : 'genres-list__item genres-list__item--white'
                    }
                    onClick={() => {
                      if (isSubscribed) {
                        handCalmGenreSelection(index);
                      } else {
                        openPremiumOnlyNotication();
                      }
                    }}
                    key={index}
                  >
                    <div className="genres-list-item-icon-container">
                      <img
                        className="genres-list-item-icon-container__icon"
                        src={
                          displayTheme
                            ? genre.icon
                            : selectedTheme === 'youtube white'
                            ? genre.whiteThemeIcon
                            : genre.icon
                        }
                        alt={genre.name}
                      />
                    </div>
                    <span className="genres-list__item__text">
                      {genre.displayName ? genre.displayName : genre.name}
                    </span>

                    {!isSubscribed && (
                      <div className="genres-list-premium-icon-container">
                        <img
                          className="genres-list-premium-icon-container__icon"
                          src={GoldCrown}
                          alt="premium"
                        />
                      </div>
                    )}
                  </li>
                );
              })}
              <h3
                className={
                  displayTheme
                    ? 'genres-list__heading genres-list__heading--general'
                    : selectedTheme === 'spotify dark'
                    ? 'genres-list__heading genres-list__heading--dark'
                    : selectedTheme === 'dark blue'
                    ? 'genres-list__heading genres-list__heading--blue'
                    : 'genres-list__heading genres-list__heading--white'
                }
              >
                endel
              </h3>

              {endelGenres.map((genre, index) => {
                return (
                  <li
                    className={
                      displayTheme
                        ? 'genres-list__item genres-list__item--general'
                        : selectedTheme === 'spotify dark'
                        ? 'genres-list__item genres-list__item--dark'
                        : selectedTheme === 'dark blue'
                        ? 'genres-list__item genres-list__item--blue'
                        : 'genres-list__item genres-list__item--white'
                    }
                    onClick={() => {
                      if (isSubscribed) {
                        handleEndelGenreSelection(index);
                      } else {
                        openPremiumOnlyNotication();
                      }
                    }}
                    key={index}
                  >
                    <div className="genres-list-item-icon-container">
                      <img
                        className="genres-list-item-icon-container__icon"
                        src={
                          displayTheme
                            ? genre.icon
                            : selectedTheme === 'youtube white'
                            ? genre.whiteThemeIcon
                            : genre.icon
                        }
                        alt={genre.name}
                      />
                    </div>
                    <span className="genres-list__item__text">
                      {genre.name}
                    </span>

                    {!isSubscribed && (
                      <div className="genres-list-premium-icon-container">
                        <img
                          className="genres-list-premium-icon-container__icon"
                          src={GoldCrown}
                          alt="premium"
                        />
                      </div>
                    )}
                  </li>
                );
              })}

              <h3
                className={
                  displayTheme
                    ? 'genres-list__heading genres-list__heading--general'
                    : selectedTheme === 'spotify dark'
                    ? 'genres-list__heading genres-list__heading--dark'
                    : selectedTheme === 'dark blue'
                    ? 'genres-list__heading genres-list__heading--blue'
                    : 'genres-list__heading genres-list__heading--white'
                }
              >
                headspace
              </h3>
              {headspaceGenres.map((genre, index) => {
                return (
                  <li
                    className={
                      displayTheme
                        ? 'genres-list__item genres-list__item--general'
                        : selectedTheme === 'spotify dark'
                        ? 'genres-list__item genres-list__item--dark'
                        : selectedTheme === 'dark blue'
                        ? 'genres-list__item genres-list__item--blue'
                        : 'genres-list__item genres-list__item--white'
                    }
                    onClick={() => {
                      if (isSubscribed) {
                        handHeadspaceGenreSelection(index);
                      } else {
                        openPremiumOnlyNotication();
                      }
                    }}
                    key={index}
                  >
                    <div className="genres-list-item-icon-container">
                      <img
                        className="genres-list-item-icon-container__icon"
                        src={
                          displayTheme
                            ? genre.icon
                            : selectedTheme === 'youtube white'
                            ? genre.whiteThemeIcon
                            : genre.icon
                        }
                        alt={genre.name}
                      />
                    </div>
                    <span className="genres-list__item__text">
                      {genre.name}
                    </span>

                    {!isSubscribed && (
                      <div className="genres-list-premium-icon-container">
                        <img
                          className="genres-list-premium-icon-container__icon"
                          src={GoldCrown}
                          alt="premium"
                        />
                      </div>
                    )}
                  </li>
                );
              })}

              <h3
                className={
                  displayTheme
                    ? 'genres-list__heading genres-list__heading--general'
                    : selectedTheme === 'spotify dark'
                    ? 'genres-list__heading genres-list__heading--dark'
                    : selectedTheme === 'dark blue'
                    ? 'genres-list__heading genres-list__heading--blue'
                    : 'genres-list__heading genres-list__heading--white'
                }
              >
                other
              </h3>
              {otherGenres.map((genre, index) => {
                return (
                  <li
                    className={
                      displayTheme
                        ? 'genres-list__item genres-list__item--general'
                        : selectedTheme === 'spotify dark'
                        ? 'genres-list__item genres-list__item--dark'
                        : selectedTheme === 'dark blue'
                        ? 'genres-list__item genres-list__item--blue'
                        : 'genres-list__item genres-list__item--white'
                    }
                    onClick={() => {
                      if (isSubscribed) {
                        handOtherGenreSelection(index);
                      } else {
                        openPremiumOnlyNotication();
                      }
                    }}
                    key={index}
                  >
                    <div className="genres-list-item-icon-container">
                      <img
                        className="genres-list-item-icon-container__icon"
                        src={
                          displayTheme
                            ? genre.icon
                            : selectedTheme === 'youtube white'
                            ? genre.whiteThemeIcon
                            : genre.icon
                        }
                        alt={genre.name}
                      />
                    </div>
                    <span className="genres-list__item__text">
                      {genre.name}
                    </span>

                    {!isSubscribed && (
                      <div className="genres-list-premium-icon-container">
                        <img
                          className="genres-list-premium-icon-container__icon"
                          src={GoldCrown}
                          alt="premium"
                        />
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export interface Genre {
  name: string;
  icon: string;
  whiteThemeIcon: string;
  displayName?: string;
  description?: string;
}

export const brainfmGenres: Genre[] = [
  {
    name: 'acoustic',
    icon: AcousticIcon,
    whiteThemeIcon: AcousticIconWhiteTheme,
  },
  {
    name: 'atmospheric',
    icon: AtmosphericIcon,
    whiteThemeIcon: AtmosphericIconWhiteTheme,
  },
  {
    name: 'cinematic',
    icon: CinematicIcon,
    whiteThemeIcon: CinematicIconWhiteTheme,
  },
  {
    name: 'classical',
    icon: ClassicalIcon,
    whiteThemeIcon: ClassicalIconWhiteTheme,
  },
  {
    name: 'drone',
    icon: DroneIcon,
    whiteThemeIcon: DroneIconWhiteTheme,
  },
  {
    name: 'electronic',
    icon: ElectronicIcon,
    whiteThemeIcon: ElectronicIconWhiteTheme,
  },
  {
    name: 'grooves',
    icon: GroovesIcon,
    whiteThemeIcon: GroovesIconWhiteTheme,
  },
  {
    name: 'lofi',
    icon: LofiIcon,
    whiteThemeIcon: LofiIconWhiteTheme,
  },
  {
    name: 'piano',
    icon: PianoIcon,
    whiteThemeIcon: PianoIconWhiteTheme,
  },
  {
    name: 'post rock',
    icon: PostRockIcon,
    whiteThemeIcon: PostRockIconWhiteTheme,
  },
  {
    name: 'beach',
    icon: BeachIcon,
    whiteThemeIcon: BeachIconWhiteTheme,
  },
  {
    name: 'chimes & bowls',
    icon: ChimesAndBowlsIcon,
    whiteThemeIcon: ChimesAndBowlsIconWhiteTheme,
  },
  {
    name: 'forest',
    icon: ForestIcon,
    whiteThemeIcon: ForestIconWhiteTheme,
  },
  {
    name: 'nightsounds',
    icon: NightsoundsIcon,
    whiteThemeIcon: NightsoundsIconWhiteTheme,
  },
  {
    name: 'rain',
    icon: RainIcon,
    whiteThemeIcon: RainIconWhiteTheme,
  },
  {
    name: 'rainforest',
    icon: RainforestIcon,
    whiteThemeIcon: RainforestIconWhiteTheme,
  },
  {
    name: 'river',
    icon: RiverIcon,
    whiteThemeIcon: RiverIconWhiteTheme,
  },
  {
    name: 'thunder',
    icon: ThunderIcon,
    whiteThemeIcon: ThunderIconWhiteTheme,
  },
  {
    name: 'underwater',
    icon: UnderwaterIcon,
    whiteThemeIcon: UnderwaterIconWhiteTheme,
  },
  {
    name: 'wind',
    icon: WindIcon,
    whiteThemeIcon: WindIconWhiteTheme,
  },
];

export const focusAtWillGenres: Genre[] = [
  {
    name: 'jambient jungle',
    icon: JambientJungleIcon,
    whiteThemeIcon: JambientJungleIconWhiteTheme,
    displayName: 'jambient jungle',
    description:
      'Nostalgic, ambient jungle. Old school meets new school. Exclusive new channel.',
  },
  {
    name: 'the deep',
    icon: TheDeepIcon,
    whiteThemeIcon: TheDeepIconWhiteTheme,
    displayName: 'the deep',
    description:
      'Alpha Chill • Deep, luscious downtempo beats. Find your flow on demand for sustained creativity.',
  },
  {
    name: 'chinchilla',
    icon: ChinchillaIcon,
    whiteThemeIcon: ChinchillaIconWhiteTheme,
    displayName: 'chinchilla',
    description:
      'Lo Fi • Foot tapping lounge tunes for getting stuff done at your own speed.',
  },
  {
    name: 'the drop',
    icon: TheDropIcon,
    whiteThemeIcon: TheDropIconWhiteTheme,
    displayName: 'the drop',
    description:
      'Uptempo EDM • Infectious, energetic, dance-floor beats to keep you engaged.',
  },
  {
    name: 'deep space',
    icon: DeepSpaceIcon,
    whiteThemeIcon: DeepSpaceIconWhiteTheme,
    displayName: 'deep space',
    description:
      'Hypnotic Exploration • Celestial beats for deep daily creativity.',
  },
  {
    name: 'symphonica',
    icon: SymphonicaIcon,
    whiteThemeIcon: SymphonicaIconWhiteTheme,
    displayName: 'symphonica',
    description:
      'Classical Plus • Custom mastered orchestral music for improved focus.',
  },
  {
    name: 'tranquility',
    icon: TranquilityIcon,
    whiteThemeIcon: TranquilityIconWhiteTheme,
    displayName: 'tranquility',
    description:
      'Focus Spa • Esoteric, mystical, and calming soundscapes for an ambient experience.',
  },
  {
    name: 'organica',
    icon: OrganicaIcon,
    whiteThemeIcon: OrganicaIconWhiteTheme,
    displayName: 'organica',
    description:
      'Acoustical, organic • Natural, solo performances on guitar and piano by humans.',
  },
  {
    name: 'powertool',
    icon: PowertoolIcon,
    whiteThemeIcon: PowertoolIconWhiteTheme,
    displayName: 'powertool',
    description:
      'ADHD, ADD • Industrial, glitch. Intense, noisy EDM channel scientifically proven to help.',
  },
  {
    name: 'the duke',
    icon: TheDukeIcon,
    whiteThemeIcon: TheDukeIconWhiteTheme,
    displayName: 'the duke',
    description:
      'Baroque Piano • 17th century Bach on a modern piano tuned for focus.',
  },
  {
    name: 'bach beats',
    icon: BachBeatshIcon,
    whiteThemeIcon: BachBeatshIconWhiteTheme,
    displayName: 'bach beats',
    description:
      'Electro Bach • Preludes, fugues and DJ beats. 1720s chamber strings meet 2020s electronica.',
  },
  {
    name: 'edwardian',
    icon: EdwardianIcon,
    whiteThemeIcon: EdwardianIconWhiteTheme,
    displayName: 'edwardian',
    description:
      'Classical Piano • Custom engineered, remastered and re-edited for focus.',
  },
  {
    name: 'atmosphere',
    icon: AtmosphereIcon,
    whiteThemeIcon: AtmosphereIconWhiteTheme,
    displayName: 'atmosphere',
    description:
      'Ambient, soundscapes • Peaceful, mellow, and subtle hypnotic spaces.',
  },
  {
    name: 'aquascope',
    icon: AquascopeIcon,
    whiteThemeIcon: AquascopeIconWhiteTheme,
    displayName: 'aquascope',
    description:
      'Water, nature • Meditative soundscapes featuring rain, waves, streams, and more.',
  },
  {
    name: 'cinematic (focus@will)',
    icon: CinematicFocusAtWillIcon,
    whiteThemeIcon: CinematicFocusAtWillIconWhiteTheme,
    displayName: 'cinematic',
    description:
      'Classical, orchestral • Emotionally connecting and highly evocative.',
  },
  {
    name: 'nature beat',
    icon: NatureBeatIcon,
    whiteThemeIcon: NatureBeatIconWhiteTheme,
    displayName: 'nature beat',
    description:
      'EDM, hybrid • Deep Electronica wrapped in rich nature soundscapes. Custom engineered for focus.',
  },
  {
    name: 'evolve',
    icon: EvolveIcon,
    whiteThemeIcon: EvolveIconWhiteTheme,
    displayName: 'evolve',
    description:
      'Ketamine Flow • “Music is the language of the spirit.” Kahlil Gibran',
  },
  {
    name: 'zen piano',
    icon: ZenPianoIcon,
    whiteThemeIcon: ZenPianoIconWhiteTheme,
    displayName: 'zen piano',
    description:
      'Piano, meditation • The focus magic happens in the space between the notes.',
  },
];

export const endelGenres: Genre[] = [
  {
    name: 'endel focus',
    icon: EndelFocusIcon,
    whiteThemeIcon: EndelFocusIconWhiteTheme,
  },
  {
    name: 'endel nature',
    icon: EndelNatureIcon,
    whiteThemeIcon: EndelNatureIconWhiteTheme,
  },
  {
    name: 'endel winter',
    icon: EndelWinterIcon,
    whiteThemeIcon: EndelWinterIconWhiteTheme,
  },
];

export const headspaceGenres: Genre[] = [
  {
    name: 'headspace',
    icon: HeadspaceIcon,
    whiteThemeIcon: HeadspaceIconWhiteTheme,
  },
];

export const calmGenres: Genre[] = [
  {
    name: 'ambient & atmospheric',
    icon: AmbientAtmosphericIcon,
    whiteThemeIcon: AmbientAtmosphericIconWhiteTheme,
  },
  {
    name: 'beats',
    icon: BeatsIcon,
    whiteThemeIcon: BeatsIconWhiteTheme,
  },
  {
    name: 'electronic (Calm)',
    displayName: 'electronic',
    icon: CalmElectronicIcon,
    whiteThemeIcon: CalmElectronicIconWhiteTheme,
  },
  {
    name: 'piano (Calm)',
    displayName: 'piano',
    icon: CalmPianoIcon,
    whiteThemeIcon: CalmPianoIconWhiteTheme,
  },
];

export const calmSleepGenres: Genre[] = [
  {
    name: 'ambient & atmospheric',
    icon: AmbientAtmosphericIcon,
    whiteThemeIcon: AmbientAtmosphericIconWhiteTheme,
  },
  {
    name: 'classical & strings',
    icon: ClassicalAndStringsIcon,
    whiteThemeIcon: ClassicalAndStringsIconWhiteTheme,
  },
  {
    name: 'electronic (Calm sleep)',
    displayName: 'electronic',
    icon: CalmElectronicIcon,
    whiteThemeIcon: CalmElectronicIconWhiteTheme,
  },
  {
    name: 'lullabies',
    icon: LullabyIcon,
    whiteThemeIcon: LullabyIconWhiteTheme,
  },
  {
    name: 'nature',
    icon: NatureIcon,
    whiteThemeIcon: NatureIconWhiteTheme,
  },
  {
    name: 'piano (Calm)',
    displayName: 'piano',
    icon: CalmPianoIcon,
    whiteThemeIcon: CalmPianoIconWhiteTheme,
  },
];

export const sleepGenres: Genre[] = [
  {
    name: 'atmospheric',
    icon: AtmosphericIcon,
    whiteThemeIcon: AtmosphericIconWhiteTheme,
  },
  {
    name: 'beach',
    icon: BeachIcon,
    whiteThemeIcon: BeachIconWhiteTheme,
  },
  {
    name: 'forest',
    icon: ForestIcon,
    whiteThemeIcon: ForestIconWhiteTheme,
  },
  {
    name: 'nightsounds',
    icon: NightsoundsIcon,
    whiteThemeIcon: NightsoundsIconWhiteTheme,
  },
  {
    name: 'rain',
    icon: RainIcon,
    whiteThemeIcon: RainIconWhiteTheme,
  },
  {
    name: 'rainforest',
    icon: RainforestIcon,
    whiteThemeIcon: RainforestIconWhiteTheme,
  },
  {
    name: 'river',
    icon: RiverIcon,
    whiteThemeIcon: RiverIconWhiteTheme,
  },
  {
    name: 'thunder',
    icon: ThunderIcon,
    whiteThemeIcon: ThunderIconWhiteTheme,
  },
  {
    name: 'underwater',
    icon: UnderwaterIcon,
    whiteThemeIcon: UnderwaterIconWhiteTheme,
  },
  {
    name: 'wind',
    icon: WindIcon,
    whiteThemeIcon: WindIconWhiteTheme,
  },
];

export const sleepGenresGuidedSleep: Genre[] = [
  {
    name: 'atmospheric',
    icon: AtmosphericIcon,
    whiteThemeIcon: AtmosphericIconWhiteTheme,
  },
];

export const headspaceSleepGenres: Genre[] = [
  {
    name: 'headspace',
    icon: HeadspaceIcon,
    whiteThemeIcon: HeadspaceIconWhiteTheme,
  },
];

export const otherGenres: Genre[] = [
  {
    name: 'quantum',
    icon: QuantumIcon,
    whiteThemeIcon: QuantumIconWhiteTheme,
  },
];

export default Genres;
