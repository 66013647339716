import { Navigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from '../styles/mui/mui-styles';
import { muiBlueTheme } from '../styles/mui/mui-styles-blue';
import { muiWhiteTheme } from '../styles/mui/mui-styles-white';
import Categories from './Categories';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RefreshIcon from '../styles/icons/soundscape-refresh.svg';
import TimerIcon from '../styles/icons/soundscape-timer.svg';
import VideoSettingsOutlinedIcon from '@mui/icons-material/VideoSettingsOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import Button from '@mui/material/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isUserLoggedIn } from '../api/auth/login';

const welcomeLogos = [
  {
    name: 'brainfm',
  },
  {
    name: 'focusatwill',
  },
  {
    name: 'endel',
  },
  {
    name: 'calm',
  },
  {
    name: 'headspace',
  },
];

const Welcome = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const isLoggedIn = isUserLoggedIn();
  const location = useLocation();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const handleContinueClick = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handleBackClick = () => {
    setCurrentPageIndex(currentPageIndex - 1);
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else {
    return (
      <section
        className={
          currentPageIndex === 2
            ? 'section-welcome section-welcome--soundscapes'
            : 'section-welcome'
        }
      >
        <div className="welcome">
          <h1 className="welcome__heading">
            {currentPageIndex === 0
              ? "Welcome! What's inside?"
              : currentPageIndex === 1
              ? 'Overview Of Focus & Sleep'
              : currentPageIndex === 2
              ? 'Overview Of Soundscapes'
              : 'Get started'}
          </h1>

          {currentPageIndex === 0 && (
            <p className="welcome__paragraph">
              Scientifically proven music, soundscapes that help you focus,
              sleep and relax from the industry leaders.
            </p>
          )}

          {currentPageIndex === 2 && (
            <p className="welcome__paragraph">
              The soundscapes play endlessly and are always at least a little
              bit different, and also vary depending on the current time of day.
            </p>
          )}

          {currentPageIndex === 3 && (
            <p className="welcome__paragraph">
              What would you like to try first?
            </p>
          )}

          {currentPageIndex === 0 && (
            <div className="row">
              <div className="welcome-logos">
                {welcomeLogos.map((logo, index) => {
                  return (
                    <div key={index} className="col-1-of-5">
                      <div className="welcome-logo-container">
                        <LazyLoadImage
                          className="welcome-logo-container__image"
                          alt={logo.name}
                          title={logo.name}
                          src={`/welcome/${logo.name}.png`}
                          effect="blur"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {currentPageIndex === 1 && (
            <div className="welcome-player-screen-container">
              <LazyLoadImage
                className="welcome-player-screen-container__image"
                alt="focus & sleep"
                src={`/welcome/player-screen.png`}
                effect="blur"
              />
            </div>
          )}

          {currentPageIndex === 2 && (
            <div className="welcome-player-screen-container">
              <LazyLoadImage
                className="welcome-player-screen-container__image"
                alt="soundscapes"
                src={`/welcome/soundscapes-screen.png`}
                effect="blur"
              />
            </div>
          )}

          {currentPageIndex === 1 && (
            <ul className="welcome-list">
              <li className="welcome-list__item">
                <LibraryMusicOutlinedIcon style={iconStyles} />{' '}
                <span className="welcome-list-hyphen">-</span> Browse all the
                tracks, tracks played today, favorites, and tracks added to
                uninterested.
              </li>

              <li className="welcome-list__item">
                <VideoSettingsOutlinedIcon style={iconStyles} />{' '}
                <span className="welcome-list-hyphen">-</span> Play settings.
              </li>

              <li className="welcome-list__item">
                <ScheduleIcon style={iconStyles} />{' '}
                <span className="welcome-list-hyphen">-</span> Set a timer.
              </li>

              <li className="welcome-list__item">
                <ThumbUpOffAltIcon style={iconStyles} />{' '}
                <span className="welcome-list-hyphen">-</span> Add the track to
                your favorites.
              </li>
              <li className="welcome-list__item">
                <ThumbDownOffAltIcon style={iconStyles} />{' '}
                <span className="welcome-list-hyphen">-</span> Add the track to
                uninterested to prevent it from being automatically played
                again.
              </li>
            </ul>
          )}

          {currentPageIndex === 2 && (
            <ul className="welcome-list">
              <li className="welcome-list__item">
                <span className="welcome-list-separate-text">'Focus'</span>
                <span className="welcome-list-hyphen">{'-'}</span>
                Selected soundscape, can be changed by clicking on icons in the
                bottom.
              </li>

              <li className="welcome-list__item">
                <span className="welcome-list-separate-text">
                  'Midday Energy Fade'
                </span>
                <span className="welcome-list-hyphen">{'-'}</span>
                Dynamic title that indicates the time of day and corresponding
                energy levels.
              </li>
              <li className="welcome-list__item">
                <img
                  className="welcome-list-icon"
                  title={'Regenerate Soundscape'}
                  src={RefreshIcon}
                  alt={'Regenerate Soundscape'}
                />{' '}
                <span className="welcome-list-hyphen">-</span> Regenerate
                soundscape.
              </li>
              <li className="welcome-list__item">
                <img
                  className="welcome-list-icon"
                  title={'Set Timer'}
                  src={TimerIcon}
                  alt={'Set Timer'}
                />{' '}
                <span className="welcome-list-hyphen">-</span> Set a timer.
              </li>
            </ul>
          )}

          {currentPageIndex === 3 && <Categories />}

          {currentPageIndex !== 3 && (
            <ThemeProvider
              theme={
                selectedTheme === 'spotify dark'
                  ? muiTheme
                  : selectedTheme === 'dark blue'
                  ? muiBlueTheme
                  : muiWhiteTheme
              }
            >
              <div className="welcome-buttons-container">
                {currentPageIndex !== 0 && currentPageIndex !== 3 && (
                  <div className="welcome-button-container welcome-button-container--with-right-margin">
                    <Button
                      onClick={handleBackClick}
                      name="continue"
                      variant="contained"
                      style={
                        selectedTheme === 'youtube white'
                          ? rectangleButtonStylesWhiteTheme
                          : rectangleButtonStyles
                      }
                    >
                      Back
                    </Button>
                  </div>
                )}

                <div className="welcome-button-container">
                  <Button
                    onClick={handleContinueClick}
                    name="continue"
                    variant="contained"
                    style={
                      selectedTheme === 'youtube white'
                        ? rectangleButtonStylesWhiteTheme
                        : rectangleButtonStyles
                    }
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </ThemeProvider>
          )}
        </div>
      </section>
    );
  }
};

const iconStyles = {
  fontSize: '2.5rem',
  marginRight: '1rem',
};

export const rectangleButtonStyles = {
  color: '#121212',
  fontSize: '1.4rem',
  fontWeight: 500,
};

export const rectangleButtonStylesWhiteTheme = {
  color: '#e7e7e7',
  fontSize: '1.4rem',
  fontWeight: 500,
};

export default Welcome;
