import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from '../styles/mui/mui-styles';
import { muiBlueTheme } from '../styles/mui/mui-styles-blue';
import { muiWhiteTheme } from '../styles/mui/mui-styles-white';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { StoreState } from '../store/store';
import {
  addNewFavoriteTrack,
  removeFavoriteTrack,
  addNewUninterestedTrack,
  removeUninterestedTrack,
} from '../api/user/user';
import {
  addFavorite,
  removeFavorite,
  addUninterested,
  removeUninterested,
} from '../reducers/userSlice';
import { signUserOut } from '../api/auth/login';
import { capitalizeFirstLetters } from './library/Track';
import { PlayingTrack } from '../reducers/librarySlice';

const TrackInformation = () => {
  const selectedTheme = localStorage.getItem('lawfmtheme');
  const location = useLocation();
  const sleepCategoryGenres =
    location.pathname === '/deep-sleep' ||
    location.pathname === '/power-nap' ||
    location.pathname === '/guided-sleep';

  const trackPath = useSelector(
    (state: StoreState) => state.library.playingTrack.path,
  );
  const playingTrack = useSelector(
    (state: StoreState) => state.library.playingTrack,
  );
  const neuralEffect = getNeuralEffectIfAvailable(trackPath, playingTrack);
  const displayTheme = useSelector(
    (state: StoreState) => state.user.displayTheme,
  );
  const dispatch = useDispatch();
  const displayTrackInfo = useSelector(
    (state: StoreState) => state.user.displayTrackInfo,
  );
  const name = useSelector(
    (state: StoreState) => state.library.playingTrack.name,
  );
  const genre = useSelector(
    (state: StoreState) => state.library.playingTrack.genre,
  );
  const trackId = useSelector(
    (state: StoreState) => state.library.playingTrack.id,
  );
  const isTrackInFavorites = useSelector((state: StoreState) =>
    state.user.favorites.includes(trackId),
  );
  const isTrackInUninterested = useSelector((state: StoreState) =>
    state.user.uninterested.includes(trackId),
  );
  const [favoritesTooltipOpen, setFavoritesTooltipOpen] = useState(false);
  const [addToFavoritesSnackbarOpen, setAddToFavoritesSnackbarOpen] =
    useState(false);
  const [addToUninterestedSnackbarOpen, setAddToUninterestedSnackbarOpen] =
    useState(false);
  const [uninterestedTooltipOpen, setUninterestedTooltipOpen] = useState(false);

  const [removeFromFavoritesSnackbarOpen, setRemoveFromFavoritesSnackbarOpen] =
    useState(false);
  const [
    removeFromUninterestedSnackbarOpen,
    setRemoveFromUninterestedSnackbarOpen,
  ] = useState(false);
  const [
    trackInformationErrorNotification,
    setTrackInformationErrorNotication,
  ] = useState(false);

  const closeTrackInformationErrorNotification = () => {
    setTrackInformationErrorNotication(false);
  };

  const openTrackInformationErrorSnackbar = () => {
    setTrackInformationErrorNotication(true);
  };

  const handleTrackInformationErrorSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setTrackInformationErrorNotication(false);
  };

  const trackInformationErrorAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleTrackInformationErrorSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleAddToFavoritesClick = async () => {
    try {
      if (isTrackInUninterested) {
        setRemoveFromFavoritesSnackbarOpen(false);
        setFavoritesTooltipOpen(false);
        await removeUninterestedTrack(trackId);
        dispatch(removeUninterested(trackId));
        await addNewFavoriteTrack(trackId);
        dispatch(addFavorite(trackId));
        setAddToFavoritesSnackbarOpen(true);
      } else if (isTrackInFavorites) {
        await handleAddToFavoritesUndo();
      } else {
        setRemoveFromFavoritesSnackbarOpen(false);
        setFavoritesTooltipOpen(false);
        await addNewFavoriteTrack(trackId);
        dispatch(addFavorite(trackId));
        setAddToFavoritesSnackbarOpen(true);
      }
    } catch (error) {
      if (
        error.message === 'Unauthorized.' ||
        error.code === 'too-many-requests'
      ) {
        signUserOut();
      } else {
        openTrackInformationErrorSnackbar();
      }
    }
  };

  const handleAddToFavoritesSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setAddToFavoritesSnackbarOpen(false);
  };

  const handleAddToFavoritesUndo = async () => {
    try {
      setAddToFavoritesSnackbarOpen(false);
      setRemoveFromFavoritesSnackbarOpen(false);
      await removeFavoriteTrack(trackId);
      dispatch(removeFavorite(trackId));
      setRemoveFromFavoritesSnackbarOpen(true);
    } catch (error) {
      if (
        error.message === 'Unauthorized.' ||
        error.code === 'too-many-requests'
      ) {
        signUserOut();
      } else {
        openTrackInformationErrorSnackbar();
      }
    }
  };

  const addToFavoritesAction = (
    <React.Fragment>
      <Button
        size="small"
        onClick={handleAddToFavoritesUndo}
        sx={{
          color: 'black',
          fontWeight: '700',
          fontSize: '12px',
        }}
      >
        <span
          className={
            selectedTheme === 'spotify dark'
              ? 'snackbar-undo-button snackbar-undo-button--dark'
              : selectedTheme === 'dark blue'
              ? 'snackbar-undo-button snackbar-undo-button--blue'
              : 'snackbar-undo-button snackbar-undo-button--white'
          }
        >
          Undo
        </span>
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleAddToFavoritesSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleRemoveFromFavoritesSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setRemoveFromFavoritesSnackbarOpen(false);
  };

  const removeFromFavoritesAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleRemoveFromFavoritesSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleRemoveFromUninterestedSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setRemoveFromUninterestedSnackbarOpen(false);
  };

  const removeFromUninterestedAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleRemoveFromUninterestedSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleAddToUninterestedUndo = async () => {
    try {
      setAddToUninterestedSnackbarOpen(false);
      await removeUninterestedTrack(trackId);
      dispatch(removeUninterested(trackId));
      setRemoveFromUninterestedSnackbarOpen(true);
    } catch (error) {
      if (
        error.message === 'Unauthorized.' ||
        error.code === 'too-many-requests'
      ) {
        signUserOut();
      } else {
        openTrackInformationErrorSnackbar();
      }
    }
  };

  const handleAddToUninterestedSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setAddToUninterestedSnackbarOpen(false);
  };

  const handleAddToUninterestedClick = async () => {
    try {
      if (isTrackInUninterested) {
        await handleAddToUninterestedUndo();
      } else if (isTrackInFavorites) {
        await removeFavoriteTrack(trackId);
        dispatch(removeFavorite(trackId));
        dispatch(addUninterested(trackId));
        await addNewUninterestedTrack(trackId);
        setAddToUninterestedSnackbarOpen(true);
      } else {
        setUninterestedTooltipOpen(false);
        await addNewUninterestedTrack(trackId);
        dispatch(addUninterested(trackId));
        setAddToUninterestedSnackbarOpen(true);
      }
    } catch (error) {
      if (
        error.message === 'Unauthorized.' ||
        error.code === 'too-many-requests'
      ) {
        signUserOut();
      } else {
        openTrackInformationErrorSnackbar();
      }
    }
  };

  const addToUninterestedAction = (
    <React.Fragment>
      <Button
        size="small"
        onClick={handleAddToUninterestedUndo}
        sx={{
          color: 'black',
          fontWeight: '700',
          fontSize: '12px',
        }}
      >
        <span
          className={
            selectedTheme === 'spotify dark'
              ? 'snackbar-undo-button snackbar-undo-button--dark'
              : selectedTheme === 'dark blue'
              ? 'snackbar-undo-button snackbar-undo-button--blue'
              : 'snackbar-undo-button snackbar-undo-button--white'
          }
        >
          Undo
        </span>
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleAddToUninterestedSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div
      className={
        displayTrackInfo ? `track-info` : `track-info track-info--hidden`
      }
    >
      <h3
        className={
          displayTheme
            ? 'track-info__name track-info__name--general'
            : selectedTheme === 'spotify dark'
            ? 'track-info__name track-info__name--dark'
            : selectedTheme === 'dark blue'
            ? 'track-info__name track-info__name--blue'
            : 'track-info__name track-info__name--white'
        }
      >
        {name && truncateString(capitalizeFirstLetters(name), 30)}
      </h3>

      {/* {neuralEffect && (
        <p
          className={
            displayTheme
              ? 'track-info__neural-effect track-info__neural-effect--general'
              : selectedTheme === 'spotify dark'
              ? 'track-info__neural-effect track-info__neural-effect--dark'
              : selectedTheme === 'dark blue'
              ? 'track-info__neural-effect track-info__neural-effect--blue'
              : 'track-info__neural-effect track-info__neural-effect--white'
          }
        >
          {capitalizeFirstLetters(neuralEffect)} Neural Effect
        </p>
      )} */}

      <p
        className={
          displayTheme
            ? 'track-info__genre track-info__genre--general'
            : selectedTheme === 'spotify dark'
            ? 'track-info__genre track-info__genre--dark'
            : selectedTheme === 'dark blue'
            ? 'track-info__genre track-info__genre--blue'
            : 'track-info__genre track-info__genre--white'
        }
      >
        {genre}
      </p>

      {neuralEffect && (
        <div>
          {neuralEffect && neuralEffect === 'low' ? (
            <div className="neural-effect-container" title="Low Neural Effect">
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level '
                    : 'neural-effect-container__level--white'
                }
              ></div>
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level'
                    : 'neural-effect-container__level--white'
                }
              ></div>
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level neural-effect-container__level--active'
                    : 'neural-effect-container__level--white neural-effect-container__level--white--active'
                }
              ></div>
            </div>
          ) : neuralEffect && neuralEffect === 'medium' ? (
            <div
              className="neural-effect-container"
              title="Medium Neural Effect"
            >
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level'
                    : 'neural-effect-container__level--white'
                }
              ></div>
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level neural-effect-container__level--active'
                    : 'neural-effect-container__level--white neural-effect-container__level--white--active'
                }
              ></div>
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level neural-effect-container__level--active'
                    : 'neural-effect-container__level--white neural-effect-container__level--white--active'
                }
              ></div>
            </div>
          ) : (
            <div className="neural-effect-container" title="High Neural Effect">
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level neural-effect-container__level--active'
                    : 'neural-effect-container__level--white neural-effect-container__level--white--active'
                }
              ></div>
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level neural-effect-container__level--active'
                    : 'neural-effect-container__level--white neural-effect-container__level--white--active'
                }
              ></div>
              <div
                className={
                  selectedTheme === 'spotify dark' ||
                  selectedTheme === 'dark blue' ||
                  displayTheme
                    ? 'neural-effect-container__level neural-effect-container__level--active'
                    : 'neural-effect-container__level--white neural-effect-container__level--white--active'
                }
              ></div>
            </div>
          )}
        </div>
      )}
      {/* <button
        className={
          displayTheme
            ? 'track-info__genre-button track-info__genre-button--general'
            : selectedTheme === 'spotify dark'
            ? 'track-info__genre-button track-info__genre-button--dark'
            : selectedTheme === 'dark blue'
            ? 'track-info__genre-button track-info__genre-button--blue'
            : 'track-info__genre-button track-info__genre-button--white'
        }
      >
        <span
          className={
            displayTheme
              ? 'track-info__genre-button-span track-info__genre-button-span--general'
              : selectedTheme === 'spotify dark'
              ? 'track-info__genre-button-span track-info__genre-button-span--dark'
              : selectedTheme === 'dark blue'
              ? 'track-info__genre-button-span track-info__genre-button-span--blue'
              : 'track-info__genre-button-span track-info__genre-button-span--white'
          }
        >
          {genre}
        </span>
      </button> */}
      {name && (
        <div className="track-action-icons">
          <ThemeProvider
            theme={
              selectedTheme === 'spotify dark'
                ? muiTheme
                : selectedTheme === 'dark blue'
                ? muiBlueTheme
                : muiWhiteTheme
            }
          >
            <Tooltip
              open={favoritesTooltipOpen}
              onMouseEnter={() => setFavoritesTooltipOpen(true)}
              onMouseLeave={() => setFavoritesTooltipOpen(false)}
              title="Add the track to your favorites to listen to it later."
            >
              <div className="track-action-icons__icon-box">
                {/* <FavoriteBorderIcon
                  onClick={handleAddToFavoritesClick}
                  style={
                    selectedTheme === 'spotify dark' && isTrackInFavorites
                      ? iconsDarkStyles
                      : selectedTheme === 'dark blue' && isTrackInFavorites
                      ? iconsActiveBlueStyles
                      : selectedTheme === 'youtube white' && isTrackInFavorites
                      ? iconsWhiteStyles
                      : displayTheme
                      ? iconStylesGeneral
                      : iconStyles
                  }
                /> */}

                {isTrackInFavorites ? (
                  <div className="favorite-icon-container">
                    <ThumbUpIcon
                      onClick={handleAddToFavoritesClick}
                      style={
                        selectedTheme === 'spotify dark' ||
                        selectedTheme === 'dark blue' ||
                        displayTheme
                          ? iconsDarkStyles
                          : iconsWhiteStyles
                      }
                    />
                  </div>
                ) : (
                  <div className="favorite-icon-container">
                    <ThumbUpOffAltIcon
                      onClick={handleAddToFavoritesClick}
                      style={
                        selectedTheme === 'spotify dark' ||
                        selectedTheme === 'dark blue' ||
                        displayTheme
                          ? iconsDarkStyles
                          : iconsWhiteStyles
                      }
                    />
                  </div>
                )}
              </div>
            </Tooltip>

            <Tooltip
              open={uninterestedTooltipOpen}
              onMouseEnter={() => setUninterestedTooltipOpen(true)}
              onMouseLeave={() => setUninterestedTooltipOpen(false)}
              title="Not interested in this track? Press the button and it will not be played automatically anymore. It will still be browsable in the library."
            >
              <div className="track-action-icons__icon-box">
                {isTrackInUninterested ? (
                  <ThumbDownIcon
                    onClick={handleAddToUninterestedClick}
                    style={
                      selectedTheme === 'spotify dark' ||
                      selectedTheme === 'dark blue' ||
                      displayTheme
                        ? iconsDarkStyles
                        : iconsWhiteStyles
                    }
                  />
                ) : (
                  <ThumbDownOffAltIcon
                    onClick={handleAddToUninterestedClick}
                    style={
                      selectedTheme === 'spotify dark' ||
                      selectedTheme === 'dark blue' ||
                      displayTheme
                        ? iconsDarkStyles
                        : iconsWhiteStyles
                    }
                  />
                )}

                {/* <NotInterestedIcon
                  onClick={handleAddToUninterestedClick}
                  style={
                    selectedTheme === 'spotify dark' && isTrackInUninterested
                      ? iconsDarkStyles
                      : selectedTheme === 'dark blue' && isTrackInUninterested
                      ? iconsActiveBlueStyles
                      : selectedTheme === 'youtube white' &&
                        isTrackInUninterested
                      ? iconsWhiteStyles
                      : displayTheme
                      ? iconStylesGeneral
                      : iconStyles
                  }
                /> */}
              </div>
            </Tooltip>
          </ThemeProvider>
        </div>
      )}

      <Snackbar
        open={addToFavoritesSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleAddToFavoritesSnackbarClose}
        message="The track added to the favorites."
        action={addToFavoritesAction}
      />

      <Snackbar
        open={removeFromFavoritesSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleRemoveFromFavoritesSnackbarClose}
        message="The track removed from the favorites"
        action={removeFromFavoritesAction}
      />

      <Snackbar
        open={addToUninterestedSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleAddToUninterestedSnackbarClose}
        message="The track marked as uninterested and it will not be played automatically anymore."
        action={addToUninterestedAction}
      />

      <Snackbar
        open={removeFromUninterestedSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleRemoveFromUninterestedSnackbarClose}
        message="The track removed from the uninterested."
        action={removeFromUninterestedAction}
      />

      <Snackbar
        open={trackInformationErrorNotification}
        autoHideDuration={6000}
        onClose={closeTrackInformationErrorNotification}
        message={`Something went wrong, please try again. If the issue persist, please contact support at ${process.env.REACT_APP_CONTACT_EMAIL}`}
        action={trackInformationErrorAction}
      />
    </div>
  );
};

function getNeuralEffectIfAvailable(
  neuralEffectPath: string | false,
  playingTrack: PlayingTrack,
): string {
  if (
    playingTrack.lowNeuralPath === '' &&
    playingTrack.mediumNeuralPath === '' &&
    playingTrack.highNeuralPath === ''
  ) {
    if (playingTrack.lowNeuralLinkPath === neuralEffectPath) {
      return 'low';
    } else if (playingTrack.mediumNeuralLinkPath === neuralEffectPath) {
      return 'medium';
    } else if (playingTrack.highNeuralLinkPath === neuralEffectPath) {
      return 'high';
    } else {
      return '';
    }
  } else {
    if (!neuralEffectPath) {
      return '';
    }

    const parts = neuralEffectPath.split('/');
    if (parts.length > 8) {
      return parts[7];
    }

    return '';
  }
}

// const iconStyles = {
//   float: 'right' as 'right',
//   fontSize: '2rem',
//   cursor: 'pointer',
// };

// const iconStylesGeneral = {
//   color: '#e7e7e7',
//   float: 'right' as 'right',
//   fontSize: '2rem',
//   cursor: 'pointer',
// };

const iconsDarkStyles = {
  float: 'right' as 'right',
  fontSize: '2.5rem',
  cursor: 'pointer',
  color: '#fafafa',
  opacity: '0.75',
};

// const iconsActiveBlueStyles = {
//   float: 'right' as 'right',
//   fontSize: '2rem',
//   cursor: 'pointer',
//   color: '#f39c12',
// };

const iconsWhiteStyles = {
  float: 'right' as 'right',
  fontSize: '2.5rem',
  cursor: 'pointer',
  color: '#0f0f0f',
};

export const truncateString = (inputString: string, maxLength: number) => {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength) + '...';
  } else {
    return inputString;
  }
};

export default TrackInformation;
