import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../reducers/userSlice';
import libraryReducer from '../reducers/librarySlice';
import { LibraryState } from '../reducers/librarySlice';
import { UserState } from '../reducers/userSlice';

export interface StoreState {
  user: UserState
  library: LibraryState
}

export const store = configureStore({
  reducer: {
    user: userReducer,
    library: libraryReducer,
  },
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});
