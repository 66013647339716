export const registerWithEmailandPassword = async (email: string, password: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/register`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  );
  const responseJSON = await response.json();

  if (response.status === 201) {
    return responseJSON;
  } else {
    throw responseJSON;
  }
};
